<template>
    
    <footer class="base-footer">
     
        <!-- <div class="base-footer">
          


        </div> -->

        <a @click="goHome()" class="footer__brand">
            <!-- <img src="@/assets/svgs/liesborn-green-oh.svg" alt="logo"> -->
            <img :src="kSImage()" alt="logo">
        </a>
        
        <a @click="goUp()" class="footer__pfeil">
            <img src="@/assets/svgs/box-arrow-up.svg" alt="UP">
        </a>
    </footer>

</template>



<script>
// import routerMixin from "../mixins/routerMixin";
// import myRouter from '../mixins/myRouter.js'
// import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'  // , useRoute
import useEmitter from '@/composables/useEmitter'
import {useStore} from 'vuex'


export default {
  name: 'Footer',
  // mixins: [routerMixin],

   props: {
    content: Object,
    language: String
  },
  
  setup(props){
    // const store = useStore();
    // const {pageName, pushPage} = myRouter();
    // const router = useRouter();
    const emitter = useEmitter();
    const store = useStore();
    

    const goHome = () => {
      emitter.emit("GO_HOME", "message from d" );
      console.log("goHome")
    }

    // const goUp = () => {
    //   console.log("goUp")
    // }

    const kSImage = () => {
      if (store.state.kontrastSwitch== true) {
        // return "@/assets/svgs/liesborn_black-oh.svg" 
        return "/liesborn-black-oh.svg" 
      } else {
        return "/liesborn-green-oh.svg" 

      }
    }    

    const goUp = () => {
      console.log("goUp in baseFooter")
      // setTimeout( () => location.hash = "#main-header", 100);
      // setTimeout( () => location.hash = "", 500);
      window.scrollTo(0, 0);
      emitter.emit("GO_UP", "message from Up Pfeil" );
    }

    // const openQrcodeScanner = () => {
    //     store.state.qrcodeModal = !store.state.qrcodeModal
    // }

    // const openKontakt = () => {
    //   store.state.showRundgang = false;
    //   router.push({ name: 'contactpage', params: { roomId: ""}, props: true})
    //   // router.push({ name: 'contactpage',props: true})
    //   // pushPage('contactPage')
    // }
    
    // const openImpressum = () => {
    //   store.state.showRundgang = false;
    //   router.push({ name: 'impressumpage', params: { roomId: ""}, props: true})
    //   // pushPage('impressumPage')
    // }

    return {
      goHome,
      goUp,
      kSImage,
        // pageName,
        // pushPage,
        // openQrcodeScanner,
        // openKontakt,
        // openImpressum,
        props
      }

  }
}

</script>



<style lang="scss" scoped>
// .main-footer {
//   background: rgb(49, 146, 62);
//   padding: 2rem;
//   // margin-top: 2rem;
//   /* grid-row: 3 / 4; */
//   grid-area: footer;
// }

.base-footer {
  // background: rgb(64, 30, 216);
  background: rgb(255, 255, 255);
  padding: 2rem;
  // height: 100px;
  grid-area: basefooter;
  padding: 0.5rem 2.0rem 0.5rem 1.9rem;
  height: 5.0rem;
  width: 100%;
  max-width: 1180px;
  // z-index: 50;
  display: flex;
  flex-flow: row;
  // margin: auto;
  // align-content: center;
  align-items: center;
  justify-content: space-between;
  // vertical-align: center;
  // align-items: flex-start;
  // justify-content: space-between;
}


.footer__brand {
  // background-color: #0e4f1f;
  vertical-align: center;
  // text-decoration: none;
  // font-weight: bold;
  /* width: 20px; */
}

.footer__brand img {
  height: 2.85rem;
  vertical-align: center;
  /* width: 100%; */
}

.footer__pfeil {
  // vertical-align: flex-end;
  vertical-align: center;
}


.main-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-footer__link {
  margin: 0.5rem 0;
}

.main-footer__link a {
  color: white;
  text-decoration: none;
}

.main-footer__link a:hover,
.main-footer__link a:active {
  color: #ccc;
}


@media (min-width: 40rem) {
  .main-footer__link {
    margin: 0 1rem;
  }

  .main-footer__links {
    flex-direction: row;
    justify-content: center;
  }
}

</style>