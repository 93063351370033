<template>
    
    <footer class="main-footer">

        <div v-if="route.path!='/'">
          
          <div class="main-footer-deko" >
            <div class="deko__line" :style="kSDekoline()"></div>
            <p  :class="store.state.typoSwitch ? 'larger':'leer'" v-html="contentui.ui.links[language]"></p>
          </div>

          <nav>
              <ul class="main-footer__links">
                  <!-- <li class="main-footer__link">
                      <a class="deselect" @click="openKontakt()"> {{ contentui.footer[language][0] }} </a>
                  </li> -->
                  <li class="main-footer__link">
                      <a class="deselect" @click="openImpressum()">{{ contentui.footer[language][1] }}</a>
                  </li>
                  <li class="main-footer__link">
                      <a class="deselect" @click="openFoerderer()">{{ contentui.footer[language][4] }}</a>
                  </li>
                  <li class="main-footer__link">
                      <a class="deselect" @click="openPincode()">{{ contentui.footer[language][3] }}</a>
                  </li>

              
                  <!-- <li class="main-footer__link">
                      <a class="deselect" @click="openQrcodeScanner()">{{ contentui.footer[language][2] }}</a>
                  </li> -->
              </ul>
          </nav>
       
        </div>  
      
    </footer>

</template>



<script>
// import routerMixin from "../mixins/routerMixin";
// import myRouter from '../mixins/myRouter.js'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'  // , useRoute


export default {
  name: 'Footer',
  // mixins: [routerMixin],

   props: {
    contentui: Object,
    language: String
  },
  
  setup(){
    const store = useStore();
    // const {pageName, pushPage} = myRouter();
    const router = useRouter();
    const route = useRoute();

    // const openQrcodeScanner = () => {
    //     store.state.qrcodeModal = !store.state.qrcodeModal
    // }

    const openPincode = () => {
        store.state.pincodeModal = true; //!store.state.pinCodeModal
    }

    const openKontakt = () => {
      store.state.showRundgang = false;
      router.push({ name: 'contactpage', params: { roomId: ""}, props: true})
      // router.push({ name: 'contactpage',props: true})
      // pushPage('contactPage')
    }
    
    const openImpressum = () => {
      store.state.showRundgang = false;
      router.push({ name: 'impressumpage', params: { roomId: ""}, props: true})
      // pushPage('impressumPage')
    }
    
    const openFoerderer = () => {
      store.state.showRundgang = false;
      router.push({ name: 'foerdererpage', params: { roomId: ""}, props: true})
      // pushPage('impressumPage')
    }
    
    const kSDekoline = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#rgba(148, 167, 143, 0.9294117647)',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                    }
               } 
        }
    

    return {
        // pageName,
        // pushPage,
        // openQrcodeScanner,
        openPincode,
        openKontakt,
        openImpressum,
        openFoerderer,
        kSDekoline,
        // props,
        route,
        store,
      }

  }
}

</script>


<style lang="scss" scoped>

.main-footer {
  background: rgb(0, 0, 0);
  // background: rgb(49, 146, 62);
  padding: 2rem 2.2rem;
  // height: fit-content(8rem);
  // margin-top: 2rem;
  /* grid-row: 3 / 4; */
  grid-area: footer;
}

// .base-footer {
//   background: rgb(201, 23, 23);
//   padding: 2rem;
//   height: 100px;
//   grid-area: basefooter;
// }


// ist jetzt im typo.scss da auch von home nav benutzt


// .main-footer__links {
//   // background-color: yellow;
//   list-style: none;
//   margin: 0;
//   padding: 0;
//   display: flex;
//   flex-direction: column;
//   align-items: start;
// }

// .main-footer__link {
//   // background-color: aquamarine;
//   margin: 0.5rem 0;
// }

// .main-footer__link a {
//   text-transform: uppercase;
//   color: white;
//   text-decoration: none;
// }

// .main-footer__link a:hover,
// .main-footer__link a:active {
//   color: #ccc;
// }

// .main-footer-deko {
//   // background-color: blue;
//   display: flex;
//   flex-direction: column;
//   align-items: start;
//   justify-content: space-between;
// }



// .main-footer-deko > p {
//   font-family: FontMedium;
//   padding-top: 0.0rem;
//   font-size: 0.8rem;
//   color: white;
// }

// @media (min-width: 40rem) {
//   .main-footer__link {
//     margin: 0 1rem 0 0.1rem;
//   }

//   .main-footer__links {
//     flex-direction: row;
//     justify-content: start;
//   }
// }

</style>