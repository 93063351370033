<template>

     <div>

          <!-- 576px für Porträt-Handys
          768px für Tablets
          992px für Laptops
          1200px für große Geräte -->

          <div class="basefull-container">
               <div class="base-c1"> 
                    <h4 :class="store.state.typoSwitch ? 'larger':'leer'">Bitte auswählen:</h4>
                    <!-- :space-between="12"
                    :slides-per-view="4" -->
                    <swiper class="swiper-container" ref="swiper" 
                         :pagination="{clickable: true }"
                         :modules="modules"
                         :centeredSlides="false"
                         :centeredSlidesBounds="false"
                         :loop="false"
                         :simulate-touch="true"
                         :allow-touch-move="true" 
                         @swiper="onSwiper"
                         @slideChange="onSlideChange"
                         :breakpoints="{
                              '240': {
                                   slidesPerView: 2,
                                   spaceBetween:8,
                              
                              },
                              '480': {
                                   slidesPerView: 3,
                                   spaceBetween:8,
                              
                              },
                              '576': {
                                   slidesPerView: 3,
                                   spaceBetween: 8,
                              
                              },
                              '768': {
                                   slidesPerView: 4,
                                   spaceBetween:24,
                              
                              },
                              '992': {
                                   slidesPerView: 5,
                                   spaceBetween:32,
                              
                              },
                              '1200': {
                                   slidesPerView: 6,
                                   spaceBetween:32,
                    
                              },
                         }"

                    >    
                         <swiper-slide v-for="item, index in contentwp" :key="index" >
                              <!-- <div  :class="index==store.state.roomId ? 'raumkachel-active':'raumkachel'" :style="kSKachel()" @click="contentRoom(index)" >  -->
                              <div class="raumkachel" :style="index==store.state.roomId ? kSKachelActive():kSKachel()" @click="contentRoom(index)" :class="store.state.typoSwitch ? 'larger':'leer'"> 
                                   <p :class="store.state.typoSwitch ? 'larger':'leer'" :style="index==store.state.roomId ? kSKachelFontActive():kSKachelFont()" v-html="item.custom_fields['wpcf-menu_' + store.state.language]">  </p> 
                              </div>  
                         </swiper-slide>
                         
                    </swiper>
               </div>
          </div>


          
          <transition name="fadepageslow" mode="out-in" appear> 
          <div :key="store.state.roomId">

               <div class="basefull-container" >
                    <div class="base-c1"> 
                              <div class="headline-container">
                                   <h1 class="deselect" v-html="contentwp[store.state.roomId].custom_fields['wpcf-raumname_' + store.state.language]"></h1>
                                   <hr class="deselect" style="{opacity: 0.0}"><AD v-if="contentwp[store.state.roomId].custom_fields['wpcf-ad_'  + store.state.language]==true && store.state.contentUi.noaudio==false" :basename="contentwp[store.state.roomId].custom_fields['wpcf-ad_basename']" :language="store.state.language" :contentui="contentui"></AD>
                              </div>
                              <hr :style="kSHr()">
                    </div>
                   
               </div>
               
               <!-- <div class="basefull-container" v-if="store.state.roomId==0"> -->
               <div class="basefull-container" v-if="store.state.roomId==0 && store.state.roomPageId==1">
                    <div class="base-c1">           
                              <!-- <div class="content-block" > -->
                                   <Bibel3D></Bibel3D>
                              <!-- </div> -->
                    </div>
               </div>

               <div class="basefull-container" :style="kSBackground()"  v-if="contentwp[store.state.roomId].custom_fields['wpcf-normalwysiwyg_' + store.state.language] != null">
                    <div class="base-c1"> 
                              <!-- normale Textseite + audio -->
                              <!-- <div class="content-block"  > -->
                                   <!-- <div  > -->
                                        <PageHeadline :headline="contentwp[store.state.roomId].custom_fields['wpcf-normalheadline_' + store.state.language]"></PageHeadline>
                                        <!-- <h1>xxx</h1> -->
                                        <div v-html="filterBR(contentwp[store.state.roomId].custom_fields['wpcf-normalwysiwyg_' + store.state.language])" :class="store.state.typoSwitch ? 'larger':'normal'"></div>
                                        <!-- <p>hallo</p> -->
                                        <!-- <p v-html="contentwp[store.state.roomId].custom_fields['wpcf-audiobasename']"></p> -->
                                        <!-- <transition name="fadepage"  mode="out-in" :key="store.state.language"> -->
                                             <!-- controls crossorigin playsinline -->
                                            
                                        <!-- <p> raum {{ contentwp[store.state.roomId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.mp3' }} </p>      -->
                                        <!-- <div :key="store.state.language"> -->
                                        <vue-plyr :options="optionsAudio" ref="vplyraudio1" @play="vPlayCheck('audio1')"  v-if="contentwp[store.state.roomId].custom_fields['wpcf-audio_' + store.state.language]==1&&store.state.contentUi==false" :key="store.state.language">
                                             <audio>
                                                  <source :src="'/mp3/' + contentwp[store.state.roomId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.mp3'" type="audio/mp3" />
                                                  <source :src="'/mp3/' + contentwp[store.state.roomId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.ogg'" type="audio/ogg" />
                                             </audio>
                                        </vue-plyr>
                                        <!-- </div> -->

                                        <!-- <vue-plyr :options="optionsAudio" ref="vplyraudio2" @play="vPlayCheck('audio2')"  v-if="(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-audio_' + store.state.language]==1)&&(contentui.noaudio==false)" :key="store.state.language">
                                             <audio>
                                                  <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.mp3'" type="audio/mp3" />
                                                  <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.ogg'" type="audio/ogg" />
                                             </audio>
                                        </vue-plyr> -->


                                        <!-- </transition> -->
                                   <!-- </div> -->
                              <!-- </div> -->
                    </div>
               </div>

               <div class="basefull-container" :style="kSBackground()" v-if="checkForRaumSeiten()==true">
                    <div class="base-c1" >                
                              <!-- raumseiten zum durchblättern -->
                              <!-- <div class="content-block" > -->
                                   <PageLeftRight></PageLeftRight>  

                                   <transition name="fadepage"  mode="out-in" appear>
                                   <div :key="store.state.roomPageId" :class="store.state.typoSwitch ? 'larger':'normal'">
                                        <PageHeadline :headline="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-seiteheadline_' + store.state.language]" v-touch:swipe.right="prevRoomPage" v-touch:swipe.left="nextRoomPage"></PageHeadline>
                                        <!-- <p> {{ store.state.roomId  }} {{ store.state.roomPageId }}  {{ store.state.language }}</p> -->
                                        <!-- <p> {{ contentwp[store.state.roomId].children[store.state.roomPageId]  }} </p> -->
                                        <p  v-html="filterBR(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-seitewysiwig_' + store.state.language])" v-touch:swipe.right="prevRoomPage" v-touch:swipe.left="nextRoomPage"></p> 
                                        
                                        <!-- <p> unterseite {{  contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.mp3' }}  </p>  -->
                                        <vue-plyr :options="optionsAudio" ref="vplyraudio2" @play="vPlayCheck('audio2')"  v-if="(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-audio_' + store.state.language]==1)&&(store.state.contentUi.noaudio==false)" :key="store.state.language">
                                             <audio>
                                                  <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.mp3'" type="audio/mp3" />
                                                  <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-audiobasename'] + '_' + store.state.language + '.ogg'" type="audio/ogg" />
                                             </audio>
                                        </vue-plyr>
                                        <!-- </transition> -->

                                   </div>
                                   </transition>

                                   <hr>
                                   
                                   <transition name="fadepage"  mode="out-in" appear>
                                        <div v-if="(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudio1']) && (store.state.language == 'de') && (store.state.contentUi.noaudio==false)">

                                             <div>
                                             <PageHeadline :headline="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudioheadline1']"></PageHeadline>
                                             <p v-html="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudiowysiwig1']"></p>     
                                             <vue-plyr :options="optionsAudio" ref="vplyraudio2" @play="vPlayCheck('audio2')" :key="store.state.language">
                                                  <audio>
                                                       <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudio1'] + '.mp3'" type="audio/mp3" />
                                                       <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudio1'] + '.ogg'" type="audio/ogg" />
                                                  </audio>
                                             </vue-plyr>
                                             <hr>
                                             <PageHeadline :headline="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudioheadline2']"></PageHeadline>
                                             <p v-html="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudiowysiwig2']"></p>  
                                             <vue-plyr :options="optionsAudio" ref="vplyraudio2" @play="vPlayCheck('audio2')" :key="store.state.language">
                                                  <audio>
                                                       <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudio2'] + '.mp3'" type="audio/mp3" />
                                                       <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudio2'] + '.ogg'" type="audio/ogg" />
                                                  </audio>
                                             </vue-plyr>
                                             <hr>
                                             <PageHeadline :headline="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudioheadline3']"></PageHeadline> 
                                             <p v-html="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudiowysiwig3']"></p>  
                                             <vue-plyr :options="optionsAudio" ref="vplyraudio2" @play="vPlayCheck('audio2')" :key="store.state.language">
                                                  <audio>
                                                       <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudio3'] + '.mp3'" type="audio/mp3" />
                                                       <source :src="'/mp3/' + contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-singleaudio3'] + '.ogg'" type="audio/ogg" />
                                                  </audio>
                                             </vue-plyr>
                                             </div>
                                        
                                        </div>
                                   </transition> 
                                   <!-- <hr> -->
                                   

                              <!-- </div> -->
                    </div>
               </div>                   
                         

              
               <div class="basefull-container" v-if='store.state.roomId==6'>
                    <div class="base-c1">
                         <!-- Inhalt und Präsentation (Evangeliarraum)  [Texte Stahlwände  + Buch Einzelseiten + Audios (tws? ) + Audiodescription Raum]  -->
                         <!-- <h3>{{ uicontent.rooms[store.state.roomId].name}}</h3> -->
                         <div class="content-block" >
                              <h4 class="deselect" :class="store.state.typoSwitch ? 'larger':'leer'">{{store.state.contentUi.ui.filter[store.state.language] }}</h4>
                              <div class="flex-row-left">
                                   <!-- <ToggleButton id="switch" v-on:change="toggleSeiten" label-enable-text="" label-disable-text=""/> -->
                                   <div class="button-base" :style="filterActive(0)" @click="toggleSeiten(false)"><p>{{store.state.contentUi.ui.but1[store.state.language]}}</p></div>
                                   <div class="button-base" :style="filterActive(1)" @click="toggleSeiten(true)"><p>{{store.state.contentUi.ui.but2[store.state.language]}}</p></div>
                                   <div class="button-base" :style="markerActive()" @click="toggleMarker()" v-if="showToggleMarker==true"><p>{{store.state.contentUi.ui.but3[store.state.language]}}</p></div>
                              </div>
                         
                              <transition name="fadepage" mode="out-in" appear >
                                   <div v-if="showAll==true" :key="showAll">
                                        <h4 class="deselect" :class="store.state.typoSwitch ? 'larger':'leer'"> {{store.state.contentUi.ui.kapitel[store.state.language] }} </h4>
                                        <dropdown  @interface="dropDownSelection" :options="onDropDownOptions()" :externindex="externIndex" ></dropdown>
                                   </div>
                              </transition>

                              <!-- <div class="flex-row">  --> 
                                   <!-- <transition name="fadeshow" appear mode="out-in" > -->
                                        <!-- <button @click="prevPage()" :style="currBibel < 2 ? '{ visibility: hidden }' : '{ visibility: visible }'" v-show="currBibel>1" >
                                             <a> &#171; </a>   
                                        </button> -->
                                   <!-- </transition> -->

                                   <!-- <p> {{currBibel}} von {{ maxBibel }} Seiten </p> -->
                                   
                                   <!-- <transition name="fadeshow" appear mode="out-in"> -->
                                        <!-- <button  @click="nextPage()"  :style="currBibel > maxBibel ? '{ visibility: hidden }' : '{ visibility: visible }'" v-show="currBibel<maxBibel" >
                                             <a> &#187; </a>   
                                        </button> -->
                                   <!-- </transition> -->
                              <!-- </div> -->

                         </div>
                         <!-- <hr> -->
                    </div>
               </div>               
               
               <audio crossorigin playsinline ref="refzitataudio">
                    <source :src="'/mp3/' + zitatAudio +'.mp3'" type="audio/mp3" />
                    <source :src="'/mp3/' + zitatAudio +'.ogg'" type="audio/ogg" />
               </audio>

               <div class="basefull-container" :style="kSBackground()" v-if='store.state.roomId==6'> 
                    <div class="base-c1">
                              
                              <PageHeadline :headline="evangeliarSubMenuLong"></PageHeadline>
                             
                              <transition name="fadepage" mode="out-in" appear >
                                   
                                   <div class="bibel-container" style="kSBackground()" :key="currBibel">
                                   
                                        <div class="bibel-image">
                                             <bibel :showmarker="showMarker" :bibelstellen="bibelstellen" :path="path" v-touch:swipe.right="prevPage" v-touch:swipe.left="nextPage" ></bibel>
                                        </div>
                                        
                                        <div class="flex-row-end" > 
                                             <div class="page-button-links" :style="showPageButton('left')" @click="prevPage()" >
                                                  <img src="../assets/svgs/pfeil-links.svg" alt="">
                                             </div>
                                             <p class="page-pagination" :class="store.state.typoSwitch ? 'large':'normal'"> {{ getScanName() }}  {{ currBibel }}  /  {{ maxBibel }}  </p>    <!-- debug  ( SCAN# {{currPathNumber}} ) -->                                              
                                             <div class="page-button-rechts" @click="nextPage()" :style="showPageButton('right')" >
                                                  <img src="../assets/svgs/pfeil-rechts.svg" alt="">
                                             </div>                                        
                                        </div>

                                        <div v-if="selectedContent.length > 0">
                                             <div v-if="selectedContent[currBibel-1].hasOwnProperty('markerText')"> 
                                                  <!-- <p v-for="markerText, index in selectedContent[currBibel-1].markerText" v-html="(index+1) + ' ' + markerText.la +'<br>' + (index+1) + ' ' + markerText.de" :key="index"></p> -->
                                                  <blMarkerAccordion :content="selectedContent[currBibel-1].markerText" :language="store.state.language"></blMarkerAccordion>
                                             </div>
                                        </div>
                                        <div class="map-container" v-if="auswahlWand==true">
                                             <Map :mappos="getMapPos()"></Map>
                                        </div>

                                   </div>
                              </transition>
                    </div>
               </div>
               
               
               <!-- Zusatzinfos bisher nicht integriert-->
               <!-- <div class="basefull-container" v-if='store.state.roomId==6' >
                    <div class="base-c1">  
                         <transition name="fadepage" mode="out-in" appear >
                                   <div class="bibel-container" :key="currBibel">
                                        
                                        <div v-if="selectedContent[currBibel-1].seitenText.hasOwnProperty('bibelheadline_' + store.state.language)">
                                             
                                             <SmallHeadline :headline="store.state.contentUi.ui.weitereinfos[store.state.language]" :uppertext="store.state.contentUi.ui.textundaudio[store.state.language]"></SmallHeadline>

                                             <div v-if="selectedContent[currBibel-1].seitenText['bibelheadline_' + store.state.language].raw!=''" >
                                                  <blOneAccordion :headline="selectedContent[currBibel-1].seitenText['bibelheadline_' + store.state.language].raw" :text="selectedContent[currBibel-1].seitenText['bibeltext_' + store.state.language].raw"></blOneAccordion>
                                             </div>

                                             <div v-if="selectedContent[currBibel-1].seitenText['bibelheadline_' + 'la'].raw!=''" >
                                                  <blOneAccordion :headline="selectedContent[currBibel-1].seitenText['bibelheadline_' + 'la'].raw" :text="selectedContent[currBibel-1].seitenText['bibeltext_' + 'la'].raw"></blOneAccordion>
                                             </div>
                                             
                                        </div>
                                   </div>
                              
                         </transition>
                    </div>
               </div>  -->
                                                  
                   
          </div>
         
          </transition>


          <!-- <div class="basefull-container">      -->
          <div class="content-footer">
               <div class="button-pfeil-links" @click="prevRoom()" :style="showRoomButton('left')">
                    <img src="../assets/svgs/pfeil-links.svg" alt="">
                    <p :class="store.state.typoSwitch ? 'larg':'leer'" v-html="props.contentui.ui.prevroom[store.state.language]"></p>   
                    <!-- <p :html="uicontent.ui.prev['de']"></p>    -->
               </div>
               <div class="footer-line"></div>
               <div class="button-pfeil-rechts" @click="nextRoom()" :style="showRoomButton('right')">
                    <p :class="store.state.typoSwitch ? 'larg':'leer'" v-html="props.contentui.ui.nextroom[store.state.language]"></p>
                    <img src="../assets/svgs/pfeil-rechts.svg" alt="">
                    <!-- <p :html="uicontent.ui.next['de']"></p>    -->
               </div>         
          </div> 
     </div>
    
</template>



<script>
import { onMounted, onUnmounted,  watch, onBeforeUnmount  } from 'vue'; // ref, onMounted, computed, getCurrentInstance
import { useRoute, useRouter } from 'vue-router'  // , useRoute, useRouter
import { ref } from 'vue'; // ref, onMounted, computed
import { useStore } from 'vuex'
// import { Pagination } from "swiper";
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { Pagination } from 'swiper'; // Navigation, , Scrollbar, A11y

// import "swiper/css/pagination";
// import 'swiper/swiper.scss';

// import Swiper core and required modules

// install Swiper modules


/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

Seitenreihenfolge wurde im Originalsatz verändert
126=128 und 127=129  => keine Auswirkungen aufs CMS
290 und 291 sind gleich, deshalb wurde ab 292 die Seitenzahl verringert.

=> maxBibel von 347 => 346


!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */




import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
// import "swiper/css/pagination";
import { Pagination } from "swiper";
import useEmitter from '../composables/useEmitter'


// import { useRouter, useRoute } from 'vue-router'  // 

// https://troisjs.github.io/


// import { AmbientLight, Camera, GltfModel, Renderer, Scene, } from 'troisjs';


// import { InstancedBufferAttribute, Object3D } from 'three';
// import SimplexNoise from 'simplex-noise';

// const simplex = new SimplexNoise();  

import chroma from "chroma-js";
// import { MathUtils } from 'three';

import Bibel from '../components/Bibel-svg.vue';
import Map from '../components/Map-svg.vue';
import Dropdown from '../components/Dropdown.vue';
import PageHeadline from '../components/PageHeadline.vue';
// import SmallHeadline from '../components/SmallHeadline.vue'; // für Zusatzinfos
import PageLeftRight from '../components/PageLeftRight.vue';
import Bibel3D from '../views/IntroViewBibel.vue';
import AD from '../components/Audiodescription.vue';
import blMarkerAccordion from '../components/blMarkerAccordion.vue'
// import blOneAccordion from '../components/blOneAccordion.vue' // für Zusatzinfos
// import "../css/tabs-component.scss";


export default {
     
     props: {
          contentui:Object,
          content: Object,
          contentwp: Object,
          zitate: Array,
          language: String,
     },

     components: { 
          Swiper,
          SwiperSlide,
          Bibel,
          PageHeadline,
          // SmallHeadline,
          PageLeftRight,
          AD,
          Dropdown,
          // ToggleButton,
          // Tabs,
          // Tab,
          Bibel3D,
          Map,

          blMarkerAccordion,
          // blOneAccordion,

          // VueCollapsiblePanel,
          // VueCollapsiblePanelGroup
          // AmbientLight,
          // Camera,
          // GltfModel,
          // Renderer,
          // Scene,
     },

     setup(props) { // props
          const emitter = useEmitter();

          // const SIZE = 1.6, NX = 25, NY = 25, PADDING = 1;
          // const SIZEP = SIZE + PADDING;
          // const W = NX * SIZEP - PADDING;
          // const H = NY * SIZEP - PADDING;
          // let path = "/bibel/bibel-113-retouchiert.jpg"
          // let bibelstellen = ref([ [  [84,615,399,32]  ],
          //                          [  [410,615,600,32], [84,649,600,32] ],
          //                          [  [80,715,600,32], [80,750,600,32] ]
          //                     ])

          let bibelstellen = ref([ ])


          let store = useStore()
          const router = useRouter();
          const route = useRoute();

          // let roomId = ref(route.params.roomId);
          // let roomPage = parseInt(route.params.pageId);
          
          let showToggleMarker = ref(false);
          let showMarker = ref("visible")
          // console.log("setup route.params.roomId", route.params.roomId);
          let currBibel = ref(1);
          let maxBibel = ref(346);
          let path = ref("/bibel/bibel-base.jpg")
          let wand = ref(1);

          // let dropDownOptions = [ 
          //               "Pater-Noster-Diagramm","Widmungsgedicht","Hieronymus: Prolog Matthäus-Evangelium","Hieronymus: Prolog Matthäus-Evangelium",
          //               "Brief an Carpianus","Prolog  Matthäus-Evangelium","Kapitelübersicht Matthäus-Evangelium",
          //               "Kanontafeln","Matthäus-Evangelium","Leer","Prolog Markus-Evangelium","Kapitelübersicht Markus-Evangelium",
          //               "Markus-Evangelium","Prolog Lukas-Evangelium","Kapitelübersicht Lukas-Evangelium","Lukas-Evangelium",
          //               "Prolog Johannes-Evangelium","Kapitelübersicht Johannes-Evangelium","Johannes-Evangelium","Federproben und Männerkopf","Tage des Kirchenjahres","Kolophon"
          //            ]
          let showAll = ref(true);
          let selectedContent = ref({});
          let evangeliarSubMenuLong = ref("submenüText in lang")
          let externIndex = ref(0);
          let auswahlWand = ref(false);
          let time = ref(0);
          let swiperIndex = ref(1);
          let swiper = null;
          let evangeliarSeiten = ref([]);
          let zitatAudio = ref("");
          let refzitataudio = ref(null)
          let mapPoints = [   
                              [130,500] , [130,400] , [130,300] , [130,200], [130,100] ,
                              [155, 80] , [260, 80] , [360, 80] , [460, 80] , [560,80] , [660, 80],
                              [690,100] , [690,200] , [690,300] , [690,400] , [690,500] ,
                              [660,520] , [560,520] , [460,520] , [360,520] , [260,520] , [155,520] 
                          ]
          let currPathNumber = ref(0);
       
          // let adBaseName = "/mp3/Conclusion_de"

          // const router = useRouter();
          // const route = useRoute();

          // const next = () => { 
          //      store.state.roomId = store.state.roomId +1;
          //      router.push({ name: "rundgang", params: { roomId: store.state.roomId, language: store.state.language}, props: true})
          // }

          // const prev = () => { 
          //      store.state.roomId = store.state.roomId -1;
          //      router.push({ name: "rundgang", params: { roomId: store.state.roomId, language: store.state.language}, props: true})
          // }
          // const onPlayAudioZitat = (value) => {
          //      console.log("onPlayAudioZitat", value)    
          // }
          
          // const getBreakPoints = () => {
          //      return  {
          //                     320: {
          //                          slidesPerView: 2,
          //                          spaceBetween: 20
          //                     },
          //                     480: {
          //                          slidesPerView: 3,
          //                          spaceBetween: 30
          //                     },
          //                     640: {
          //                          slidesPerView: 4,
          //                          spaceBetween: 40
          //                     },
          //                     840: {
          //                          slidesPerView: 5,
          //                          spaceBetween: 40
          //                     }
          //                }
          // }
          
          const getMapPos = () => {
               // console.log("getMapPos", mapPoints[wand.value])

               if (selectedContent.value[currBibel.value-1].mapPos.length == 1){
                    let tWand = selectedContent.value[currBibel.value-1].mapPos[0] -1;
                    return [  [mapPoints[tWand],"x"] ];

               } else if (selectedContent.value[currBibel.value-1].mapPos.length == 2){

                    let tWand1 = selectedContent.value[currBibel.value-1].mapPos[0] -1;
                    let tWand2 = selectedContent.value[currBibel.value-1].mapPos[1] -1;

                    if (tWand1 == tWand2) {
                         return [  [mapPoints[tWand1],"x"]  ]
                    } else {
                         return [  [mapPoints[tWand1],"1"] ,  [mapPoints[tWand2],"2"]  ]
                    }

                    // let tWand = selectedContent.value[currBibel.value-1].mapPos[0];
                    // return [ [mapPoints[tWand],"x"] ];

               } else if (selectedContent.value[currBibel.value-1].mapPos.length == 3){

                    let tWand1 = selectedContent.value[currBibel.value-1].mapPos[0] -1;
                    let tWand2 = selectedContent.value[currBibel.value-1].mapPos[1] -1;
                    let tWand3 = selectedContent.value[currBibel.value-1].mapPos[2] -1;

                    if ( (tWand1 == tWand2) && (tWand1 == tWand3) ) {
                         return [  [mapPoints[tWand1],"x"]  ]
                    } else {
                         return [  [mapPoints[tWand1],"1"] ,  [mapPoints[tWand2],"2"] ,  [mapPoints[tWand3],"3"] ]
                    }

                    // let tWand = selectedContent.value[currBibel.value-1].mapPos[0];
                    // return [ [mapPoints[tWand],"x"] ];

               }     
               
               // return mapPoints[wand.value];
          }

          const getScanName = () => {
               // console.log("getScanName", auswahlWand.value)
               if (auswahlWand.value == false){
                    return store.state.contentUi.ui.pagination[store.state.language]
               } else {
                    return store.state.contentUi.ui.paginationauswahl[store.state.language]
               }
               
          }

          const prevRoomPage = () => {
               
               if ( store.state.roomPageId > 0 ) {
                   store.state.roomPageId = store.state.roomPageId -1
                   router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId:  store.state.roomPageId}, props: true})   // , language: store.state.language
               } else {
                   store.state.roomPageId = store.state.maxRoomPage -1;
                   router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId: store.state.roomPageId}, props: true})   // , language: store.state.language
               }
               // console.log("prevRoomPage", store.state.roomPageId )
              
           }
   
           const nextRoomPage = () => {
                   
               if (store.state.roomPageId < store.state.maxRoomPage-1){
                   store.state.roomPageId = store.state.roomPageId +1;
                   router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
               } else {
                   store.state.roomPageId = 0;
                   router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
               }
              

               // console.log("nextRoomPage", store.state.roomPageId)
           }


          const toggleSeiten = (value) => {
               // console.log("toggleSeiten", value);
               auswahlWand.value = value;
               bibelstellen.value = [];

               if (value == true){
                    currBibel.value = 1;
                    selectedContent.value = evangeliarSeiten.value.filter(seite => seite.markerPos.length != 0);  // props.content.evangeliarSeiten.filter(seite => seite.markerText != null);
                    console.log(selectedContent.value.length)
                    maxBibel.value = selectedContent.value.length;
                    // console.log("selectedContent", selectedContent);
                    showAll.value = false;
                    showToggleMarker.value = true;
                    showMarker.value = "visible"
                    
                    checkBibelStellen();
               } else  if (value == false) {
                    currBibel.value = 1;
                    selectedContent.value = evangeliarSeiten.value // props.content.evangeliarSeiten.filter(seite => seite.nr != null);
                    // console.log(selectedContent.value.length)
                    maxBibel.value = selectedContent.value.length
                    // console.log("selectedContent", selectedContent.value);
                    showAll.value = true;
                    checkBibelStellen();
               }
               
               // console.log("Toggle Auswahl:", selectedContent.value.length);

          }

          const onDropDownOptions = () => {
               let ary = []

               for (let i = 0; i < store.state.content.evangeliarSubMenu.length; i++) {
                    ary.push( store.state.content.evangeliarSubMenu[i][store.state.language])
               }
               return ary
          }

          const kSHr = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#1e211d',
                         border: '#2px solid #1e211d',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                         border: '#2px solid #ffffff',
                    }
               } 
          }

          const onSwiper = (swipertemp) => {
            swiper = swipertemp
            // console.log(swiper);
          };
        
          const onSlideChange = (swiper) => {
               // console.log("activeIndex", swiper.activeIndex);
               swiperIndex.value = swiper.activeIndex
               if (swiperIndex.value == 0) {
                    swiperIndex.value = 3
               } else if (swiperIndex.value ==4) {
                    swiperIndex.value = 1
               }
               time.value = 0;
               // console.log('slide change');
          };

          const onLeft = () => {
            // console.log("left")
            swiper.slideNext();
        }

        const onRight = () => {
            //console.log("right")
            swiper.slideNext();
        }

        const showPageButton = (mode) => {
          if (mode == "left"){
               if ( currBibel.value>1 ) {
                    return { opacity: 1.0 }
               } else {
                    return { opacity: 0.2 }
               }
          } else if (mode == "right"){
               if ( currBibel.value < maxBibel.value ) {
                    return { opacity: 1.0 }
               } else {
                    return { opacity: 0.2 }
               }
          }
        }

        const showRoomButton = (mode) => {
          if (mode == "left"){
               if ( store.state.roomId > 0 ) {
                    return { opacity: 1.0 }
               } else {
                    return { opacity: 0.2 }
               }
          } else if (mode == "right"){
               if ( store.state.roomId < 6 ) {
                    return { opacity: 1.0 }
               } else {
                    return { opacity: 0.2 }
               }
          }
        }

        const filterActive = (mode) => {

          // console.log("mode", mode);

          if (mode == 0 && auswahlWand.value == false) {
               return {
                    backgroundColor: "#000000",
                    border: "1px #94a78f solid", 
               }
          } else if (mode == 0 && auswahlWand.value == true) {
               return {
                    backgroundColor: "#94a78f",
                    border: "1px #94a78f solid", 
               }

          } else if (mode == 1 && auswahlWand.value == true) {
               return {
                    backgroundColor: "#000000",
                    border: "1px #94a78f solid", 
               }
          } else if (mode == 1 && auswahlWand.value == false) {
               return {
                    backgroundColor: "#94a78f",
                    border: "1px #94a78f solid", 
               }
          }

        }

        const markerActive = () => {

               if (showMarker.value == "hidden") {
                    return {
                         backgroundColor: "#000000",
                         border: "1px #94a78f solid", 
                    }
               } else {
                    return {
                         backgroundColor: "#94a78f",
                         border: "1px #94a78f solid", 
                    }
               }

        }

        const kSBackground = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#1e211d',
                    }
               } else {
                    return {
                         backgroundColor: '#000000',
                    }
               } 
        }

        const kSKachel = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#1e211d',
                         border: '1px solid #1e211d',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                         border: '1px solid #000000',
                    }
               } 
        }

        const kSKachelActive = () => {
               // console.log("kSKachelActive")
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#000000',
                         border: '1px solid #4c5749',
                    }
               } else {
                    return {
                         backgroundColor: '#000000',
                         border: '1px solid #ffffff',
                    }
               } 
        }

        const kSKachelFont = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         color: '#ffffff',
                    }
               } else {
                    return {
                         color: '#000000',
                    }
               } 
        }

        const kSKachelFontActive = () => {
               // console.log("kSKachelActive")
               if (store.state.kontrastSwitch == false) {
                    return {
                         color: '#00000',
                    }
               } else {
                    return {
                         color: '#ffffff',
                    }
               } 
        }

        const contentRoom = (index) => {
               store.state.roomPageId = 0; // XXX
               store.dispatch("setRoomId", index);
               auswahlWand.value = false;
               // store.dispatch("setPlot", { geometry: newFeature });
               // roomId.value = index;
               // console.log("contentRoom", store.state.roomId );

               // if(store.state.roomId == 0){
               //      store.state.roomId = parseInt(route.params.roomId);
               //      store.state.showRundgang = true;
               // }

               // console.log("roomPage in content", roomPage);
               // if (roomPage == undefined){
               //      roomPage = 1;
               // }
               // console.log("roomPage in content", roomPage);
               // console.log("EXXXTERN MOUNTED PAGEID", route.params.pageId);

               // if (route.params.pageId != null || route.params.pageId == ""){
               //      store.state.roomPageId = parseInt(route.params.pageId)
               // } else {
               //      store.state.roomPageId = 1;
               // }

               // console.log("EXXXTERN MOUNTED PAGEID AFTER", route.params.pageId);

               store.state.showRundgang = true
               if(store.state.roomId == 6){
                    auswahlWand.value = false;
                    bibelstellen.value = [];
                    selectedContent.value = evangeliarSeiten.value // .filter(seite => seite.bibelseite.nr.raw != null);  // props.content.
                    // console.log("selectedContent", selectedContent.value)
                    maxBibel.value = selectedContent.value.length;
                    console.log(maxBibel.value)
                    checkBibelStellen();
               }

               setTimeout(() =>{
                    // console.log("scroller timeout")
                    window.scrollTo(0, 0);
               }, 500)

               // router.push({ name: "rundgangpage", params: { roomId: store.state.roomId}, props: true})  // , language: store.state.language
          }

          const nextPage = () => {
               // console.log("nextPage")
               currBibel.value < maxBibel.value-1 ? currBibel.value ++ : currBibel.value = maxBibel.value;
               checkBibelStellen();
          }

          const prevPage = () => { 
               // console.log("prevPage")
               currBibel.value > 1 ? currBibel.value -- : currBibel.value = 1;
               checkBibelStellen();
          }
          
          const nextRoom = () => {
               // if (store.state.roomId < (props.uicontent.rooms.length-1)){
               // store.state.roomId = store.state.roomId +1;
               //      router.push({ name: "rundgangpage", params: { roomId: store.state.roomId}, props: true})   // , language: store.state.language
               // }
               contentRoom(store.state.roomId +1)
               setTimeout(() =>{
                    console.log("scroller timeout")
                    window.scrollTo(0, 0);
               }, 500)
          }

          const prevRoom = () => {
               // if (store.state.roomId >= 0){
               //      store.state.roomId = store.state.roomId -1;
               //      router.push({ name: "rundgangpage", params: { roomId: store.state.roomId}, props: true})   // , language: store.state.language
               // }
               contentRoom(store.state.roomId -1)
               setTimeout(() =>{
                    console.log("scroller timeout")
                    window.scrollTo(0, 0);
               }, 500)
          }


          // const prevRoomPage = () => {
               
          //      if ( store.state.roomPageId > 1 ) {
          //           store.state.roomPageId = store.state.roomPageId -1
          //           router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId:  store.state.roomPageId}, props: true})   // , language: store.state.language
          //      } else {
          //           store.state.roomPageId = 3
          //           router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId: store.state.roomPageId}, props: true})   // , language: store.state.language
          //      }
          //      console.log("prevRoomPage", store.state.roomPageId )
          // }

          // const nextRoomPage = () => {
               
          //      if (store.state.roomPageId < maxRoomPage){
          //           store.state.roomPageId = store.state.roomPageId +1;
          //           router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
          //      } else {
          //           store.state.roomPageId = 1;
          //           router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
          //      }
          //      console.log("nextRoomPage", store.state.roomPageId)
          // }
          // const checkForNormaleSeite = () => {
          //      contentWp[store.state.roomId].custom_fields['wpcf-normaltext_' + language] != null
          //      return false 
          // }
          const filterBR = (xxx) => {
               //console.log(xxx);
               // return "<H1>hallo</H1>"
               // let  yyy = xxx /// .replace('<br />','\r' );
               //console.log(xxx);
               let yyy = xxx.replace(/(?:\r\n|\r|\n)/g, '');
               //console.log(yyy);
               // console.log(yyy)
               return  yyy
               // return "<H1>hallo</H1>"
          }

          const checkForRaumSeiten = () => {
               if ( props.contentwp[store.state.roomId].children.length > 0 ){
                    // console.log("checkForRaumSeiten true")
                    return true;
               } else {
                    // console.log("checkForRaumSeiten false")
                    return false;
               }
               
          }
          
          const dropDownSelection = (index) => { 
               // console.log("dropDownSelection", index)
               currBibel.value = props.content.evangeliarSubMenu[index].seite[0]
               // console.log("currBibel", currBibel)
               evangeliarSubMenuLong.value = props.content.evangeliarSubMenu[index]["textlang_" + props.language]
               checkBibelStellen()

          }
          
          const checkBibelStellen = () => {
               if (selectedContent.value[currBibel.value-1].markerPos != null){
                    bibelstellen.value = selectedContent.value[currBibel.value-1].markerPos
                    // console.log("checkBibelStellen",bibelstellen.value )
                    showToggleMarker.value = true;
               } else {
                    bibelstellen.value = [];
                    showToggleMarker.value = false;
               }
               // console.log("checkBibelStellen", bibelstellen)

               // welches evangeliarSubMenuLong wird angezeigt
               const results = props.content.evangeliarSubMenu.filter(menu => ( (menu.seite[0] <= currBibel.value) && (menu.seite[1] >= currBibel.value) ) );
               // console.log("results", results, currBibel.value)
               // const results = resultstemp.filter(menu => ( (menu.seite[1] >= currBibel.value) ) );
               // console.log("results", results, currBibel.value)
               if (auswahlWand.value==false ){
                    if (results.length > 0){
                         evangeliarSubMenuLong.value = results[0]["textlang_" + props.language];
                    }else {
                         evangeliarSubMenuLong.value = "&nbsp;";
                    }
               } else {
                    evangeliarSubMenuLong.value = store.state.contentUi.ui.auswahlwand[store.state.language]
                    // console.log("auswahlWand evangeliarSubMenuLong", evangeliarSubMenuLong.value);
                    // wand.value = parseInt(selectedContent.value[currBibel.value-1].seitenText.wand.raw) -1;
                    wand.value = parseInt(selectedContent.value[currBibel.value-1].mapPos[0]) -1;
                    // console.log ("wand", wand.value);
               }
               
               externIndex.value =  externIndex.value +1 ; 
               // und das bild
               if (process.env.NODE_ENV === "production"){
                    // var base_url = "https://www.bluelemon.de/demo/AL/mediaguide_v3/bibel/" // "." + publicPath
                    var base_url = "https://app.museum-abtei-liesborn.de/bibel/624/" // "." + publicPath
                    base_url = window.location.origin + "/bibel/624/" //   
               } else {
                    base_url =  window.location.origin +"/bibel/624/" //   
                    // base_url =  "." + publicPath
               }
               // console.log(selectedContent.value[currBibel.value-1]);
               currPathNumber.value = parseInt(selectedContent.value[currBibel.value-1].nr);
               

               if (selectedContent.value[currBibel.value-1].markerPos.length > 0){
                    showToggleMarker.value = true;
               } else {
                    showToggleMarker.value = false;
               }
               // console.log("currPathNumber", currPathNumber.value);
               path.value = base_url + currPathNumber.value + '.jpg'; // base_url + selectedContent.value[currBibel.value-1].image;
               // console.log("path", path.value)
               // path.value = '/bibel/' + selectedContent.value[currBibel.value-1].image;
          }

          const kSDekoline = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#94A78Fed',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                    }
               } 
        }

          watch(() =>  props.language, (first, second) => {
               console.log( "Watchstore language function called with args:", first, second);
               if (auswahlWand.value==false ){
                    const results = props.content.evangeliarSubMenu.filter(menu => ( (menu.seite[0] <= currBibel.value) && (menu.seite[1] >= currBibel.value) ) );
                    evangeliarSubMenuLong.value = results[0]["textlang_" + props.language]
               } else {
                    evangeliarSubMenuLong.value = store.state.contentUi.ui.auswahlwand[store.state.language]
                    console.log("auswahlWand evangeliarSubMenuLong", evangeliarSubMenuLong.value);
               }
          });

          watch(() =>  route.params.pageId, (first, second) => {
               console.log( "router params changed", first, second);
          });
          
          // store.state.roomPageId = to.params.pageId;
          // onCreated(() => {
          //      console.log("created")
          // })
          onUnmounted(() => {
               // refzitataudio.value.stop();
          })

          onBeforeUnmount(() => {
               // refzitataudio.value.stop();
          })

          onMounted(() => {
               store.state.roomPageId = 1;
               // console.log("onMounted", props.content)
               // console.log("onMounted contentWp", props.contentwp[store.state.roomId].custom_fields['wpcf-ad_'  + props.language])

               // init der bibelseiten erst einmal leer anlegen nur mit nr.
               for (let i = 1; i <= maxBibel.value; i++) {
                    let o = { 
                         "nr": i,
                         "markerPos": [],
                         "markerText": [],
                         // "seitenText": []
                         "seitenText": {},
                         "mapPos": [],
                    }
                    evangeliarSeiten.value.push(o)
               }

               emitter.on("AUDIO_ZITAT", message => {
                    console.log("AUDIO_ZITAT", message)

                    // soll nur da hinscrollen
                    // getCurrentInstance().ctx.$refs["bibel" + message].scrollIntoView({ behavior: "smooth" });
                    // document.getElementById("bs"+message).scrollTo({ behavior: "smooth", top: 0 });

                    var my_element = document.getElementById("bs"+message);
                    console.log("my_element", my_element)
                    my_element.scrollIntoView({
                         behavior: "smooth",
                         block: "start",
                         inline: "nearest"
                         });


                    if (props.contentui.noaudio==false){
                         // refzitataudio.value.pause();
                         // refzitataudio.value.currentTime=0;
                         // zitatAudio.value = "07.35_de";
                         
                         // if (refzitataudio.value.paused == true) {
                              // paused.value = false;
                         //  refzitataudio.value.currentTime=0;
                         // refzitataudio.value.play();
                         // } else {
                              // paused.value = true;
                         //  refzitataudio.value.pause();
                         // }
                    }
                   

               })

               // console.log("zitate", props.zitate);
               for (let z = 0; z < props.zitate.length; z++) {
                    // console.log("zitate", z, props.zitate[z]);
                    let bibelseite = parseInt(props.zitate[z]["toolset-meta"].bibelseite.nr.raw);
                    // console.log("bibelseite", bibelseite)

                    // die mit ; delimited data in eiene arrays wandeln.
                    let splitAry = (props.zitate[z]["toolset-meta"].bibelzitate.pos.raw).split(";")
                    let splitAryJson = []

                    for (let index = 0; index < splitAry.length; index++) {
                         // console.log("index", z, index, splitAry);
                         splitAryJson.push (JSON.parse(splitAry[index]))
                    }

                    // evangeliarSeiten.value[bibelseite-1].markerPos.push([JSON.parse(props.zitate[z]["toolset-meta"].bibelzitate.pos.raw)] );
                    evangeliarSeiten.value[bibelseite-1].markerPos.push(splitAryJson);
                    // console.log("markerPos", evangeliarSeiten.value[bibelseite-1].markerPos)

                    evangeliarSeiten.value[bibelseite-1].markerText.push(props.zitate[z]["toolset-meta"].bibelzitate );
                    if (props.zitate[z]["toolset-meta"].bibelseite.wand != null) {
                         evangeliarSeiten.value[bibelseite-1].mapPos.push(parseInt(props.zitate[z]["toolset-meta"].bibelseite.wand.raw ));
                    }    
                    // console.log("mappos", evangeliarSeiten.value[bibelseite-1].mapPos)
                    // if (evangeliarSeiten.value[bibelseite-1].seitenText == {}) {
                    //      evangeliarSeiten.value[bibelseite-1].seitenText = props.zitate[z]["toolset-meta"].bibelseite ;
                    // }

                    if (evangeliarSeiten.value[bibelseite-1].seitenText.nr == null ){
                         evangeliarSeiten.value[bibelseite-1].seitenText = props.zitate[z]["toolset-meta"].bibelseite ;
                    }
                    // console.log(props.zitate[z]["toolset-meta"].bibelseite)


                    // props.zitate[z]
                    // let o = { "nr": z}
                    // evangeliarSeiten.value.push(o)
               }

               // console.log("evangeliarSeiten Setup", evangeliarSeiten.value);
               
               // jetzt auffüllen mit
               // "markerText":  []
               // "markerPos":     [ [  [84,615,399,32] ],
               //                  [ [410,615,600,32], [84,649,600,32] ],
               //                  [ [80,715,600,32], [80,750,600,32] ]
               //                  ],
               // console.log(evangeliarSeiten.value)

               selectedContent.value = evangeliarSeiten.value.filter(seite => seite.seitenText != []);  // props.content.evangeliarSeiten.filter(seite => seite.nr != null);
               // console.log("selectedContent", selectedContent.value)
               maxBibel.value = selectedContent.value.length;
               // console.log(maxBibel.value);

               if(store.state.roomId == 0){
                    // store.state.setRoomId( parseInt(route.params.roomId));
                    store.dispatch("setRoomId", parseInt(route.params.roomId) );
                    store.state.showRundgang = true;
               }

               // console.log("roomPage in content", roomPage);
               // if (roomPage == undefined){
               //      roomPage = 1;
               // }
               // console.log("roomPage in content", roomPage);
               console.log("EXXXTERN MOUNTED PAGEID", route.params.pageId);

               if (route.params.pageId != null || route.params.pageId == ""){
                    store.state.roomPageId = parseInt(route.params.pageId)
               } else {
                    store.state.roomPageId = 1;
               }

               // console.log("EXXXTERN MOUNTED PAGEID AFTER", route.params.pageId);

               store.state.showRundgang = true
               if(store.state.roomId == 6){
                    checkBibelStellen();
               }
          })

          
          return {
               props,
               nextPage,
               prevPage,
               showMarker,
               bibelstellen,
               currBibel,
               maxBibel,
               dropDownSelection,
               onDropDownOptions,
               toggleSeiten,
               showAll,
               showToggleMarker,
               selectedContent,
               path,
               evangeliarSubMenuLong,
               externIndex,
               onSwiper,
               onSlideChange,
               onLeft,
               onRight,
               route,
               contentRoom,
               modules: [Pagination],
               nextRoom,
               prevRoom,
               filterActive,
               showPageButton,
               showRoomButton,
               checkForRaumSeiten,
               kSBackground,
               kSKachel,
               kSKachelActive,
               kSKachelFont,
               kSKachelFontActive,
               markerActive,
               kSDekoline,
               kSHr, 
               prevRoomPage,
               nextRoomPage,
               filterBR,
               // getBreakPoints,
               refzitataudio,
               zitatAudio,
               getScanName,
               getMapPos,
               mapPoints,
               auswahlWand,
               currPathNumber,
               wand,
               store,
          }
     },

     data() {
          return {
               tiltRadius: 100,
               tiltY: 100,
               n: 30,
               cscale: chroma.scale(['#dd3e1b', '#0b509c']),
               renderer: "",
               camera: "",
               fov: 50,
               planeAspectRatio: 1 / 1,
               options: {     
                    // controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
                    controls: ['play-large', 'play', 'progress', 'current-time','mute', 'volume', 'captions','settings', 'airplay', 'fullscreen'],
                    quality: { default: '1080p' },
                    settings: [], 
                    fullscreen: { enabled: false, fallback: true, iosNative: false, container: null },
                    hideControls: false,
                    captions: { active: true, language: 'en', update: false },
                    // caption: {
                    //      active: true,
                    //      update: true,// THAT line solved my problem
                    // }
               },
               // showAll: true,
               optionsAudio: {     
                    // controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
                    // controls: ['play-large', 'play', 'progress', 'current-time'],
                    hideControls: false,
                    settings: [], 
                    captions: { active: true, language: 'en', update: true }
                    // caption: {
                    //      active: true,
                    //      update: true,// THAT line solved my problem
                    // }
               },
               // selectedContent: [],
               mode: 'dark',
               // pagination: {
               //      clickable: true,
               //      renderBullet: function (index, className) {
               //           console.log(index, className);
               //           return '<span class="' + className + '">' + (index + 1) + "</span>";
               //      },
               // },
          };
     },
     methods: {
          
          onReady(model){  // 
               // console.log("hallo", model)
               model.scene.castShadow = true;
               model.scene.children[0].castShadow = true;
          },
          
          toggleMarker(){
               if (this.showMarker == "visible"){
                    this.showMarker = "hidden"
               } else {
                    this.showMarker = "visible"
               }
          },

          vPlayCheck(type){
               console.log(type);
               // console.log(this.$refs);

               // console.log(this.$refs.vplyraudio1);
               if (this.$refs.vplyraudio1){
                    // console.log(this.$refs.vplyraudio1);
                    // this.$refs.vplyraudio1.player.stop()
               }

               if (this.$refs.vplyraudio2){
                    // console.log(this.$refs.vplyraudio2);
                    // this.$refs.vplyraudio2.player.stop()
               }

               // console.log(this.$refs.vplyrvideo);
               if (this.$refs.vplyrvideo){
                    // console.log(this.$refs.vplyrvideo);
                    // this.$refs.vplyrvideo.player.stop()
               }

               // if (type == "audio1"){
               //      // console.log(this.$refs.vplyrvideo);
               //      this.$refs.vplyraudio1.player.stop()
               //      this.$refs.vplyrvideo.player.stop()
               // } else if  (type == "audio2"){
               //      this.$refs.vplyraudio2.player.stop()
               //      this.$refs.vplyrvideo.player.stop()
               // } else if  (type == "video"){
               //      // console.log(this.$refs.vplyraudio);
               //      this.$refs.vplyraudio1.player.stop()
               //      this.$refs.vplyraudio2.player.stop()
               // }
          },
          
          tabClicked (selectedTab) {
               console.log('Current tab re-clicked:' + selectedTab.tab.name)
          },

          tabChanged (selectedTab) {
               console.log('Tab changed to:' + selectedTab.tab.name)
          },

          resize (){	
          
          },

       
          color(i) {
               return this.cscale(i / this.n).css();
          },
     },

     mounted() {
          
     },
     
}
</script>



<style lang="scss" scoped>

.headline-container {
     display: flex;
     // background-color: orange;

}

:deep(.swiper-pagination .swiper-pagination-bullet ) {
     // bottom: 0px;
     opacity: 1;
     border: white solid 1px;
     background-color: transparent;
}

:deep(.swiper-pagination .swiper-pagination-bullet-active) {
     background-color: rgb(255, 255, 255);
}



@media ( min-width: 480px ) and ( max-width: 576px ) {
   
     :deep(.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal) {
     bottom: 0px;
     left: 0;
     width: 100%;;
     }
}

button {
  background-color: #e4e4e4; 
  border-radius: 8px;
  border: none;
  color: rgb(0, 0, 0);
  margin: 0.4rem;
  padding: 0.8rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.0rem;
}

// .content-container {
//      padding-top: 1.8rem;
//      padding-bottom: 1.8rem;
// }
.content-block{
     padding-top: 0.3rem;
     // display:block;
     // position: absolute;

}
#container-3d {
     margin: 0 auto;
     // width: 400px;
     // height: 400px;
     width: 70%;
     // height: 80%;
     // background-color: red;
}
.plyr {
     --plyr-color-main: #464646;
}


svg {  // xxxx hier könnte die maximale breite angegeben werden
    max-width: 844px;
    max-height: 100%;
}

.bibel-container {
     // display: grid;
     // grid-template-columns: 33% 67%;
     // grid-template-rows: auto;
     // grid-template-areas: "bt bi" ;
     // padding-top: 20px;

     // grid-auto-flow: reverse;
     display: grid;
     grid-template-columns: 100%;
     grid-template-areas: "bi"
                          "bt" ;
     padding-top: 20px;

}

.bibel-text {
     grid-area: bt;
     align-items: center;
   
}

.bibel-image {
     display: flex;
     justify-content:center;
     grid-area: bi;
     // background-color: green;
     // max-width: 100%;
}

.map-container {
     display: flex;
     justify-content:center;
     // grid-area: bi;
     // background-color: green;
     // max-width: 100%;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.button-pfeil-links {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     transition: all 0.5s;
     // background-color: #f80000;
     width:20%;
     // height: 2rem; 
}

.button-pfeil-rechts {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     transition: all 0.5s;
     // background-color: #f80000;
     width:20%;
     // height: 2rem; 
}

.button-pfeil-rechts p {
   margin-bottom: 0px;
   margin-top: 0px;
   margin-right: 10px;
}

.button-pfeil-links p {
   margin-bottom: 0px;
   margin-top: 0px;
   margin-left: 10px;
}


.headline-container hr {
    opacity: 0.0;
}


.page-button-links {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     // background-color: #f80000;
     width:10%;
     height: 2rem;
     transition: all 0.5s;
}

.page-button-rechts {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     // background-color: #f80000;
     width:10%;
     height: 2rem;
     transition: all 0.5s;
}


// .page-button-links {
//      display: flex;
//      align-items: center;
//      justify-content: flex-start;
//      // background-color: #f80000;
//      width:10%;
//      height: 2rem;
// }

// .page-button-rechts {
//      display: flex;
//      align-items: center;
//      justify-content: flex-end;
//      // align-content: right;
//      // vertical-align: middle;
//      // background-color: #f80000;
//      width:10%;
//      height: 2rem;
// }
// .page-pagination {
//      vertical-align: middle;
//      margin-bottom: 0.5rem;
//      margin-top: 0.5rem;
// }


.button-base {
     display: flex;
     //  min-width: 75px;
     height: 24px;
     width: auto;
     margin: 0px 12px 12px 0px;
     background-color: #f80000;
     border-radius: 0px;
     align-content: center;
     transition: all 0.3s;
}

@media (max-width: 480px) {
     .button-base {
          margin: 0px 0px 12px 0px;
     }
}
.button-base p {
     font-family: FontSerifItalic;
     color: white;
     font-size: 16px;
     line-height: 0px;
     text-align: center;
     padding: 0px;
     margin: 0px;
}
.flex-row-left {
     display: flex;
     flex-direction: row;
     // justify-content: left;
     padding-bottom: 20px;
     align-items: left;
     // justify-content:space-between; //  space-around;

}

@media (max-width: 576px) {
     .flex-row-left {
     
          flex-direction: column;
          // justify-content: left;
          padding-bottom: 20px;
          align-items: left;
          justify-content:space-between; //  space-around;
     }
}


// .flex-row-end {
//      display: flex;
//      justify-content:space-between; //  space-around;
//      align-items: center;
//      // background-color: #2c5777;
// }

.wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #f80000;
    margin: 0;
    padding: 20px;
  }

.comment {
font-style: italic;
color: lightgrey;
}

// @media (max-width: 60rem) {
@media (max-width: 768px) {
     .bibel-container{
          grid-auto-flow: reverse;
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: auto ;
          grid-template-areas: "bi"
                               "bt" ;
     }

     svg {
          max-width: 705px;
          max-height: 100%;
     }

}

.swiper-container {
     // position: relative;
     // left: 0px;
     // top: 0px;
     width: 100%;
     height: 150px;
     z-index: 0;
     // width: 100%;
     // display: flex;
     background-color: #000000;
}

@media (max-width: 576px) {

     .swiper-container {
     // position: relative;
     // left: 0px;
     // top: 0px;
     width: 100%;
     height: 120px;
     z-index: 0;
     // width: 100%;
     // display: flex;
     // background-color: #04ff04;
     }

}

@media (max-width: 480px) {

     .swiper-container {
     // position: relative;
     // left: 0px;
     // top: 0px;
     width: 100%;
     height: 120px;
     z-index: 0;
     // width: 100%;
     // display: flex;
     // background-color: #ff04c9;
     }


}


.testslide{
     width: 156px;
     height: 116px;
     background-color: red;
}

.swiper-pagination{
     bottom: 0px;
     width: 100%;
     height: 30px;
     // background-color: orange;
}

// .red {
//    background-color: red;
//    width: 400px;
//    height: 200px;
// }
// .blue {
//    background-color: blue;
//    width: 400px;
//    height: 200px;
// }
// .green {
//    background-color: green;
//    width: 400px;
//    height: 200px;
// }
// .yellow {
//    background-color: yellow;
//    width: 400px;
//    height: 200px;
// }


.bgbibel {
    background: radial-gradient(#fff 20%, #b9b9b9 100%);
}


</style>


<style lang="scss" scoped>
@import "../css/variables.scss";
     .tabs-component {
         margin: 4em 0;
     }
     
     .tabs-component-tabs {
         border: solid 1px #ddd;
         border-radius: 6px;
         margin-bottom: 5px;
     }
     
     @media (min-width: 700px) {
         .tabs-component-tabs {
             border: 0;
             align-items: stretch;
             display: flex;
             justify-content: flex-start;
             margin-bottom: -1px;
         }
     }
     
     .tabs-component-tab {
         color: #999;
         font-size: 14px;
         font-weight: 600;
         margin-right: 0;
         list-style: none;
     }
     
     .tabs-component-tab:not(:last-child) {
         border-bottom: dotted 1px #ddd;
     }
     
     .tabs-component-tab:hover {
         color: #666;
     }
     
     .tabs-component-tab.is-active {
         color: #000;
     }
     
     .tabs-component-tab.is-disabled * {
         color: #cdcdcd;
         cursor: not-allowed !important;
     }
     
     @media (min-width: 700px) {
         .tabs-component-tab {
             background-color: #fff;
             border: solid 1px #ddd;
             border-radius: 3px 3px 0 0;
             margin-right: .5em;
             transform: translateY(2px);
             transition: transform .3s ease;
         }
     
         .tabs-component-tab.is-active {
             border-bottom: solid 1px #fff;
             z-index: 2;
             transform: translateY(0);
         }
     }
     
     .tabs-component-tab-a {
         align-items: center;
         color: inherit;
         display: flex;
         padding: .75em 1em;
         text-decoration: none;
     }
     
     .tabs-component-panels {
         padding: 4em 0;
     }
     
     @media (min-width: 700px) {
         .tabs-component-panels {
             background-color: #fff;
             border: solid 1px #ddd;
             border-radius: 0 6px 6px 6px;
             box-shadow: 0 0 10px rgba(0, 0, 0, .05);
             padding: 4em 2em;
         }
     }
     
     .tabs-component-btn {
       cursor: pointer;
       background: #e1ecf4;
       border-radius: 3px;
       border: 1px solid #7aa7c7;
       padding: 4px 8px;
       color: #39739d;
     }
     
     .tabs-component-btn:hover {
       background-color: #b3d3ea;
       color: #2c5777;
     }
     
     .tabs-component-btn:active {
       background-color: #a0c7e4;
       box-shadow: none;
       color: #2c5777;
     }


     .content-footer {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 5rem;
          justify-content: space-between;
          align-items: center;
          background-color: $dekocolor15proz;
          margin-top: 2.5rem;
     }

     .content-footer > div {
          width: 200px;
          height: 70px;
          // flex-wrap: wrap;
          
          // background-color: red;
          
     }

     .content-footer > div > p {
          font-family: FontSerifItalic;
          color: white;
          font-size: 16px;
     }


     .content-footer > div:nth-child(1) {
          text-align: left;
          margin-left: 36px;
     }

     .content-footer > div:nth-child(3) {
          text-align: right;
          margin-right: 36px;
     }

     .content-footer > .footer-line {
          width: 2px;
          height: 36px;
          background-color: white;
          opacity: 0.20;
          vertical-align: center;
     }

     .swiper-slide {
          // width: 100% !important;
          // width: 172px !important;
          // justify-content: space-between;
     }
//     
//      // display: flex;
//      // height: 300px;
//      // justify-content: center;
//      // align-items: center;
//     
//      // margin: 0 30%;
//      // font-size: 24px;
//      // font-weight: 700;
// } 


/* .swiper-slide:nth-child(1n) {
  background-color: palevioletred;
}
.swiper-slide:nth-child(2n) {
  background-color: skyblue;
}
.swiper-slide:nth-child(3n) {
  background-color: peru;
}
.swiper-slide:nth-child(4n) {
  background-color: cadetblue;
}
.swiper-slide:nth-child(5n) {
  background-color: plum;
}
.swiper-slide:nth-child(6n) {
  background-color: goldenrod;
}
.swiper-slide:nth-child(7n) {
  background-color: palegreen;
} */



// .pagination-slider .swiper-pagination-bullet {
//      text-align: center;
//      line-height: 40px;
//      font-size: 24px;
//      color: rgb(243, 7, 7);
//      opacity: 1;
//      background: rgba(131, 130, 130, 0.2);
//      width: 40px !important;
//      height: 40px !important;
//      }
//      .pagination-slider .swiper-pagination-bullet-active {
//      color: #fff;
//      background: #ffffff;
//      }
//      .pagination-slider .swiper-pagination {
//      position: unset;
//      margin-bottom: 3%;
//      }



</style>