<template>
    <header class="main-header" id="main-header">
        
        <div>
            <a @click="goHome()" class="main-header__brand">
                <img :src="kSImage()" alt="logo">
            </a>
        </div>

        <div class="line" :style="kSDekoline()"></div>
        <!-- <p> xxx {{ content.menu }}</p> -->
        <nav class="main-nav">
            <ul class="main-nav__items">

                <li class="main-nav__item deselect" :style="isActive(index)" v-for="item, index in contentui.menu[language]" :key="index" >
                    <a class="deselect" :class="store.state.typoSwitch ? 'larger':''" @click="openNavigation(index)"> {{ item }} </a>   
                </li>

            </ul>
        </nav>
    </header>
    
</template>



<script>
// import {toRefs} from 'vue';
// import { onMounted } from 'vue';
import { onMounted } from 'vue';
import {useStore} from 'vuex'
import useEmitter from '@/composables/useEmitter'
import { useRouter } from 'vue-router'  // , useRoute


export default {
  
  props: {
    contentui: Object,
    language: String
  },

  setup(props) {
    // console.log("header menu", props.content.menu)
    // console.log("header language", props.language)
 
    // const { content } = toRefs(props)  //, language
    const router = useRouter();
    // const route = useRoute();

    const store = useStore();
    const emitter = useEmitter();
    
    onMounted(() => {
      // console.log("header UI content", props.contentui)
    })

    const kSImage = () => {
      if (store.state.kontrastSwitch== true) {
        // return "@/assets/svgs/liesborn_black-oh.svg" 
        return "/liesborn-black-oh.svg" 
      } else {
        return "/liesborn-green-oh.svg" 

      }
    }    

const openMenu = () => {
      store.state.menuModal = true;
      console.log("openMenu")
    }

    const goHome = () => {
      emitter.emit("GO_HOME", "message from header" );
      console.log("goHome")
    }

    const isActive = (index)=> {

      if (index==0 && store.state.showRundgang == true) {
          if (store.state.kontrastSwitch == false ) {
            return {
              borderBottom: "2px solid #94A78F",
            }
          } else {
            return {
              borderBottom: "2px solid #000000",
            }
          }
      } else if (index==0 && store.state.showRundgang == false) {
          return {
            borderBottom: "2px solid #ff000000",
          }

      } else if (index==1 && store.state.showSettings == true) {
          if (store.state.kontrastSwitch == false ) {
            return {
              borderBottom: "2px solid #94A78F",
            }
          } else {
            return {
              borderBottom: "2px solid #000000",
            }
          }
      } else if (index==1 && store.state.showSettings == false) {
          return {
            borderBottom: "2px solid #ff000000",
          }

      } else if (index==2 && store.state.showLanguage == true) {
          if (store.state.kontrastSwitch == false ) {
            return {
              borderBottom: "2px solid #94A78F",
            }
          } else {
            return {
              borderBottom: "2px solid #000000",
            }
          }
      } else if (index==2 && store.state.showLanguage == false) {
          return {
            borderBottom: "2px solid #ff000000",
          }

      } else {
          return {}
      }
    }

    const openNavigation = (index) => {
      if (index == 0 ){
        window.scrollTo(0, 0) ;
        store.state.showLanguage = false
        store.state.showSettings = false
        openRouterSite(index)
      } else if (index == 1)  {
        window.scrollTo(0, 0) ;
        store.state.showLanguage = false
        store.state.showSettings = true
      } else if (index == 2)  {
        window.scrollTo(0, 0) ;
        store.state.showLanguage = true
        store.state.showSettings = false
      }
    }

    const openRouterSite = (index) => {
      console.log("props.contentui", props.contentui)
      const baseroute = props.contentui.menuroutes[index]
      console.log("openRouterSite", index, baseroute)

      if (baseroute == "rundgangpage"){
          store.state.showRundgang = true;
          store.state.roomPageId = 1;
          store.state.roomId = 0;
          store.state.roomPageId = 0;
          router.push({ name: baseroute, params: { roomId: 0, pageId:0}, props: true})
          // router.push(baseroute).catch(()=>{});
      } else {
          store.state.showRundgang = false
          router.push({ name: baseroute, props: true})  // params: { roomId: 0}
          // router.push(baseroute).catch(()=>{});
      }
      // if (router.currentRoute.value.fullPath !== "home") 
      // console.log(router.currentRoute.value.fullPath);
    }

    const kSDekoline = () => {
        // console.log("home ksdekoline")
        if (store.state.kontrastSwitch == false) {
            return {
                  backgroundColor: '#94A78Fed',
            }
        } else {
            return {
                  backgroundColor: '#000000',
            }
        } 
      }

    return {
      openMenu,
      goHome,
      // openRouterSite,
      openNavigation,
      isActive,
      kSImage,
      kSDekoline,
      store,
      // props, 
      // content,
      // language
    }
  },
}
</script>



<style lang="scss" scoped>
.main-header {
  width: 100%;
  max-width: 1180px;
  position: fixed;
  top: 0;
  // left: 0;
  // background-color: #ffff00;
  background: #ffffff;
  padding: 0.5rem 2.0rem 0.5rem 2.05rem;
  height: 7.0rem;
  z-index: 50;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
  justify-content: space-between;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
}

@media (min-width: 640px) {
  .main-header {
    flex-direction: row;
    align-items: center;
  }

}

.toggle-button {
  width: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__bar {
  width: 100%;
  height: 0.2rem;
  background: black;
  display: block;
  margin: 0.5rem 0;
}




.main-header__brand {
  color: #0e4f1f;
  text-decoration: none;
  font-weight: bold;
  /* width: 20px; */
}

.main-header__brand img {

  height: 2.5rem;
  vertical-align: top;
  /* width: 100%; */
}

@media (min-width: 640px) {
  .main-header__brand {
    color: #0e4f1f;
    text-decoration: none;
    font-weight: bold;
    /* width: 20px; */
  }

  .main-header__brand img {

    height: 3.85rem;
    vertical-align: middle;
    /* width: 100%; */
  }
}

.line {
  display:visible;
  background-color: #eaeee9;
  border-radius: 2px;
  height: 2px;
  width: 100%;
}

@media (min-width: 640px) {
  .line {
    display:none;
  
  }

}


.main-nav {
  // background-color: #ff1b68;
  // display: none;
  width: 100%
}

@media (min-width: 640px) {
  .main-nav {
    width: auto;
  }
}
.main-nav__items {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  // flex-flow: row wrap;
  // justify-content: flex-start;
}

.main-nav__item {
  // background-color: green;
  margin: 0;
}

@media (min-width: 640px) {
  .main-nav__item {
    // background-color: green;
    margin: 0 0.2rem 0 2rem;
  }

}

.main-nav__item a,
.mobile-nav__item a {
  font-family: FontMedium;
  font-size: 0.8rem;
  text-decoration: none;
  color: #000000;
  text-transform: uppercase;
  // font-weight: bold;
  padding: 0.2rem 0;
}

.main-nav__item a.larger,
.mobile-nav__item a.larger {
  // font-family: FontMedium;
  font-size: 0.9rem;
  // text-decoration: none;
  // color: #000000;
  // text-transform: uppercase;
  // font-weight: bold;
  padding: 0.22rem 0;
}


 @media (min-width: 724px) {
  .main-nav__item a,
  .mobile-nav__item a {
    font-family: FontMedium;
    font-size: 1.0rem;
    text-decoration: none;
    color: #000000;
    text-transform: uppercase;
    // font-weight: bold;
    padding: 0.2rem 0;
  }

    .main-nav__item a.larger,
    .mobile-nav__item a.larger {
      // font-family: FontMedium;
      font-size: 0.9rem;
      // text-decoration: none;
      // color: #000000;
      // text-transform: uppercase;
      // font-weight: bold;
      padding: 0.22rem 0;
    }
 }



// @media (max-width: 400px) {
//   .mobile-nav__item a {
//     font-family: FontMedium;
//     font-size: 0.8rem;
//     text-decoration: none;
//     color: #000000;
//     text-transform: uppercase;
//     // font-weight: bold;
//     padding: 0.2rem 0;
//   }

//   .main-nav__item a.larger,
//   .mobile-nav__item a.larger {
//     // font-family: FontMedium;
//     font-size: 0.85rem;
//     // text-decoration: none;
//     // color: #000000;
//     // text-transform: uppercase;
//     // font-weight: bold;
//     padding: 0.22rem 0;
//   }
// }



.main-nav__item a:hover,
.main-nav__item a:active {
  // color: rgb(0, 0, 0);
  // border-bottom: 2px solid #94A78F;
}

.main-nav__item--cta a,
.mobile-nav__item--cta a {
  color: white;
  background: #ff1b68;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.main-nav__item--cta a:hover,
.main-nav__item--cta a:active,
.mobile-nav__item--cta a:hover,
.mobile-nav__item--cta a:active {
  color: #ff1b68;
  background: white;
  border: none;
}


@media (min-width: 40rem) {
  .toggle-button {
    display: none;
  }

  .main-nav {
    display: flex;
  }
}

</style>