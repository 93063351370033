<template>
     
     <div class="basefloater-container" :style="kSBackground()">
          <div class="base-c1" ref="target">
               <div class="settings-topline"><h4 class="deselect" :class="store.state.typoSwitch ? 'larger':'leer'">{{contentui.einstellungen[language]}}</h4><img src="../assets/svgs/close.svg" alt="" @click="closeSettings()"></div>

               <div class="settings-container">
                    
                    <div class="settings-group-container">
                         <h5 :class="[store.state.kontrastSwitch==false ? '' : 'text-white', store.state.typoSwitch ? 'larger':'leer'] ">{{contentui.groesseeinstellungen[language]}}</h5>
                         <div class="deko-settings__line"></div>
                         <div class="setting-container">
                              <div class="setting-base center" :class="store.state.typoSwitch==false ? 'setting-active' : ''" @click="store.state.typoSwitch=false, saveCookies(), typoSwitch('t1')" >
                                   <svg id="svg-t1" xmlns="http://www.w3.org/2000/svg" width="59.5" height="59.5" viewBox="0 0 59.5 59.5">
                                        <g id="Gruppe_63"  transform="translate(-419 -129.5)">
                                        <g id="Ellipse_15"  transform="translate(419 139)" fill="none" stroke="#fff" stroke-width="1">
                                             <circle cx="25" cy="25" r="25" stroke="none"/>
                                             <circle cx="25" cy="25" r="24.5" fill="none"/>
                                        </g>
                                        <path id="Pfad_113"  d="M47.12,17l-1.376-3.536H41.216L39.856,17H38.4L42.864,5.528h1.3L48.608,17Zm-1.808-4.816-1.28-3.456q-.048-.128-.16-.464t-.216-.7q-.1-.36-.168-.552-.128.5-.264.968t-.232.744l-1.3,3.456Zm7.92-3.9a3.35,3.35,0,0,1,2.32.688A2.84,2.84,0,0,1,56.3,11.16V17H55.28l-.272-1.216h-.064a3.921,3.921,0,0,1-1.184,1.04,3.6,3.6,0,0,1-1.7.336,3,3,0,0,1-1.936-.616,2.324,2.324,0,0,1-.768-1.928,2.26,2.26,0,0,1,1.008-1.976,5.847,5.847,0,0,1,3.1-.76l1.456-.048V11.32a1.94,1.94,0,0,0-.464-1.488,1.9,1.9,0,0,0-1.312-.416,4.07,4.07,0,0,0-1.28.2,9.168,9.168,0,0,0-1.136.456L50.3,9.016a6.445,6.445,0,0,1,1.328-.52A5.882,5.882,0,0,1,53.232,8.28Zm1.68,4.528-1.264.048a4.262,4.262,0,0,0-2.216.512,1.472,1.472,0,0,0-.616,1.264,1.244,1.244,0,0,0,.44,1.056,1.806,1.806,0,0,0,1.128.336,2.736,2.736,0,0,0,1.8-.6,2.257,2.257,0,0,0,.728-1.848Z" transform="translate(396 152.472)" fill="#fff"/>
                                        <g id="haken" name="haken" class="svg-visible"   transform="translate(355.363 -118.637)">
                                             <circle id="Ellipse_17" cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                             <path id="Pfad_115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </g>
                                        </g>
                                   </svg>
                                   <p>{{contentui.ui.klein[language]}}</p>     
                              </div>
                              <div class="setting-base center" :class="store.state.typoSwitch==true ? 'setting-active' : ''" @click="store.state.typoSwitch=true,saveCookies(), typoSwitch('t2')" >
                                   <svg id="svg-t2" xmlns="http://www.w3.org/2000/svg" width="61.5" height="58.5" viewBox="0 0 61.5 58.5">
                                        <g id="Gruppe_62" transform="translate(-539 -130.5)">
                                        <g id="Ellipse_16"  transform="translate(539 139)" fill="none" stroke="#fff" stroke-width="1">
                                             <circle cx="25" cy="25" r="25" stroke="none"/>
                                             <circle cx="25" cy="25" r="24.5" fill="none"/>
                                        </g>
                                        <path id="Pfad_114"  d="M46.68,26l-2.064-5.3H37.824L35.784,26H33.6L40.3,8.792H42.24L48.912,26Zm-2.712-7.224-1.92-5.184q-.072-.192-.24-.7t-.324-1.044q-.156-.54-.252-.828-.192.744-.4,1.452t-.348,1.116l-1.944,5.184Zm11.88-5.856a5.025,5.025,0,0,1,3.48,1.032,4.26,4.26,0,0,1,1.128,3.288V26H58.92l-.408-1.824h-.1a5.882,5.882,0,0,1-1.776,1.56,5.4,5.4,0,0,1-2.544.5,4.494,4.494,0,0,1-2.9-.924,3.486,3.486,0,0,1-1.152-2.892,3.39,3.39,0,0,1,1.512-2.964,8.771,8.771,0,0,1,4.656-1.14l2.184-.072V17.48a2.91,2.91,0,0,0-.7-2.232,2.848,2.848,0,0,0-1.968-.624,6.1,6.1,0,0,0-1.92.3,13.752,13.752,0,0,0-1.7.684l-.648-1.584a9.667,9.667,0,0,1,1.992-.78A8.822,8.822,0,0,1,55.848,12.92Zm2.52,6.792-1.9.072a6.394,6.394,0,0,0-3.324.768,2.207,2.207,0,0,0-.924,1.9,1.866,1.866,0,0,0,.66,1.584,2.709,2.709,0,0,0,1.692.5,4.1,4.1,0,0,0,2.7-.9,3.385,3.385,0,0,0,1.092-2.772Z" transform="translate(516 146.208)" fill="#fff"/>
                                        <g id="haken" name="haken" class="svg-invisible"   transform="translate(477.363 -117.637)">
                                             <circle id="Ellipse_17" cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                             <path id="Pfad_115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </g>
                                        </g>
                                        </svg>
                                   <p>{{contentui.ui.gross[language]}}</p>     
                              </div>
                         </div>
                    </div>
               
                    <div class="settings-group-container">
                         <h5 :class="[store.state.kontrastSwitch==false ? '' : 'text-white', store.state.typoSwitch ? 'larger':'leer'] ">{{contentui.kontrasteinstellungen[language]}}</h5>
                         <div class="deko-settings__line"></div>
                         <div class="setting-container">
                              <div class="setting-base center" :class="store.state.kontrastSwitch==false ? 'setting-active' : ''" @click="store.state.kontrastSwitch=false, saveCookies(), kontrastSwitch('k1')" >
                                   <svg id="svg-k1" xmlns="http://www.w3.org/2000/svg" width="59.5" height="59.5" viewBox="0 0 59.5 59.5" style="background-color:#fff0">
                                        <g id="Gruppe_60"  transform="translate(-59 -129.5)">
                                             <g id="Ellipse_13"  transform="translate(59 139)" fill="none" stroke="#fff" stroke-width="1">
                                                  <circle cx="25" cy="25" r="25" stroke="none"/>
                                                  <circle cx="25" cy="25" r="24.5" fill="none"/>
                                             </g>
                                             <g id="haken" name="haken" class="svg-visible"  transform="translate(-4.637 -118.637)">
                                                  <circle id="Ellipse_17"  cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                  <path id="Pfad_115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                             </g>
                                        </g>
                                   </svg>
                                   <p>{{contentui.ui.standard[language]}}</p> 
                              </div>
                              <div class="setting-base center" :class="store.state.kontrastSwitch==true ? 'setting-active' : ''" @click="store.state.kontrastSwitch=true, saveCookies(), kontrastSwitch('k2')">
                                   <svg id="svg-k2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="61.5" height="58.5" viewBox="0 0 61.5 58.5" style="background-color:#fff0">
                                        
                                        <g id="Gruppe_61" transform="translate(-178 -129.5)">
                                             <g id="Gruppe_22" transform="translate(0.0 0.0) rotate(0)">  
                                                  <g id="Ellipse_14" transform="translate(179 139)" fill="#fff" stroke="#fff" stroke-width="1">
                                                  <circle cx="25" cy="25" r="25" stroke="none"/>
                                                  <circle cx="25" cy="25" r="24.5" fill="none"/>
                                                  </g>
                                                  <g id="Gruppe_maskieren_2"  transform="translate(-66)" clip-path="url(#clip-path)">
                                                  <g id="Ellipse_26" transform="translate(245 139)" stroke="#fff" stroke-width="1">
                                                       <circle cx="25" cy="25" r="25" stroke="none"/>
                                                       <circle cx="25" cy="25" r="24.5" fill="none"/>
                                                  </g>
                                                  </g>
                                             </g>
                                             <g id="haken" name="haken" class="svg-invisible"  transform="translate(114 -118.637)">
                                                  <circle id="Ellipse_17" cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                  <path id="Pfad_115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                             </g>
                                        </g>
                                   </svg>

                                   <p>{{contentui.ui.sw[language]}}</p>     
                              </div>
                         </div>
                    </div>
               </div> 
              
          </div>
     </div>

</template>



<script>
import { onMounted  } from 'vue'; // ref, onMounted, computed, 
import {useStore} from 'vuex'
import { inject } from 'vue'
import { ref } from 'vue'; // ref, onMounted, computed
// import { vOnClickOutside } from '@vueuse/components'
import { onClickOutside } from '@vueuse/core'


export default {
    
     props: {
        contentui: Object,
        language: String
    },

    setup(props) {
          const store = useStore();
          const $cookies = inject('$cookies');
          const target = ref(null);

          // store.state.typoSwitch = $cookies.get("typoSwitch",); 
          // store.state.kontrastSwitch = $cookies.get("kontrastSwitch",);
          onClickOutside(target, (event) =>  {
                    console.log("onClickOutside", event)
                    closeSettings();
               }
          )
   
          const closeSettings = () => {
               store.state.showSettings = false;
               store.state.showLanguage = false;
          }

          const kSBackground = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#4c5749',
                    }
               } else {
                    return {
                         backgroundColor: '#222222',
                    }
               } 
          }

          const kontrastSwitch = (value) => {
               const checkArray =["k1", "k2"]
               for (let i= 0; i < checkArray.length; i++) {
                    const butsvg = document.getElementById("svg-" + checkArray[i]);
                    const haken = butsvg.querySelector('#haken');
                    haken.classList =["svg-invisible"]
               }
         
               const butsvg = document.getElementById("svg-" + value);
               const haken = butsvg.querySelector('#haken');
               haken.classList.toggle("svg-visible")
          }

          const typoSwitch = (value) => {
               const checkArray =["t1", "t2"]
               for (let i= 0; i < checkArray.length; i++) {
                    const butsvg = document.getElementById("svg-" + checkArray[i]);
                    const haken = butsvg.querySelector('#haken');
                    haken.classList =["svg-invisible"]
               }
         
               const butsvg = document.getElementById("svg-" + value);
               const haken = butsvg.querySelector('#haken');
               haken.classList.toggle("svg-visible")
          }


          const saveCookies = () => {
               // store.state.kontrastSwitch
               // console.log("saveCookies", store.state.kontrastSwitch, store.state.typoSwitch)
               $cookies.set("typoSwitch",store.state.typoSwitch); 
               $cookies.set("kontrastSwitch",store.state.kontrastSwitch); 
          }

          onMounted(() => {
               console.log("onMounted", props)
               let tempArray = ["t1", "t2"]
               
               for (let i= 0; i < tempArray.length; i++) {
                    const butsvg = document.getElementById("svg-" + tempArray[i]);
                    const haken = butsvg.querySelector('#haken');
                    if (store.state.typoSwitch == true && tempArray[i] == "t1"){
                         haken.classList = ["svg-invisible"]
                        //  haken.classList.toggle("svg-visible")
                    // }else {
                    //      haken.classList =["svg-invisible"]
                    } else if (store.state.typoSwitch == true  && tempArray[i] == "t2"){
                         console.log("t2 visible", store.state.typoSwitch)
                         haken.classList = ["svg-visible"]
                         // haken.classList.toggle("svg-visible")
                    } else {
                         // haken.classList =["svg-invisible"]
                    }
               }
               
               tempArray =["k1", "k2"]
               
               for (let i= 0; i < tempArray.length; i++) {
                    const butsvg = document.getElementById("svg-" + tempArray[i]);
                    const haken = butsvg.querySelector('#haken');
                    if (store.state.kontrastSwitch == true  && tempArray[i] == "k1"){
                         haken.classList = ["svg-invisible"]
                         // haken.classList.toggle("svg-visible")
                    // }else {
                    //      haken.classList =["svg-invisible"]
                    } else if (store.state.kontrastSwitch == true  && tempArray[i] == "k2"){
                         console.log("k2 visible", store.state.kontrastSwitch)
                         haken.classList = ["svg-visible"]
                         // haken.classList.toggle("svg-visible")
                    } else {
                         // haken.classList =["svg-invisible"]
                    }
               }

        })
          

          return {
               saveCookies,
               kontrastSwitch,
               typoSwitch,
               closeSettings,
               kSBackground,
               target,
               store,
               props
          }
     }

}
</script>



<style lang="scss" scoped>

.settings-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row ;
  justify-content: space-between;
  width: 100%;
}

.settings-group-container {
     display: flex;
     flex-wrap: wrap;
     flex-direction: column;
     justify-content: space-around;
}
.setting-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  // justify-content: space-between;
  // align-content: space-around;
  align-content: space-between;
  width: 15rem;
  margin-top: 1rem;
}


.settings-topline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between
  
}

.center {
     text-align: center;
}
// .mobile-nav {
// //   display: none;
//   position: fixed;
//   z-index: 101;
//   top: 0;
//   left: 0;
//   background: rgb(255, 255, 255);
//   width: 100%;
//   height: 100vh;
// }

// .mobile-nav__items {
//   width: 90%;
// //   height: 100%;
//   list-style: none;
//   margin: 0 auto;
//   padding: 0;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
// }

// .mobile-nav__item {
//   margin: 1rem 0;
// }

// .mobile-nav__item a {
//   font-size: 1.5rem;
// }

button {
  background-color: #e4e4e4; 
  border-radius: 8px;
  border: none;
  color: rgb(0, 0, 0);
  margin: 0.4rem ;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
}

.setting-base {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    align-content: space-around;
}
</style>

