<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 784 603.5" style="enable-background:new 0 0 784 603.5;" xml:space="preserve">

    
    <g>
        <polyline class="st0" points="206.9,52.9 206.9,52.5 206.9,52.9 104.5,52.9 104.5,52.5 206.9,52.5"/>
        <polyline class="st0" points="206.9,52.9 104.5,52.9 104.5,52.5 206.9,52.5"/>
        <polyline class="st0" points="309.2,52.9 309.2,52.5 309.2,52.9"/>
        <polyline class="st0" points="206.9,52.5 206.9,52.9 206.9,52.5 309.2,52.5"/>
        <line class="st0" x1="309.2" y1="52.9" x2="206.9" y2="52.9"/>
        <line class="st0" x1="206.9" y1="52.5" x2="309.2" y2="52.5"/>
        <line class="st0" x1="309.2" y1="52.9" x2="206.9" y2="52.9"/>
        <polyline class="st0" points="104.9,52.9 104.5,52.9 104.9,52.9"/>
        <polyline class="st0" points="104.5,151.5 104.9,151.5 104.5,151.5"/>
        <line class="st0" x1="104.5" y1="52.9" x2="104.5" y2="151.5"/>
        <line class="st0" x1="104.9" y1="151.5" x2="104.9" y2="52.9"/>
        <line class="st0" x1="104.5" y1="52.9" x2="104.5" y2="151.5"/>
        <line class="st0" x1="104.9" y1="151.5" x2="104.9" y2="52.9"/>
        <polyline class="st0" points="104.9,151.5 104.5,151.5 104.9,151.5"/>
        <polyline class="st0" points="104.5,249.8 104.9,249.8 104.5,249.8"/>
        <line class="st0" x1="104.9" y1="151.5" x2="104.9" y2="249.8"/>
        <line class="st0" x1="104.5" y1="249.8" x2="104.5" y2="151.5"/>
        <line class="st0" x1="104.9" y1="151.5" x2="104.9" y2="249.8"/>
        <line class="st0" x1="104.5" y1="249.8" x2="104.5" y2="151.5"/>
        <polyline class="st0" points="104.5,249.8 104.9,249.8 104.5,249.8 "/>
        <polyline class="st0" points="104.5,348.1 104.9,348.1 104.5,348.1 "/>
        <line class="st0" x1="104.5" y1="249.8" x2="104.5" y2="348.1"/>
        <line class="st0" x1="104.9" y1="249.8" x2="104.9" y2="348.1"/>
        <line class="st0" x1="104.9" y1="249.8" x2="104.9" y2="348.1"/>
        <line class="st0" x1="104.5" y1="348.1" x2="104.5" y2="249.8"/>
        
        <polyline class="st0" points="104.9,348.1 104.5,348.1 104.9,348.1"/>
        <polyline class="st0" points="104.5,446.5 104.9,446.5 104.5,446.5"/>
        <line class="st0" x1="104.9" y1="348.1" x2="104.9" y2="446.5"/>
        <line class="st0" x1="104.5" y1="446.5" x2="104.5" y2="348.1"/>
        
        <polyline class="st0" points="206.9,545.5 206.9,545 206.9,545.5"/>
        <polyline class="st0" points="206.9,545 104.5,545 104.5,545.5 206.9,545.5"/>
        <polyline class="st0" points="206.9,545 104.5,545 104.5,545.5 206.9,545.5"/>
        <polyline class="st0" points="104.5,545 104.9,545 104.5,545"/>
        <polyline class="st0" points="104.9,446.5 104.5,446.5 104.9,446.5"/>
        <line class="st0" x1="104.5" y1="545" x2="104.5" y2="446.5"/>
        <line class="st0" x1="104.9" y1="446.5" x2="104.9" y2="545"/>
        
        <polyline class="st0" points="616.3,545 616.3,545.5 616.3,545 718.7,545 718.7,545.5 616.3,545.5"/>
        <polyline class="st0" points="616.3,545 718.7,545 718.7,545.5 616.3,545.5"/>
        <line class="st0" x1="718.7" y1="545" x2="718.7" y2="446.5"/>
        <line class="st0" x1="718.2" y1="446.5" x2="718.2" y2="545"/>
        
        <polyline class="st0" points="616.3,52.5 616.3,52.9 616.3,52.5"/>
        <polyline class="st0" points="616.3,52.9 718.7,52.9 718.7,52.5 616.3,52.5"/>
        <line class="st0" x1="718.7" y1="52.9" x2="718.7" y2="151.5"/>
        <line class="st0" x1="718.2" y1="151.5" x2="718.2" y2="52.9"/>
        
        <polyline class="st0" points="411.6,52.9 411.6,52.5 411.6,52.9"/>
        <polyline class="st0" points="309.2,52.5 309.2,52.9 309.2,52.5"/>
        <line class="st0" x1="411.6" y1="52.5" x2="309.2" y2="52.5"/>
        <line class="st0" x1="309.2" y1="52.9" x2="411.6" y2="52.9"/>
        
        <polyline class="st0" points="513.9,52.9 513.9,52.5 513.9,52.9"/>
        <polyline class="st0" points="411.6,52.5 411.6,52.9 411.6,52.5 513.9,52.5"/>
        <line class="st0" x1="513.9" y1="52.9" x2="411.6" y2="52.9"/>
        <line class="st0" x1="411.6" y1="52.5" x2="513.9" y2="52.5"/>
        <line class="st0" x1="513.9" y1="52.9" x2="411.6" y2="52.9"/>
        <polyline class="st0" points="513.9,52.5 513.9,52.9 513.9,52.5"/>
        <line class="st0" x1="616.3" y1="52.5" x2="513.9" y2="52.5"/>
        <line class="st0" x1="513.9" y1="52.9" x2="616.3" y2="52.9"/>
        
        <polyline class="st0" points="309.2,545.5 309.2,545 309.2,545.5"/>
        <polyline class="st0" points="206.9,545 206.9,545.5 206.9,545"/>
        <line class="st0" x1="206.9" y1="545.5" x2="309.2" y2="545.5"/>
        <line class="st0" x1="309.2" y1="545" x2="206.9" y2="545"/>
        <line class="st0" x1="206.9" y1="545.5" x2="309.2" y2="545.5"/>
        <line class="st0" x1="309.2" y1="545" x2="206.9" y2="545"/>
        <polyline class="st0" points="411.6,545.5 411.6,545 411.6,545.5"/>
        <polyline class="st0" points="309.2,545 309.2,545.5 309.2,545"/>
        <line class="st0" x1="411.6" y1="545.5" x2="309.2" y2="545.5"/>
        <line class="st0" x1="309.2" y1="545" x2="411.6" y2="545"/>
        <line class="st0" x1="411.6" y1="545.5" x2="309.2" y2="545.5"/>
        <line class="st0" x1="309.2" y1="545" x2="411.6" y2="545"/>
        <polyline class="st0" points="513.9,545.5 513.9,545 513.9,545.5"/>
        <polyline class="st0" points="411.6,545 411.6,545.5 411.6,545"/>
        <line class="st0" x1="411.6" y1="545.5" x2="513.9" y2="545.5"/>
        <line class="st0" x1="513.9" y1="545" x2="411.6" y2="545"/>
        
        <polyline class="st0" points="616.3,545.5 616.3,545 616.3,545.5"/>
        <polyline class="st0" points="513.9,545 513.9,545.5 513.9,545"/>
        <line class="st0" x1="616.3" y1="545.5" x2="513.9" y2="545.5"/>
        <line class="st0" x1="513.9" y1="545" x2="616.3" y2="545"/>
        <line class="st0" x1="513.9" y1="545" x2="616.3" y2="545"/>
        
        <line class="st0" x1="718.7" y1="348.1" x2="718.7" y2="446.5"/>
        <line class="st0" x1="718.2" y1="446.5" x2="718.2" y2="348.1"/>
        <line class="st0" x1="718.7" y1="249.8" x2="718.7" y2="348.1"/>
        <line class="st0" x1="718.2" y1="348.1" x2="718.2" y2="249.8"/>
        <line class="st0" x1="718.7" y1="249.8" x2="718.7" y2="348.1"/>
        <line class="st0" x1="718.2" y1="348.1" x2="718.2" y2="249.8"/>
        <line class="st0" x1="718.7" y1="151.5" x2="718.7" y2="249.8"/>
        <line class="st0" x1="718.2" y1="249.8" x2="718.2" y2="151.5"/>

        <line class="st0" x1="104.5" y1="350" x2="104.5" y2="350.1"/>
        <line class="st0" x1="104.5" y1="350.1" x2="104.5" y2="350"/>
        <line class="st0" x1="104.5" y1="349.7" x2="104.5" y2="349.5"/>
        <line class="st0" x1="104.5" y1="349.2" x2="104.5" y2="349"/>
        <line class="st0" x1="104.5" y1="348.8" x2="104.5" y2="348.7"/>
        <line class="st0" x1="104.5" y1="348.7" x2="104.5" y2="348.8"/>
        <line class="st0" x1="104.5" y1="349" x2="104.5" y2="349.2"/>
        <line class="st0" x1="104.5" y1="349.5" x2="104.5" y2="349.7"/>
        <line class="st0" x1="104.5" y1="248" x2="104.5" y2="248.2"/>
        <line class="st0" x1="104.5" y1="248.5" x2="104.5" y2="248.7"/>
        <line class="st0" x1="104.5" y1="248.8" x2="104.5" y2="249"/>
        <line class="st0" x1="104.5" y1="249.4" x2="104.5" y2="249.5"/>
        <line class="st0" x1="104.5" y1="249.5" x2="104.5" y2="249.4"/>
        <line class="st0" x1="104.5" y1="249" x2="104.5" y2="248.8"/>
        <line class="st0" x1="104.5" y1="248.7" x2="104.5" y2="248.5"/>
        <line class="st0" x1="104.5" y1="248.2" x2="104.5" y2="248"/>
        <line class="st0" x1="718.7" y1="346.6" x2="718.7" y2="349.7"/>
        <line class="st0" x1="718.7" y1="349.7" x2="718.7" y2="349.7"/>
        <polyline class="st0" points="722.1,346.6 722.1,349.7 719,349.7"/>
        <polyline class="st0" points="722.1,346.6 719,346.6 719,349.7"/>
        <line class="st0" x1="718.7" y1="251.4" x2="718.7" y2="251.4"/>
        <polyline class="st0" points="722.1,248.2 722.1,251.4 719,251.4"/>
        <polyline class="st0" points="722.1,248.2 719,248.2 719,251.4"/>
        <line class="st0" x1="718.7" y1="149.9" x2="718.7" y2="153.1"/>
        <line class="st0" x1="718.7" y1="153.1" x2="718.7" y2="153.1"/>
        <line class="st0" x1="718.7" y1="149.9" x2="718.7" y2="149.9"/>
        <polyline class="st0" points="722.1,149.9 722.1,153.1 719,153.1"/>
        <polyline class="st0" points="722.1,149.9 719,149.9 719,153.1"/>
        <line class="st0" x1="718.7" y1="444.9" x2="718.7" y2="448"/>
        <line class="st0" x1="718.7" y1="448" x2="718.7" y2="448"/>
        <line class="st0" x1="718.7" y1="444.9" x2="718.7" y2="444.9"/>
        <polyline class="st0" points="722.1,444.9 722.1,448 719,448"/>
        <polyline class="st0" points="722.1,444.9 719,444.9 719,448"/>
        <line class="st0" x1="617.9" y1="545.5" x2="614.7" y2="545.5"/>
        
        <polyline class="st0" points="515.5,548.9 512.4,548.9 512.4,545.8"/>
        <polyline class="st0" points="515.5,548.9 515.5,545.8 512.4,545.8"/>
        <polyline class="st0" points="413.2,548.9 410,548.9 410,545.8"/>
        <polyline class="st0" points="413.2,548.9 413.2,545.8 410,545.8"/>
        
        <polyline class="st0" points="311.1,548.9 311.1,545.8 307.9,545.8"/>
        <line class="st0" x1="208.4" y1="545.5" x2="205.3" y2="545.5"/>
        <polyline class="st0" points="208.4,548.9 205.3,548.9 205.3,545.8"/>
        <polyline class="st0" points="208.4,548.9 208.4,545.8 205.3,545.8"/>
        <line class="st0" x1="617.9" y1="52.5" x2="614.7" y2="52.5"/>
        <polyline class="st0" points="617.9,49.1 614.7,49.1 614.7,52.2"/>
        <polyline class="st0" points="617.8,49.3 617.8,52.3 614.8,52.3"/>
        <line class="st0" x1="515.5" y1="52.5" x2="512.4" y2="52.5"/>
        <polyline class="st0" points="515.5,49.1 512.4,49.1 512.4,52.2"/>
        <polyline class="st0" points="515.5,49.1 515.5,52.2 512.4,52.2"/>
        <line class="st0" x1="413.2" y1="52.5" x2="410" y2="52.5"/>
        <polyline class="st0" points="413.2,49.1 410,49.1 410,52.2"/>
        <polyline class="st0" points="413.2,49.1 413.2,52.2 410,52.2"/>
        <polyline class="st0" points="311.1,49.1 307.9,49.1 307.9,52.2"/>
        <polyline class="st0" points="311.1,49.1 311.1,52.2 307.9,52.2"/>
        <polyline class="st0" points="208.4,49.1 205.3,49.1 205.3,52.2"/>
        <polyline class="st0" points="208.4,49.1 208.4,52.2 205.3,52.2"/>
        <polyline class="st0" points="101.1,149.9 101.1,153.1 104.1,153.1"/>
        <polyline class="st0" points="101.1,149.9 104.1,149.9 104.1,153.1"/>
        <polyline class="st0" points="101.1,444.9 101.1,448 104.1,448"/>
        <polyline class="st0" points="101.1,444.9 104.1,444.9 104.1,448"/>
        <line class="st0" x1="104.5" y1="240.7" x2="104.5" y2="243.9"/>
        <polyline class="st0" points="101.1,240.7 101.1,243.9 104.1,243.9"/>
        <polyline class="st0" points="101.1,240.7 104.1,240.7 104.1,243.9"/>
        <polyline class="st0" points="101.1,357.3 101.1,354.1 104.1,354.1"/>
        <polyline class="st0" points="101.1,357.3 104.1,357.3 104.1,354.1"/>
        <polyline class="st0" points="101.1,62.6 101.1,59.4 104.1,59.4"/>
        <polyline class="st0" points="101.1,62.6 104.1,62.6 104.1,59.4"/>
        <polyline class="st0" points="113.9,49.1 110.7,49.1 110.7,52.2"/>
        <polyline class="st0" points="113.9,49.1 113.9,52.2 110.7,52.2"/>
        <polyline class="st0" points="101.1,535.4 101.1,538.6 104.1,538.6"/>
        <polyline class="st0" points="101.1,535.4 104.1,535.4 104.1,538.6"/>
        <line class="st0" x1="113.9" y1="545.5" x2="110.7" y2="545.5"/>
        <polyline class="st0" points="113.9,548.9 110.7,548.9 110.7,545.8"/>
        <polyline class="st0" points="113.9,548.9 113.9,545.8 110.7,545.8"/>
        <polyline class="st0" points="708.6,548.9 711.8,548.9 711.8,545.8"/>
        <polyline class="st0" points="708.6,548.9 708.6,545.8 711.8,545.8"/>
        <line class="st0" x1="718.7" y1="535.4" x2="718.7" y2="538.6"/>
        <line class="st0" x1="718.7" y1="538.6" x2="718.7" y2="538.6"/>
        <polyline class="st0" points="722.1,535.4 722.1,538.6 719,538.6"/>
        <polyline class="st0" points="722.1,535.4 719,535.4 719,538.6"/>
        <line class="st0" x1="718.7" y1="62.6" x2="718.7" y2="59.4"/>
        <line class="st0" x1="718.7" y1="59.4" x2="718.7" y2="59.4"/>
        <polyline class="st0" points="722.1,62.6 722.1,59.4 719,59.4"/>
        <polyline class="st0" points="722.1,62.6 719,62.6 719,59.4"/>
        <polyline class="st0" points="708.6,49.1 711.8,49.1 711.8,52.2"/>
        <polyline class="st0" points="708.6,49.1 708.6,52.2 711.8,52.2"/>
    </g>

    <g id="line-outlines">
        <line class="st1" x1="99.8" y1="48.8" x2="722.7" y2="48.8"/>
        <line class="st2" x1="100.6" y1="48.8" x2="722.4" y2="48.8"/>
        <line class="st3" x1="99.8" y1="49" x2="99.8" y2="49"/>
        <line class="st3" x1="99.8" y1="49.5" x2="99.8" y2="49.5"/>
        <line class="st3" x1="99.8" y1="49.5" x2="99.8" y2="49.5"/>
        <line class="st3" x1="99.8" y1="49" x2="99.8" y2="49"/>
        <line class="st2" x1="100.6" y1="49" x2="100.6" y2="248.8"/>
        <line class="st2" x1="722.6" y1="48.8" x2="722.6" y2="548.9"/>
        <line class="st1" x1="100.2" y1="548" x2="723.4" y2="548"/>
        <line class="st2" x1="101" y1="549" x2="722.6" y2="549"/>
        <line class="st1" x1="100.9" y1="548.6" x2="100.9" y2="348.4"/>
        <line class="st2" x1="100.9" y1="548.6" x2="100.9" y2="348.3"/>
    </g>
    <g id="bibelmöbel">
        <polyline class="st0" points="649.8,449.8 633.9,449.8 633.9,376.4 649.8,376.4 649.8,449.8 	"/>
        <polyline class="st0" points="633.9,376.4 649.8,376.4 649.8,449.8 633.9,449.8 633.9,376.4 	"/>
        <polyline class="st0" points="649.8,228.3 633.9,228.3 633.9,154.9 649.8,154.9 649.8,228.3 	"/>
        <polyline class="st0" points="633.9,154.9 649.8,154.9 649.8,228.3 633.9,228.3 633.9,154.9 	"/>
        <polyline class="st3" points="625.8,281.7 625.8,322.9 625.8,281.7 	"/>
        <polyline class="st0" points="593.9,281.7 593.9,322.9 593.9,281.7 	"/>
        <polyline class="st0" points="627.1,253.2 627.1,351.5 627.1,253.2 	"/>
        <polyline class="st0" points="627.1,281.7 627.1,322.9 627.1,281.7 	"/>
        <polyline class="st0" points="592.7,351.5 592.7,253.2 592.7,351.5 	"/>
        <polyline class="st3" points="592.7,322.9 592.7,281.7 592.7,322.9 	"/>
        <polyline class="st3" points="627.1,322.9 625.8,322.9 627.1,322.9 	"/>
        <line class="st0" x1="627.1" y1="253.2" x2="592.7" y2="253.2"/>
        <line class="st3" x1="592.7" y1="351.5" x2="627.1" y2="351.5"/>
        <polyline class="st3" points="625.8,281.7 627.1,281.7 625.8,281.7 	"/>
        <line class="st0" x1="627.1" y1="253.2" x2="592.7" y2="253.2"/>
        <line class="st3" x1="592.7" y1="351.5" x2="627.1" y2="351.5"/>
        <line class="st3" x1="593.9" y1="322.9" x2="592.7" y2="322.9"/>
        <line class="st3" x1="593.9" y1="281.7" x2="592.7" y2="281.7"/>
        <line class="st3" x1="593.9" y1="281.7" x2="592.7" y2="281.7"/>
        <line class="st3" x1="593.9" y1="322.9" x2="592.7" y2="322.9"/>
    </g>
    <g id="bibel">
        <polyline class="st4" points="620.3,303.8 613.6,317.3 601.7,317.3 608.3,303.8 	"/>
        <line class="st4" x1="620.3" y1="303.8" x2="608.3" y2="303.8"/>
        <polyline class="st4" points="618.8,302.3 612.1,315.8 600.2,315.8 606.8,302.3 	"/>
        <line class="st4" x1="618.8" y1="302.3" x2="606.8" y2="302.3"/>
        <line class="st4" x1="620.3" y1="303.8" x2="618.8" y2="302.3"/>
        <line class="st4" x1="613.6" y1="317.3" x2="612.1" y2="315.8"/>
        <line class="st4" x1="601.7" y1="317.3" x2="600.2" y2="315.8"/>
        <line class="st4" x1="608.3" y1="303.8" x2="606.8" y2="302.3"/>
        <polyline class="st4" points="613.6,287.4 620.3,300.8 608.3,300.8 601.7,287.4 	"/>
        <line class="st4" x1="613.6" y1="287.4" x2="601.7" y2="287.4"/>
        <polyline class="st4" points="612.1,288.9 618.8,302.3 606.8,302.3 600.2,288.9 	"/>
        <line class="st4" x1="612.1" y1="288.9" x2="600.2" y2="288.9"/>
        <line class="st4" x1="613.6" y1="287.4" x2="612.1" y2="288.9"/>
        <line class="st4" x1="620.3" y1="300.8" x2="618.8" y2="302.3"/>
        <line class="st4" x1="608.3" y1="300.8" x2="606.8" y2="302.3"/>
        <line class="st4" x1="601.7" y1="287.4" x2="600.2" y2="288.9"/>
        <polyline class="st4" points="620.3,303.8 613.6,317.3 601.7,317.3 608.3,303.8 	"/>
        <line class="st4" x1="620.3" y1="303.8" x2="608.3" y2="303.8"/>
        <polyline class="st4" points="618.8,302.3 612.1,315.8 600.2,315.8 606.8,302.3 	"/>
        <line class="st4" x1="618.8" y1="302.3" x2="606.8" y2="302.3"/>
        <line class="st4" x1="620.3" y1="303.8" x2="618.8" y2="302.3"/>
        <line class="st4" x1="613.6" y1="317.3" x2="612.1" y2="315.8"/>
        <line class="st4" x1="601.7" y1="317.3" x2="600.2" y2="315.8"/>
        <line class="st4" x1="608.3" y1="303.8" x2="606.8" y2="302.3"/>
        <polyline class="st4" points="613.6,287.4 620.3,300.8 608.3,300.8 601.7,287.4 	"/>
        <line class="st4" x1="613.6" y1="287.4" x2="601.7" y2="287.4"/>
        <polyline class="st4" points="612.1,288.9 618.8,302.3 606.8,302.3 600.2,288.9 	"/>
        <line class="st4" x1="612.1" y1="288.9" x2="600.2" y2="288.9"/>
        <line class="st4" x1="613.6" y1="287.4" x2="612.1" y2="288.9"/>
        <line class="st4" x1="620.3" y1="300.8" x2="618.8" y2="302.3"/>
        <line class="st4" x1="608.3" y1="300.8" x2="606.8" y2="302.3"/>
        <line class="st4" x1="601.7" y1="287.4" x2="600.2" y2="288.9"/>
    </g>
    <g id="kreis">
        <polyline class="st5" points="81.2,296.3 59.3,285.7 59.3,306.9 81.2,296.3 	"/>
        <path class="st6" d="M354.2,347.3c1.8-0.1,3.9-0.3,6.5-0.7c2.6-0.4,5.4-1.2,8.6-2.3c3.1-1.1,6.1-2.5,8.9-4.2
            c2.8-1.7,5.4-3.6,7.8-5.7c2.4-2.1,4.6-4.5,6.5-7c1.9-2.5,3.6-5.2,5-8.1c1.4-2.9,2.5-5.9,3.3-9c0.8-3.1,1.3-5.9,1.4-8.4
            s0.1-4.6,0-6.3c-0.1-1.7-0.4-3.8-0.8-6.2c-0.4-2.5-1.2-5.2-2.4-8.2c-1.2-3-2.6-5.9-4.4-8.6c-1.7-2.7-3.7-5.2-6-7.5
            c-2.2-2.3-4.7-4.4-7.3-6.3c-2.6-1.9-5.5-3.5-8.4-4.8s-6.1-2.4-9.4-3.2c-3.3-0.8-6.2-1.2-8.8-1.3c-2.6-0.1-4.8-0.1-6.5,0
            c-1.8,0.1-3.9,0.3-6.5,0.7c-2.6,0.4-5.4,1.2-8.6,2.3c-3.1,1.1-6.1,2.5-8.9,4.2c-2.8,1.7-5.4,3.6-7.8,5.7c-2.4,2.1-4.6,4.5-6.5,7
            c-1.9,2.5-3.6,5.2-5,8.1c-1.4,2.9-2.5,5.9-3.3,9c-0.8,3.1-1.3,5.9-1.4,8.4s-0.1,4.6,0,6.3c0.1,1.7,0.4,3.8,0.8,6.2
            c0.4,2.5,1.2,5.2,2.4,8.2c1.2,3,2.6,5.9,4.4,8.6c1.7,2.7,3.7,5.2,6,7.5c2.2,2.3,4.7,4.4,7.3,6.3c2.6,1.9,5.5,3.5,8.4,4.8
            c3,1.4,6.1,2.4,9.4,3.2c3.3,0.8,6.2,1.2,8.8,1.3C350.2,347.4,352.4,347.4,354.2,347.3"/>
        <path class="st6" d="M353.8,342c1.6-0.1,3.5-0.3,5.8-0.7c2.3-0.4,4.8-1,7.6-2c2.8-1,5.4-2.3,7.9-3.8c2.5-1.5,4.8-3.2,7-5.1
            c2.1-1.9,4.1-4,5.8-6.3c1.7-2.3,3.2-4.7,4.5-7.2c1.3-2.6,2.2-5.2,3-8c0.7-2.8,1.1-5.3,1.2-7.5s0.1-4.1,0-5.6
            c-0.1-1.5-0.3-3.4-0.7-5.5c-0.4-2.2-1.1-4.6-2.1-7.3c-1.1-2.7-2.4-5.2-3.9-7.6c-1.6-2.4-3.3-4.6-5.3-6.7c-2-2.1-4.2-3.9-6.5-5.6
            c-2.4-1.7-4.9-3.1-7.5-4.3s-5.4-2.2-8.3-2.9c-2.9-0.7-5.5-1.1-7.8-1.2c-2.3-0.1-4.2-0.1-5.8,0c-1.6,0.1-3.5,0.3-5.8,0.7
            c-2.3,0.4-4.8,1-7.6,2c-2.8,1-5.4,2.3-7.9,3.8c-2.5,1.5-4.8,3.2-7,5.1c-2.1,1.9-4.1,4-5.8,6.3c-1.7,2.3-3.2,4.7-4.5,7.2
            c-1.3,2.6-2.2,5.2-3,8c-0.7,2.8-1.1,5.3-1.2,7.5c-0.1,2.2-0.1,4.1,0,5.6c0.1,1.5,0.3,3.4,0.7,5.5c0.4,2.2,1.1,4.6,2.1,7.3
            c1.1,2.7,2.4,5.2,3.9,7.6c1.6,2.4,3.3,4.6,5.3,6.7c2,2.1,4.2,3.9,6.5,5.6c2.4,1.7,4.9,3.1,7.5,4.3c2.7,1.2,5.4,2.2,8.3,2.9
            c2.9,0.7,5.5,1.1,7.8,1.2C350.3,342.1,352.3,342.1,353.8,342"/>
        <path class="st6" d="M353.2,331.2c1.2-0.1,2.6-0.2,4.3-0.5c1.7-0.3,3.6-0.8,5.7-1.5c2.1-0.8,4.1-1.7,6-2.8c1.9-1.1,3.6-2.4,5.2-3.8
            c1.6-1.4,3.1-3,4.4-4.7c1.3-1.7,2.4-3.5,3.4-5.4s1.7-3.9,2.2-6c0.5-2.1,0.9-4,0.9-5.7c0.1-1.7,0.1-3.1,0-4.2
            c-0.1-1.1-0.2-2.5-0.5-4.2c-0.3-1.6-0.8-3.5-1.6-5.5c-0.8-2-1.8-3.9-2.9-5.8c-1.2-1.8-2.5-3.5-4-5c-1.5-1.5-3.1-3-4.9-4.2
            c-1.8-1.3-3.7-2.3-5.7-3.2c-2-0.9-4.1-1.6-6.3-2.1c-2.2-0.5-4.1-0.8-5.9-0.9c-1.7-0.1-3.2-0.1-4.4,0c-1.2,0.1-2.6,0.2-4.3,0.5
            c-1.7,0.3-3.6,0.8-5.7,1.5c-2.1,0.8-4.1,1.7-6,2.8c-1.9,1.1-3.6,2.4-5.2,3.8c-1.6,1.4-3.1,3-4.4,4.7c-1.3,1.7-2.4,3.5-3.4,5.4
            s-1.7,3.9-2.2,6c-0.5,2.1-0.9,4-0.9,5.7c-0.1,1.7-0.1,3.1,0,4.2c0.1,1.1,0.2,2.5,0.5,4.2c0.3,1.6,0.8,3.5,1.6,5.5
            c0.8,2,1.8,3.9,2.9,5.7c1.2,1.8,2.5,3.5,4,5c1.5,1.6,3.1,3,4.9,4.2c1.8,1.3,3.7,2.3,5.7,3.2c2,0.9,4.1,1.6,6.3,2.1
            c2.2,0.5,4.1,0.8,5.9,0.9C350.6,331.3,352,331.3,353.2,331.2"/>
        <path class="st6" d="M352.4,317.7c0.7,0,1.6-0.1,2.6-0.3c1-0.2,2.1-0.5,3.4-0.9c1.2-0.5,2.4-1,3.5-1.7c1.1-0.7,2.1-1.4,3.1-2.3
            c1-0.8,1.8-1.8,2.6-2.8c0.8-1,1.4-2.1,2-3.2c0.6-1.1,1-2.3,1.3-3.6c0.3-1.2,0.5-2.4,0.6-3.3c0-1,0-1.8,0-2.5s-0.1-1.5-0.3-2.5
            c-0.2-1-0.5-2.1-0.9-3.3c-0.5-1.2-1-2.3-1.7-3.4c-0.7-1.1-1.5-2.1-2.4-3c-0.9-0.9-1.9-1.7-2.9-2.5c-1-0.7-2.2-1.4-3.3-1.9
            c-1.2-0.5-2.4-1-3.7-1.3c-1.3-0.3-2.5-0.5-3.5-0.5s-1.9,0-2.6,0c-0.7,0-1.6,0.1-2.6,0.3c-1,0.2-2.1,0.5-3.4,0.9s-2.4,1-3.5,1.7
            c-1.1,0.7-2.1,1.4-3.1,2.3s-1.8,1.8-2.6,2.8c-0.8,1-1.4,2.1-2,3.2c-0.6,1.1-1,2.3-1.3,3.6c-0.3,1.2-0.5,2.4-0.6,3.3
            c0,1,0,1.8,0,2.5s0.1,1.5,0.3,2.5c0.2,1,0.5,2.1,0.9,3.3c0.5,1.2,1,2.3,1.7,3.4c0.7,1.1,1.5,2.1,2.4,3c0.9,0.9,1.9,1.7,2.9,2.5
            c1,0.7,2.2,1.4,3.3,1.9c1.2,0.5,2.4,1,3.7,1.3c1.3,0.3,2.5,0.5,3.5,0.5C350.8,317.8,351.7,317.8,352.4,317.7"/>
        <line class="st6" x1="351.2" y1="342" x2="351.2" y2="278.9"/>
        <line class="st6" x1="314.8" y1="324.6" x2="367.5" y2="286.6"/>
        <line class="st6" x1="331.1" y1="259.1" x2="360.2" y2="315.8"/>
        <line class="st6" x1="308.2" y1="284.3" x2="370.4" y2="304.6"/>
        <line class="st6" x1="372.1" y1="259.5" x2="341.9" y2="315.6"/>
        <line class="st6" x1="396.3" y1="292.6" x2="331.2" y2="300.9"/>
        <line class="st6" x1="385.4" y1="327.2" x2="336" y2="285.5"/>
        <path class="st6" d="M351.6,304.9c0.5,0,1-0.1,1.7-0.3c0.7-0.2,1.3-0.5,2-1c0.7-0.5,1.3-1.1,1.7-1.9c0.5-0.7,0.7-1.4,0.8-2.1
            c0.1-0.6,0.1-1.2,0.1-1.6c0-0.4-0.1-1-0.3-1.6c-0.2-0.6-0.5-1.3-1.1-2c-0.5-0.7-1.2-1.2-1.9-1.7c-0.8-0.4-1.5-0.7-2.1-0.8
            s-1.2-0.1-1.7-0.1c-0.5,0-1,0.1-1.7,0.3c-0.7,0.2-1.3,0.5-2,1c-0.7,0.5-1.3,1.1-1.7,1.9c-0.5,0.7-0.7,1.4-0.8,2.1
            c-0.1,0.6-0.1,1.2-0.1,1.6s0.1,1,0.3,1.6c0.2,0.6,0.5,1.3,1.1,2c0.5,0.7,1.2,1.2,1.9,1.7c0.8,0.4,1.5,0.7,2.1,0.8
            C350.6,304.9,351.1,304.9,351.6,304.9"/>
        <path class="st6" d="M354.2,347.3c1.8-0.1,3.9-0.3,6.5-0.7c2.6-0.4,5.4-1.2,8.6-2.3c3.1-1.1,6.1-2.5,8.9-4.2
            c2.8-1.7,5.4-3.6,7.8-5.7c2.4-2.1,4.6-4.5,6.5-7c1.9-2.5,3.6-5.2,5-8.1c1.4-2.9,2.5-5.9,3.3-9c0.8-3.1,1.3-5.9,1.4-8.4
            s0.1-4.6,0-6.3c-0.1-1.7-0.4-3.8-0.8-6.2c-0.4-2.5-1.2-5.2-2.4-8.2c-1.2-3-2.6-5.9-4.4-8.6c-1.7-2.7-3.7-5.2-6-7.5
            c-2.2-2.3-4.7-4.4-7.3-6.3c-2.6-1.9-5.5-3.5-8.4-4.8s-6.1-2.4-9.4-3.2c-3.3-0.8-6.2-1.2-8.8-1.3c-2.6-0.1-4.8-0.1-6.5,0
            c-1.8,0.1-3.9,0.3-6.5,0.7c-2.6,0.4-5.4,1.2-8.6,2.3c-3.1,1.1-6.1,2.5-8.9,4.2c-2.8,1.7-5.4,3.6-7.8,5.7c-2.4,2.1-4.6,4.5-6.5,7
            c-1.9,2.5-3.6,5.2-5,8.1c-1.4,2.9-2.5,5.9-3.3,9c-0.8,3.1-1.3,5.9-1.4,8.4s-0.1,4.6,0,6.3c0.1,1.7,0.4,3.8,0.8,6.2
            c0.4,2.5,1.2,5.2,2.4,8.2c1.2,3,2.6,5.9,4.4,8.6c1.7,2.7,3.7,5.2,6,7.5c2.2,2.3,4.7,4.4,7.3,6.3c2.6,1.9,5.5,3.5,8.4,4.8
            c3,1.4,6.1,2.4,9.4,3.2c3.3,0.8,6.2,1.2,8.8,1.3C350.2,347.4,352.4,347.4,354.2,347.3"/>
        <path class="st6" d="M459.7,298.3c0-59.5-49-108.5-108.5-108.5c-59.5,0-108.5,49-108.5,108.5c0,59.5,49,108.5,108.5,108.5
            C410.7,406.8,459.7,357.8,459.7,298.3"/>
        <path class="st6" d="M459.7,298.3c0-59.5-49-108.5-108.5-108.5c-59.5,0-108.5,49-108.5,108.5c0,59.5,49,108.5,108.5,108.5
            C410.7,406.8,459.7,357.8,459.7,298.3"/>
    </g>
    <g id="möbel1">
        <line class="st3" x1="408.9" y1="443.8" x2="408.9" y2="414.4"/>
        <line class="st3" x1="408.9" y1="443.8" x2="408.9" y2="414.4"/>
        <line class="st3" x1="209.5" y1="443.8" x2="408.9" y2="443.8"/>
        <polyline class="st3" points="408.9,414.4 238.9,414.4 238.9,375.5 209.5,375.5 209.5,443.8 408.9,443.8 	"/>
        <polyline class="st3" points="238.9,414.4 238.9,375.5 209.5,375.5 209.5,443.8 	"/>
        <polyline class="st3" points="206.9,372.9 206.9,446.5 411.6,446.5 	"/>
        <line class="st3" x1="241.6" y1="372.9" x2="206.9" y2="372.9"/>
        <polyline class="st3" points="411.6,411.8 241.6,411.8 241.6,372.9 	"/>
        <polyline class="st3" points="411.6,430.6 309.2,430.6 309.2,446.5 411.6,446.5 411.6,433.9 309.2,433.9 309.2,446.5 	"/>
        <line class="st3" x1="411.6" y1="433.9" x2="411.6" y2="430.6"/>
        <line class="st3" x1="309.2" y1="433.9" x2="309.2" y2="430.6"/>
        <line class="st3" x1="411.6" y1="407.6" x2="411.6" y2="430.6"/>
        <line class="st3" x1="411.6" y1="407.6" x2="411.6" y2="411.8"/>
        <polyline class="st3" points="411.6,407.6 245.7,407.6 245.7,372.9 206.9,372.9 206.9,446.5 309.2,446.5 	"/>
        <line class="st3" x1="245.7" y1="407.6" x2="241.6" y2="411.8"/>
        <line class="st3" x1="245.7" y1="372.9" x2="241.6" y2="372.9"/>
        <line class="st3" x1="411.6" y1="411.8" x2="411.6" y2="446.5"/>
        <line class="st3" x1="408.9" y1="414.4" x2="238.9" y2="414.4"/>
        <line class="st3" x1="408.9" y1="154.2" x2="408.9" y2="183.6"/>
        <line class="st3" x1="408.9" y1="154.2" x2="408.9" y2="183.6"/>
        <line class="st3" x1="209.5" y1="154.2" x2="408.9" y2="154.2"/>
        <polyline class="st3" points="408.9,183.6 238.9,183.6 238.9,222.4 209.5,222.4 209.5,154.2 408.9,154.2 	"/>
        <polyline class="st3" points="238.9,183.6 238.9,222.4 209.5,222.4 209.5,154.2 	"/>
        <polyline class="st3" points="206.9,225.1 206.9,151.5 411.6,151.5 	"/>
        <line class="st3" x1="241.6" y1="225.1" x2="206.9" y2="225.1"/>
        <polyline class="st3" points="411.6,186.2 241.6,186.2 241.6,225.1 	"/>
        <polyline class="st3" points="411.6,167.4 309.2,167.4 309.2,151.5 411.6,151.5 411.6,164.1 309.2,164.1 309.2,151.5 	"/>
        <line class="st3" x1="411.6" y1="164.1" x2="411.6" y2="167.4"/>
        <line class="st3" x1="309.2" y1="164.1" x2="309.2" y2="167.4"/>
        <line class="st3" x1="411.6" y1="190.4" x2="411.6" y2="167.4"/>
        <line class="st3" x1="411.6" y1="190.4" x2="411.6" y2="186.2"/>
        <polyline class="st3" points="411.6,190.4 245.7,190.4 245.7,225.1 206.9,225.1 206.9,151.5 309.2,151.5 	"/>
        <line class="st3" x1="245.7" y1="190.4" x2="241.6" y2="186.2"/>
        <line class="st3" x1="245.7" y1="225.1" x2="241.6" y2="225.1"/>
        <line class="st3" x1="411.6" y1="186.2" x2="411.6" y2="151.5"/>
        <line class="st3" x1="408.9" y1="183.6" x2="238.9" y2="183.6"/>
    </g>
    <template v-for="mp, index in mappos" :key="index"> 
        <circle fill="#ffffff" stroke="none" :cx="mp[0][0]" :cy="mp[0][1]" r="12"> 
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </circle>
        <text :x="mp[0][0]" :y="mp[0][1]+5" class="small" v-html="mp[1]!='x'?mp[1]:''"></text>
    </template>
    

</svg>


</template>


<script>
import { onMounted } from 'vue'; // ref, onMounted, computed
// import useEmitter from '../composables/useEmitter'

// usage
// <bibel-svg :pageNum="pageNum" :json="sitejson" ></iris-svg>


export default {
    props: {
        mappos: {
            type: Array,
            required: true,
            default: ()=> [ [ [10,10], "x"] ]
        }
    },
    setup() {  // props
        
        onMounted(() => {
       
        });


        return {
           
        };
    },
    
}
</script>



<style lang="scss" scoped>
    .small {
        font-family: FontBold;
        font-size: 15px;
        text-anchor: middle;
    }

	.st0{fill:none;stroke:#EEEEEE;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st1{fill:none;stroke:#94A78F;stroke-width:0.288;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st2{fill:none;stroke:#94A78F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st3{fill:none;stroke:#EEEEEE;stroke-width:0.288;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st4{fill:none;stroke:#DD0000;stroke-width:0.504;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st5{fill:#EEEEEE;}
	.st6{fill:none;stroke:#94A78F;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

    .small {
        font-family: FontBold;
        font-size: 15px;
        text-anchor: middle;
    }

</style>