<template>
  
  <div class="content">

      <Header v-if="uiLoaded==true && Object.keys(contentUi).length > 0" :contentui="contentUi" :language="store.state.language"></Header>

      <main :class="store.state.kontrastSwitch==true ? 'main-bgcolor': ''"  v-if="uiLoaded==true && Object.keys(contentUi).length > 0">
           
                  <!-- <transition name="fadepage" appear mode="out-in">
                    <div v-if="store.state.showRundgang==true">
                      <button @click="pushPage('introPage')">introPage</button>
                      <button @click="rundgangPage">content</button>
                    </div>
                  </transition> -->

                  <!-- Blödsinn, das gehört definitiv in die conten / rundgang ecke!!! 
                  <transition name="fadepage" appear mode="out-in">
                    <div v-if="store.state.showRundgang==true">
                      <div  class="button-base" :class="index==route.params.roomId ? 'button-active':''" v-for="item, index in contentUi.rooms" :key="index"  @click="contentRoom(index)">
                          <a> {{ item.name }} </a>   
                      </div>
                      
                      <div content-footer>
                        <button  @click="prevRoom()">
                            <a> {{ contentUi.ui.prev[store.state.language] }} </a>   
                        </button>
                        <button  @click="nextRoom()">
                            <a> {{ contentUi.ui.next[store.state.language] }} </a>   
                        </button>
                        <hr size="5" style="opacity: 0.25" noshade/> 
                      </div>

                    </div>
                  </transition>-->

                <!-- <router-view v-if="uiLoaded==true"  :key="route.path" >
                </router-view> -->

                  <!-- <p>{{route.path}}</p> -->

                  <!-- :id="roomId"  :roomId="roomId"  -->
                  <router-view v-if="uiLoaded==true && contentLoaded==true && zitateLoaded==true" v-slot="{ Component }" :key="(route.path && route.params.roomId)" :contentui="contentUi" :content="content" :zitate="contentZitate" :contentwp="contentWp" :language="store.state.language" >  
                      <!-- name="fadepage" -->
                      <transition name="slidefade"  mode="out-in">
                        <component :is="Component" class="animated" />
                      </transition>
                  </router-view>

                  <div class="spacer">

                  </div>

              
      </main>

      <div class="floater">
        <transition-group name="movefloater" mode="out-in" key="store.state.language">
          <FloaterSettings :contentui="contentUi" :language="store.state.language" v-if="uiLoaded==true && Object.keys(contentUi).length > 0  && store.state.showSettings == true" :key="2"></FloaterSettings >
          <FloaterLanguage :contentui="contentUi" :language="store.state.language" :content="content" v-if="uiLoaded==true && Object.keys(contentUi).length > 0 && store.state.showLanguage == true" :key="3"></FloaterLanguage>
        </transition-group>
      </div>

      <Footer v-if="uiLoaded==true && Object.keys(contentUi).length > 0" :contentui="contentUi" :language="store.state.language"></Footer>
      <BaseFooter v-if="uiLoaded==true && Object.keys(contentUi).length > 0 && route.path!='/'" :content="contentUi" :language="store.state.language"></BaseFooter>
      <!-- && route.path!='/'  -->
  </div>

  <!-- <div class="floaterbottom">
      <transition name="movefloaterup" mode="out-in" key="store.state.language">
        <FloaterPincode :contentui="contentUi" :language="store.state.language" :content="content" v-if="uiLoaded==true && Object.keys(contentUi).length > 0 && store.state.showPincode == true" ></FloaterPincode>
      </transition>
  </div> -->

  <transition name="fadepage" appear mode="out-in">
      <menuModal v-if="store.state.menuModal==true" :contentui="contentUi" :language="store.state.language"></menuModal>
  </transition>

  <!-- <transition name="fadepage" appear mode="out-in">
    <QrcodeModal v-if="store.state.qrcodeModal==true&&uiLoaded==true" :content="contentUi"></QrcodeModal>
  </transition> -->

  <transition name="fadepage" appear mode="out-in">
      <PincodeModal v-if="store.state.pincodeModal==true&&uiLoaded==true" :contentui="contentUi" :language="store.state.language"></PincodeModal>
  </transition>

  <transition name="fadesplash"  mode="out-in">
      <blSplash v-if="uiLoaded==false"></blSplash>
  </transition>

  <!-- <blDemoOverlay v-if="store.state.debug==true"></blDemoOverlay> -->
  
  <!-- <blLayoutGrid></blLayoutGrid> -->
  <!-- <blSystemInformation /> -->

</template>



<script>
// import QrcodeModal from './components/QrcodeScanner.vue'
import PincodeModal from './components/PincodeModal.vue'
// import QrcodeModal from './components/QrcodeScannerAlternative3.vue'
// import QrcodeModal from './components/QrcodeScannerAlternative1.vue'
import menuModal from './components/Menu.vue'

import Footer from './components/Footer.vue'
import BaseFooter from './components/BaseFooter.vue'
import Header from './components/Header.vue'
// import blSystemInformation from './components/blSystemInformation.vue'
import blSplash from './components/blSplash.vue'
// import blDemoOverlay from './components/blDemoOverlay.vue'

import FloaterSettings from './components/FloaterSettings.vue'
import FloaterLanguage from './components/FloaterLanguage.vue'
// import FloaterPincode from './components/FloaterPincode.vue'
// import blLayoutGrid from './components/blLayoutGrid_ipad_768x1024.vue'

import { onMounted, ref, inject  } from 'vue'; // ref, onMounted, computed
import { useRouter, useRoute } from 'vue-router'  // 
import {useStore} from 'vuex';
import useEmitter from '@/composables/useEmitter';
import { publicPath } from '../vue.config';
// import orderBy from 'lodash/orderBy'


export default {
  name: 'App',
  

  components: {
    // QrcodeModal,
    PincodeModal,
    menuModal,
    Header,
    Footer,
    BaseFooter,
    // blSystemInformation
    blSplash,
    // blDemoOverlay,

    FloaterSettings,
    FloaterLanguage,
    // FloaterPincode,
    // blLayoutGrid
  },
  

  setup(){
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const emitter = useEmitter();
    const $cookies = inject('$cookies');

    let uiLoaded = ref(false);
    let contentLoaded = ref(false);
    let zitateLoaded = ref(false);
    // let roomId = 0;

    let errorLoads =[];
    let toLoads = [];
    let loadtext = ref("");
    let contentUi = ref({});
    let content = ref({});
    let contentWp = ref({});
    let contentZitate = ref([]);
    let imageArray = []


    // nicht im router script initialisisert
    // eslint-disable-next-line no-unused-vars
    router.beforeEach((to, from) => {
      console.log("to", to.params )
      // Object.prototype.hasOwnProperty.call(object, "objectProperty");
      if( !Object.prototype.hasOwnProperty.call(to.params, "pageId") ) {
        to.params.pageId = 1;
        store.state.roomPageId = 1;
      } else if (to.params.pageId == "") {
        to.params.pageId = 1;
        store.state.roomPageId = 1;
      } else {
        console.log("EXXXTERN PageID", to.params.pageId)
        // console.log(store)
        store.state.roomPageId = parseInt(to.params.pageId);
      }
      console.log("to", to)
    })


    const resetApp = () => {
      uiLoaded.value = false
      contentLoaded.value = false
      // roomId = ref(0);
      // store.state.roomId = 0
      // console.log("******************************");
      // console.log(router.currentRoute.value.fullPath);
      // console.log(router.currentRoute.value.params);
      // console.log(roomId, roomId)
      // console.log(router.currentRoute.value.fullPath);
      // if (router.currentRoute.value.fullPath !== "home") router.push("/home").catch(()=>{});
      // console.log(router.currentRoute.value.fullPath);
    }

    const introPage = () => {
      store.state.roomId = 0;
      store.state.roomPageId = 1;
      router.push("/introPage").catch(()=>{});
    }

    const pushPage = (page) => {
      store.state.roomId = 0;
      store.state.roomPageId = 1;
      router.push("/" + page).catch(()=>{});
    }

    const getDebug= () => {
      return store.state.debug;
    }

    // const nextRoom = () => {
    //   if (store.state.roomId < (contentUi.value.rooms.length-1)){
    //     store.state.roomId = store.state.roomId +1;
    //       router.push({ name: "rundgangPage", params: { roomId: store.state.roomId, language: store.state.language}, props: true})
    //   } 
    // }

    // const prevRoom = () => {
    //   if (store.state.roomId >= 0){
    //     store.state.roomId = store.state.roomId -1;
    //     // if(store.state.roomId==0){
    //     //   pushPage('introPage')
    //     // } else {
    //         router.push({ name: "rundgangPage", params: { roomId: store.state.roomId, language: store.state.language}, props: true})
    //     // }
    //   }
    // }

    // const rundgangPage = () => {
    //   store.state.roomId = store.state.roomId  +1;
    //   console.log("contentExtern", store.state.roomId );
    //   router.push({ name: "rundgangPage", params: { roomId: store.state.roomId}, props: true})
    // }

    // const contentRoom = (index) => {
    //   store.state.roomId = index ;
    //   console.log("contentRoom", store.state.roomId );
    //   router.push({ name: "rundgangPage", params: { roomId: store.state.roomId, language: store.state.language}, props: true})
    // }

    const checkCookies = () => {
      // console.log("checkCookies")
      let lg = $cookies.isKey("language")
      // console.log("lg", lg)
      if (lg == false)  {
        $cookies.set("language",store.state.language);
        $cookies.set("typoSwitch",store.state.typoSwitch); 
        $cookies.set("kontrastSwitch",store.state.kontrastSwitch); 
      } else {
        // console.log($cookies.get("language"))
        store.state.language       = $cookies.get("language");
        store.state.typoSwitch     = ( $cookies.get("typoSwitch") === 'true'); 
        store.state.kontrastSwitch = ( $cookies.get("kontrastSwitch") === 'true'); 
      }
      
    }

    // IMAGE LOADING METHODS
    const checkLoadingFinishedOK= (url) => { // (url){
        // console.log("checkLoadingFinishedOK")
        let index = toLoads.indexOf(url);
        loadtext = url;
        if (index > -1) {
          toLoads.splice(index, 1);
        }
        // console.log(this.toLoads);
        checkLoadingFinished();
        // console.log("OK");
    }

    const checkLoadingFinishedNOOK = (url) => { //(url){
        var index = toLoads.indexOf(url);
        if (index > -1) {
          toLoads.splice(index, 1);
        }
        // console.log(this.toLoads);
        errorLoads.push(url);
        checkLoadingFinished();
        console.log("NO OK");
    }

    const checkLoadingFinished = () => {
        if (toLoads.length==0){
          // loaded.value = true;
          if (errorLoads.length == 0){
              store.dispatch('setAppLoaded');  // direkt freischalten des splash screen
          } else {
            // headertext= "Fehlende Medien";
            uiLoaded.value = true; // button auf dem splash screen anzeigen
            loadtext.value = errorLoads;
          }
          // console.log ("END OF LOADING");

          checkCookies()
          uiLoaded.value = true;
          // console.log("uiLoaded", uiLoaded.value)
        }
    }

    const loadOneImage = (url) => { // (url) {
        // var self = this;
        // console.log(" **** url laden versuch: "+ url)
        return new Promise(function(resolve, reject) {
          let img = new Image()
          // self = this
          img.onload = function() {
            // console.log("loaded:"+ url)
            checkLoadingFinishedOK(url);
            resolve(url)
          }
          img.onerror = function() {
            console.log("konnte das nicht laden:"+ url)
            checkLoadingFinishedNOOK(url);
            reject(url)
          }
          img.src = url
        })
    }

    const contentLoad = () => {
      
      // console.log("contentLoad", contentLoaded.value)
      // console.log("contentUi debug", contentUi.value.debug)
      if (process.env.NODE_ENV === "production"){
        var base_url = "https://app.museum-abtei-liesborn.de/"; // "https://www.bluelemon.de/demo/AL/mediaguide_v3/" // "." + publicPath
        base_url = window.location.origin +"/" + publicPath //   
      } else {
        base_url = window.location.origin +"/" + publicPath //   
        // base_url =  "." + publicPath
      }
      // console.log("content base_url", base_url + 'content.json')
      // console.log("window.location.origin", window.location.origin + 'content.json')
      // console.log("baseUrl", baseUrl)
      // fetch('./static/contentUi.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      fetch(base_url + 'content.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
          .then(res => {
              if (!res.ok) {
                console.log("loaded NOOK")
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {
              // console.log("content")
              // console.log("json", json)
              content.value = json;
              store.state.content = json;
              // console.log("debug", content.value.debug)
              // imageArray.push("./static/images/bluelemon_logo.jpg");
              // imageArray.push(base_url + "/images/bluelemon_logo.jpg");
              // // for(i = 0; i < contentUi.menu.length; i++) {
              // //   // var menu = this.contentUi.menu[i];          
              // // }
              // loadOneImage(imageArray[0])
              // ??? var promises = imageArray.map(loadOneImage);
              // ??? Promise.all(promises.map(p => p.catch(e => e)));
              // checkCookies()
              // contentLoaded.value = true;

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            // contentLoaded.value = true;
          });


      // der eigentliche Datenteil, wird nach und nach content.json ersetzen
      // https://app.museum-abtei-liesborn.de/?json=get_page_index&custom_fields=*&dev=0&count=800
      fetch(base_url + 'contentwp.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      
          .then(res => {
              if (!res.ok) {
                console.log("loaded live content NOOK")
                console.log(res);
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {
              // console.log("live content loaded")
              // console.log("json", json)
              contentWp.value = json.pages;
              store.state.contentWp = json.pages;
              // console.log("p", contentWp)
              // imageArray.push("./static/images/bluelemon_logo.jpg");
              // imageArray.push(base_url + "/images/bluelemon_logo.jpg");
              // // for(i = 0; i < contentUi.menu.length; i++) {
              // //   // var menu = this.contentUi.menu[i];          
              // // }
              // loadOneImage(imageArray[0])
              // ??? var promises = imageArray.map(loadOneImage);
              // ??? Promise.all(promises.map(p => p.catch(e => e)));
              // checkCookies()
              contentLoaded.value = true;

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            contentLoaded.value = true;
          });
      
      
      

    }

    const zitatLoad = () => {
      let zitateUrl = '';
      
      if (process.env.NODE_ENV === "production"){
        var base_url = "https://app.museum-abtei-liesborn.de/"; // "https://www.bluelemon.de/demo/AL/mediaguide_v3/" // "." + publicPath
        base_url =  window.location.origin +"/" + publicPath // 
      } else {
        base_url =  window.location.origin +"/" + publicPath //   
      }

      if (contentUi.value.noaudio == true){
        zitateUrl = base_url + 'zitate.json';
      } else {
        zitateUrl = 'https://app.museum-abtei-liesborn.de/wp-json/wp/v2/zitat?_embed&per_page=100&page=1'
      }
      fetch(zitateUrl, { method: 'get', headers: { 'content-type': 'application/json' } } )
      
          .then(res => {
              if (!res.ok) {
                console.log("loaded live content NOOK")
                console.log(res);
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {
              
              const sortAlphaNum = (a, b) => a.slug.localeCompare(b.slug, 'en', { numeric: true })
              // console.log(ary.sort(sortAlphaNum))
              // const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
              // const newSort = orderBy(json,['slug'],'asc');
              const newSort = json.sort(sortAlphaNum)
              // console.log("newSort", newSort)
              contentZitate.value = newSort;

              // console.log("contentZitate", contentZitate)
              store.state.contentZitate = json;
              
              zitateLoaded.value = true;

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            // contentLoaded.value = true;
          });
    }


    const uiLoad = () => {
      // console.log("uiLoad", uiLoaded.value)
      // console.log("contentUi debug", contentUi.value.debug)
      if (process.env.NODE_ENV === "production"){
        // var base_url = "https://www.bluelemon.de/demo/AL/mediaguide_v3/" // "." + publicPath
        var base_url = "https://app.museum-abtei-liesborn.de/" // "." + publicPath
        base_url =  window.location.origin +"/" +publicPath //   
      } else {
        base_url =  window.location.origin +"/" +publicPath //   
        // base_url =  "." + publicPath
      }
      
      fetch(base_url + 'contentUi.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
          .then(res => {
              if (!res.ok) {
                console.log("loaded NOOK")
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {
              // console.log("loaded")
              // console.log("json", json)
              contentUi.value = json;
              store.state.contentUi = json;
              store.state.debug = contentUi.value.debug;
              zitatLoad();

              // console.log("contentUi", contentUi.value)
              // imageArray.push("./static/images/bluelemon_logo.jpg");
              imageArray.push(base_url + "/images/bluelemon_logo.jpg");
              // for(i = 0; i < contentUi.menu.length; i++) {
              //   // var menu = this.contentUi.menu[i];          
              // }
              loadOneImage(imageArray[0])
              // ??? var promises = imageArray.map(loadOneImage);
              // ??? Promise.all(promises.map(p => p.catch(e => e)));
              // checkCookies()
              uiLoaded.value = true;

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            uiLoaded.value = true;
          });
    }

    onMounted(() => {
      
      window.addEventListener('keydown', (e) => {
            if(e.key =="d"){
            //  this.$store.dispatch('switchDebug'); 
            store.state.debug = !store.state.debug;
            // console.log("store.state.debug", store.state.debug);
            //  this.$store.state.debug = !this.$store.state.debug //  ('switchDebug'); 
            // } else if (e.key =="g"){
            //   this.switchVisible(); 
            // } else if (e.key =="t"){
            //   clearTimeout(refreshTimeoutId);
            //   store.dispatch('setAppTimeout', true);           
            }
          }, {passive: true});
      
      // console.log("route.path", route.path);

      resetApp()
      
      if (uiLoaded.value == false){
        // console.log("ui laden init")
        uiLoad();
      }
      if (contentLoaded.value == false){
        console.log("content laden init")
        contentLoad();
      }
      emitter.on("GO_HOME", message => {
          console.log("GO HOME in APP", message)
          store.state.roomId = 0;
          store.state.roomPageId = 1;
          store.state.showRundgang = false;
          router.push("/").catch(()=>{});
          // resetApp()
      })
      emitter.on("GO_UP", message => {
          console.log("GO UP in APP", message)
      })

      // console.log(process.env.NODE_ENV, publicPath)

      /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
      var prevScrollpos = window.pageYOffset;
      // alert (prevScrollpos);
      window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        // alert (currentScrollPos);
        // console.log(prevScrollpos, currentScrollPos);  // && currentScrollPos < 30    || (currentScrollPos == null)   && ( currentScrollPos < 50 ) 
        if ( ( (prevScrollpos > currentScrollPos)  ||  ( currentScrollPos < 50 )  ||  ( currentScrollPos == undefined ) ) ){
          document.getElementById("main-header").style.top = "0rem";
        } else {
          document.getElementById("main-header").style.top = "-8rem"; // "-70px";
          store.state.showSettings = false;
          store.state.showLanguage = false;
        }
        prevScrollpos = currentScrollPos;
      }
    })

    // methods: {
    //   checkLoadingFinishedOK(url){
    //     var index = this.toLoads.indexOf(url);
    //     this.loadtext = url;
    //     if (index > -1) {
    //       this.toLoads.splice(index, 1);
    //     }
    //     // console.log(this.toLoads);
    //     this.checkLoadingFinished();
    //     // console.log("OK");
    //   },
    //   checkLoadingFinishedNOOK(url){
    //     var index = this.toLoads.indexOf(url);
    //     if (index > -1) {
    //       this.toLoads.splice(index, 1);
    //     }
    //     // console.log(this.toLoads);
    //     this.errorLoads.push(url);
    //     this.checkLoadingFinished();
    //     // console.log("NO OK");
    //   },
    //   checkLoadingFinished(){
    //     if (this.toLoads.length==0){
    //       this.loaded = true;
    //       this.firstLoad = 1; // jetzt die karten einmal aufbauen
    //       if (this.errorLoads.length == 0){
    //           this.$store.dispatch('setAppLoaded');  // direkt freischalten des splash screen
    //       } else {
    //         this.headertext= "Fehlende Medien";
    //         this.endload = true; // button auf dem splash screen anzeigen
    //         this.loadtext = this.errorLoads;
    //       }
    //       // console.log ("END OF LOADING");
    //       this.endload = true;
    //     }
    //   },
    //   loadOneImage(url) {
    //     var self = this;
    //     // console.log(" **** url laden versuch: "+ url)
    //     return new Promise(function(resolve, reject) {
    //       var img = new Image()
    //       // self = this
    //       img.onload = function() {
    //         // console.log("loaded:"+ url)
    //         self.checkLoadingFinishedOK(url);
    //         resolve(url)
    //       }
    //       img.onerror = function() {
    //         console.log("konnte das nicht laden!!!:"+ url)
    //         self.checkLoadingFinishedNOOK(url);
    //         reject(url)
    //       }
    //       img.src = url
    //     })
    //   },
    // },

    return {
      uiLoaded,
      contentLoaded,
      zitateLoaded,
      resetApp,
      route,
      router,
      introPage,
      pushPage,
      // rundgangPage,
      // contentRoom,
      // roomId,
      store,
      content,
      contentWp,
      contentUi,
      contentZitate,
      // nextRoom,
      // prevRoom,
      getDebug
    }

  }
  // methods:{
  //   startExtern(theID){
  //       // console.log("startExtern", theID);
  //       this.pushPath('/menu' + theID);
  //   },
  //   pushExtern(theID){
  //       // console.log("startExtern", theID);
  //       this.pushPath('/menu' + theID);
  //   },
  //   pushPath: function(path) {
  //       // console.log ("pushPath", path);
  //       if (this.$router.path !== path) this.$router.push(path).catch(()=>{});
  //   },
  //   home: function() {
  //       console.log ("home");
  //       console.log("******************************");
  //       if (this.$router.name !== "home") this.$router.push("/").catch(()=>{});
  //   },
  //   setBackState: function(value) {
  //       this.backState = value;
  //   }
  // }
}
</script>



<style lang="scss">
@import "css/variables.scss";

#app {
  font-family: FontLight, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin: 0;
  /* display: grid;
  grid-template-rows: 3.5rem auto fit-content(8rem);
  grid-template-areas: "header"
                       "main"
                       "footer"; */
  /* padding-top: 3.5rem; */
  height: 100%;
  /* color: #2c3e50;
  margin-top: 60px; */
}

.content {
  text-align: left;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 7.0rem auto  fit-content(8rem) 0;   /* erster Wert Abstand nach oben */ /* fit-content(8rem)  5rem */
  grid-template-areas: "header"
                       "main"
                       "footer"
                       "basefooter";
  /* padding-top: 3.5rem; */
  height: 100%;
}

.floater {
  position: fixed;
  
  top: 100px;
  // left: 0px;
  // width: 1024px;
  width: 100%;
  max-width: 1180px;
  height: auto;
  margin: auto;
  align-items: center;
  // z-index: 10;
  // background-color: #ffe601;
}

// width: 100%;
//     max-width: 1180px;
//     position: fixed;
//     top: 0;
//     background: #ffffff;
//     padding: 0.5rem 2rem 0.5rem 1.9rem;
//     height: 7rem;
//     z-index: 50;
//     display: flex;
//     margin: auto;
//     align-items: center;
//     justify-content: space-between;
//     transition: top 0.3s;






.floaterbottom {
  position: sticky;
  bottom: 0px;
  margin-bottom: 0px;
  // bottom: 0px;
  // left: 0px;
  // // width: 1024px;
  width: 100%;
  height: auto;
  // z-index: 10;
  // background-color: #ffe601;
}


main {
  /* grid-row: 2 / 3; */
  grid-area: main;
  background-color: $backgroundcolor;
  /* margin-bottom: 2rem; */
}

.main-bgcolor {
  background-color: black;
}
/* footer {
  background-color: orange;
} */
</style>

<style lang="scss" scoped>
button {
  background-color: #ffe601; 
  border-radius: 8px;
  border: none;
  color: rgb(0, 0, 0);
  margin: 0.4rem;
  padding: 0.8rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.0rem;
}

.animated {
  transition: all 0.5s;
  // display: flex;
  width: 100%;
}

.spacer {
  height: 20px;
}
</style>