// const path = require("path");

// vue.config.js
module.exports = {

  runtimeCompiler: false,
  // publicPath:
    // process.env.NODE_ENV === "production"
    //   ? "./" // prod
    //   : "/", // dev

  publicPath: process.env.NODE_ENV === 'production'
        ? '/'
        : '/',

  // publicPath: process.env.NODE_ENV === 'production'
  //       ? '/demo/AL/mediaguide_v3/'
  //       : '/',
  
  // baseUrl: process.env.NODE_ENV === 'production'
  //       ? 'https://www.bluelemon.de/demo/AL/mediaguide_v2b/'
  //       : 'http://localhost:8080/'

  // https://stackoverflow.com/questions/64723498/how-to-set-vue-3-with-router-from-subfolder
  // publicPath: 
  //   process.env.NODE_ENV === 'production' ? '/demo/AL/mediaguide_v2' : '',
};