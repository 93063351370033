<template>
    
    <nav class="mobile-nav">
        
        <h1>Menu</h1>

        <ul class="mobile-nav__items">

            <li class="mobile-nav__item" v-for="item, index in uicontent.menu[language]" :key="index">
                 <a @click="openRouterSite(index)"> {{ item }} </a> 
            </li>

            <!-- <li class="main-nav__item" v-for="item, index in content.menu[language]" :key="index">
                  <a @click="openRouterSite(index)"> {{ item }} </a>   
            </li> -->

            <!-- <li class="mobile-nav__item">
                <a href="../packages/index.html">Packages</a>
            </li>
            <li class="mobile-nav__item">
                <a href="../customers/index.html">Customers</a>
            </li>
            <li class="mobile-nav__item mobile-nav__item--cta">
                <a href="index.html">Start Hosting</a>
            </li> -->

            <button @Click="closeMenu()"> {{uicontent.ui.close[language]}} </button>
        </ul>

        

    </nav>

</template>



<script>
import {useStore} from 'vuex'
import { useRouter } from 'vue-router'  // , useRoute


export default {
    props: {
        uicontent: Object,
        language: String
    },

    setup(props) {
        const store = useStore();
        const router = useRouter();

        const closeMenu = () => {
            console.log("closeMenu")
            store.state.menuModal = false;
        }

        const openRouterSite = (index) => {  //xxx externe funktion
            
            console.log("openRouterSite", index, props)
            const baseroute = props.uicontent.menuroutes[index]
            console.log("baseroute", baseroute)

            if (baseroute == "rundgangpage"){
                store.state.showRundgang = true
                store.state.roomId = 0;
                // router.push(baseroute).catch(()=>{});
                router.push({ name: baseroute, params: { roomId: ""}, props: true})
            } else {
                store.state.showRundgang = false
                router.push({ name: baseroute, params: { roomId: ""}, props: true})
                // router.push(baseroute).catch(()=>{});
            }
            // if (router.currentRoute.value.fullPath !== "home") 
            closeMenu();
            // console.log(router.currentRoute.value.fullPath);
        }


        return {
            closeMenu,
            openRouterSite,
            props
        }
    }

}
</script>



<style lang="scss" scoped>

.mobile-nav {
//   display: none;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  width: 100%;
  height: 100vh;
}

.mobile-nav__items {
  width: 90%;
  height: 100%;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-nav__item {
  margin: 1rem 0;
}

.mobile-nav__item a {
  font-size: 1.5rem;
}

button {
  background-color: #e4e4e4; 
  border-radius: 8px;
  border: none;
  color: rgb(0, 0, 0);
  margin: 0.4rem ;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
}

</style>