<template>

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" viewBox="0 0 624 798"
    preserveAspectRatio="xMidYMid meet" xml:space="preserve">
         
        <image x="0" y="0" width="624" height="798" :xlink:href="path"/>

        <g :visibility="showmarker">
            <template v-for="bibelstelle, index in bibelstellen" :key="index">
                 
                        <template v-if="bibelstelle[0].length > 4">
                            <polygon
                                :points="bibelstelle[0].toString().replace(',', ' ' )" 
                                style="fill:rgba(0,0,0);stroke-linejoin:round; stroke-width:5; opacity:0.25"
                            />
                        </template>
                        <template v-else>
                            <rect v-for="marker, index in bibelstelle" :key="index" 
                            :x="marker[0]"
                            :y="marker[1]"
                            :width="marker[2]- marker[0]"
                            :height="marker[3]-marker[1]" 
                            :rx="4"
                            style="fill:rgba(0,0,0,0.25)"
                        />
                        </template>
                    
                <rect
                    @click.self="onPlayAudioZitat(index)"
                    :x="bibelstelle[0][0]-13" 
                    :y="bibelstelle[0][1]-13" 
                    :width="22"
                    :height="22" 
                    :rx="12"
                    style="fill:rgb(255,255,255)"
                />
                <text :x="bibelstelle[0][0]-2" :y="bibelstelle[0][1]+3" class="small" @click.self="onPlayAudioZitat(index)">{{ index +1}}</text>
                
            </template>
        </g>

    </svg>

</template>


<script>
import { onMounted, ref  } from 'vue'; // ref, onMounted, computed
import useEmitter from '../composables/useEmitter'
// usage
// <bibel-svg :pageNum="pageNum" :json="sitejson" ></iris-svg>


export default {
    props: {
        path: String,
        showmarker: String,
        bibelstellen: Array,
        // onPlayAudioZitat: { type: Function },
    },
    setup() {  // props
        let radius = ref(200);
        let visible = ref("visible");
        const emitter = useEmitter();

        // let path = "/bibel/bibel-113-retouchiert.jpg"
        // let bibelstellen = ref( [   [  [84,615,399,32]  ],
        //                             [  [410,615,600,32], [84,649,600,32] ],
        //                             [  [80,715,600,32], [80,750,600,32] ]
        //                 ])
        onMounted(() => {
            // console.log(process.env.NODE_ENV, publicPath)
            // console.log("bibelstellen", props.bibelstellen);
            // console.log(props);
        });

        const onPlayAudioZitat = (value) => {
               // console.log("onPlayAudioZitat", value)    
               emitter.emit("AUDIO_ZITAT", value );
        }


        return {
            radius,
            visible,
            onPlayAudioZitat
            // path,
            // bibelstellen
        };
    },
    
}
</script>



<style lang="scss" scoped>
    .small {
        font-family: FontBold;
        font-size: 15px;
        text-anchor: middle;
    }

</style>