<template>
      
      <div class="flex-row-end" v-if="store.state.maxRoomPage>1"> 
                                   
                <div class="page-button-links" @click="prevRoomPage()">
                        <img src="../assets/svgs/pfeil-links.svg" alt="">
                </div>
                <!-- <transition name="fadeshow" appear mode="out-in" > --><!-- </transition> -->

                <p class="page-pagination"> {{ store.state.roomPageId +1}} / {{ store.state.maxRoomPage  }} </p>
                      
                <!-- <button  :style="currPage > maxPage ? '{ visibility: hidden }' : '{ visibility: visible }'" v-show="roomPage<maxRoomPage" > -->
                <div  class="page-button-rechts" @click="nextRoomPage()" >
                        <img src="../assets/svgs/pfeil-rechts.svg" alt="">
                </div>
                <!-- <transition name="fadeshow" appear mode="out-in"> --><!-- </transition> -->

    </div>
   
</template>



<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import store from "../store/global-store";



// settings des players
// https://github.com/sampotts/plyr#options
// https://www.npmjs.com/package/@altinget/vue-plyr


export default {

    // props: {
    //     headline: String,
    //     subline: String
    // },

    setup(){

        // const store = useStore();
        const router = useRouter();
        let store = useStore();
        // console.log("store", store);

        // const route = useRoute();

        // let maxRoomPage = 3; 

        const prevRoomPage = () => {
               
            if ( store.state.roomPageId > 0 ) {
                store.state.roomPageId = store.state.roomPageId -1
                router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId:  store.state.roomPageId}, props: true})   // , language: store.state.language
            } else {
                store.state.roomPageId = store.state.maxRoomPage -1;
                router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId: store.state.roomPageId}, props: true})   // , language: store.state.language
            }
            console.log("prevRoomPage", store.state.roomPageId )
        }

        const nextRoomPage = () => {
                
            if (store.state.roomPageId < store.state.maxRoomPage-1){
                store.state.roomPageId = store.state.roomPageId +1;
                router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
            } else {
                store.state.roomPageId = 0;
                router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
            }
            console.log("nextRoomPage", store.state.roomPageId)
        }

        return {
            nextRoomPage,
            prevRoomPage,
            store,
            // maxRoomPage
        }

    },

}
</script>



<style lang="scss" >
@import "../css/variables.scss";

svg {
    max-width: 95%;
    max-height: 100%;
}

.page-button-links {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     // background-color: #f80000;
     width:10%;
     height: 2rem;
}

.page-button-rechts {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     // align-content: right;
     // vertical-align: middle;
     // background-color: #f80000;
     width:10%;
     height: 2rem;
}

.page-pagination {
     vertical-align: middle;
     margin-bottom: 0.5rem;
     margin-top: 0.5rem;
     font-size: 14px;
}

.flex-row {
     display: flex;
     justify-content: center;
     align-items: center;
}

.flex-row-end {
     display: flex;
     justify-content:space-between; //  space-around;
     align-items: center;
     // background-color: #2c5777;
}


// .plyr {
//      --plyr-color-main: #464646;
// }

// .base-line{
// width: 100%;
// background-color: $softbackgroundcolor;
// height: 4px;
// padding-bottom: 0px;
// }
</style>