
import { createRouter, createWebHistory } from 'vue-router'
import ImpressumView from '../views/ImpressumViewWP.vue'
import FoerdererView from '../views/FoerderViewWP.vue'
// import ContactView from '../views/ContactView.vue'
import ContentView from '../views/ContentView.vue'
// import IntroView from '../views/IntroViewForLoop.vue'
import IntroView from '../views/IntroViewBibel.vue'
import HomeView from '../views/HomeView.vue'
// import SettingView from '../views/SettingsView.vue'
// import LanguageView from '../views/LanguageView.vue'


// import { useStore } from 'vuex'


// Vue.use(VueRouter);

// function hallo (){
//   console.log("hallo");
//   return store.getters.getAppStartPageType();
// }
function checkfalseUrl(to) {
  console.log("checkfalseUrl", to)
  // if (to.hash) return { path: to.path, query: to.query, hash: '' }
}

var routes = [
  // const
  // const
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/home",
    name: "home",
    component: HomeView
  },
  // {
  //   path: "/introPage",
  //   name: "intropage",
  //   component: IntroView
  // },
  {
    path: "/impressumPage",
    name: "impressumpage",
    component: ImpressumView
  },
  {
    path: "/foerdererPage",
    name: "foerdererpage",
    component: FoerdererView
  },
  {
    path: "/introPageCached",
    name: "intropagecached",
    component: IntroView
  },
  // {
  //   path: "/contentPage/:roomId/:pageId?", // /:pageId?
  //   name: "contentpage",
  //   component: ContentView
  // },
  // {
  //   path: "/contactPage",
  //   name: "contactpage",
  //   component: ContactView
  // },
  // {
  //   path: "/settingPage",
  //   name: "settingpage",
  //   component: SettingView
  // },
  // {
  //   path: "/languagePage",
  //   name: "languagepage",
  //   component: LanguageView
  // },
  {
    path: "/rundgangPage/:roomId/:pageId?", // 
    // path: "/rundgangPage/:roomId/:pageId?", // 
    name: "rundgangpage",
    component: ContentView // IntroView
  },
  {
    path: "/:catchAll(.*)",
    name: "home",
    component: HomeView,
    beforeEnter: checkfalseUrl
    // (to, from) => {
    //   // reject the navigation
    //   return false
    // },
  },
  // {
  //   path: "/scannerPage",
  //   name: "scannerpage",
  //   component: ScannerView
  // } 
];

// mode: 'hash',
// base: process.env.BASE_URL,

const router = createRouter({
  //history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // https://stackoverflow.com/questions/71196755/linking-router-link-and-scrollbehavior-not-working-vue-3-and-ionic-6
  // eslint-disable-next-line no-unused-vars
  scrollBehavior: (to, from, savedPosition) => {
    console.log("scrollBehavior", window.location.href)
    // window.scrollTo(0, 0);
    if (to.hash) {
      console.log("scrollBehavior", window.location.href)
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
    return { x: 0, y: 0 }
  },

  // eslint-disable-next-line no-unused-vars
  // beforeEach :(to, from) => {
    // if(to.name === "Home" && !to.query.hasOwnProperty("pageId")){
    //     to.query.pageId = "7"
    // }
    
    // console.log("to", to)
    // if(!to.query.hasOwnProperty("pageId")){
    //   to.query.pageId = "7"
    // }
  
  // }
})


// let store = useStore()

// nicht hier initialisisert, der store ist noch nicht verfügbar
// // eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from) => {
  
//   console.log("to", to.params )
//   // Object.prototype.hasOwnProperty.call(object, "objectProperty");
//   if(!Object.prototype.hasOwnProperty.call(to.params, "pageId") ) {
//     to.params.pageId = 1;
//   } else {
//     console.log("EXXXTERN PageID", to.params.pageId)
//     // console.log(store)
//     store.state.roomPageId = to.params.pageId;
//   }
//   console.log("to", to)

// })


export default router