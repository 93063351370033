<template>
    <!-- https://codepen.io/wa23/pen/BrGzdd -->
    
    <div>

        <div class="vue-select" @click="toggleDropdown">
            
            <span class="selected-option">{{ store.state.contentUi.ui.waehlemenu[store.state.language] }}
                <!-- <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z"></path>
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="14.121" height="7.811" viewBox="0 0 14.121 7.811">
                      <g id="box-arrow-up" transform="translate(1.061 1.061)">
                          <path id="Pfad_110" data-name="Pfad 110" d="M10,8l6,6,6-6" transform="translate(-10 -8)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      </g>
                </svg>
            </span>

            <transition name="slide">
              <div class="dropdown-options-container" v-show="showDropdown">
                  <div class="dropdown-options" v-for="option,index in options" v-bind:class="{'selected': option === selected }" :key="index">
                      <div class="dropdown-options--cell" @click="selectOption(option, index)">
                          <span class="option-text">{{ option }}</span>
                      </div>
                  </div>
              </div>
            </transition>
            
        </div> 

    </div>

</template>



<script>
import { watch } from "vue";
import { useStore } from 'vuex'

export default {
    
    props: {
        // options: {
        //     defaut: [   "Angular",
        //                 "React",
        //                 "Vue",
        //                 "Backbone",
        //                 "Polymer",
        //                 "Knockout",
        //                 "Batman",
        //                 "A really long option A really long option A really long option A really long option A really long option"],
        //     type: Array,
        //     }, 
        // placeholder: {
        //     default: "Select from the dropdown",
        //     type: String
        // }
        options: Array,
        externindex: Number
    },

    setup(props) {
          // watch(props.externindex, (currentValue, oldValue) => {
          //   console.log(currentValue);
          //   console.log(oldValue);
          // });
        // let selected = "select an option";
        // let showDropdown = false;
        // let placeholder = "Select from the dropdown";

        // const selectOptionExtern = (index) => {
        //     console.log("selectOptionExtern")
        //     selected = props.options[index-1];
        //     placeholder = props.options[index-1];
        // }
        let store = useStore()

      // inform parent (the form) of a selection so the model can be updated.
      // this.$emit('interface', index)
     

          // Watch prop value change and assign to value 'selected' Ref
          watch(() => props.externindex, (first, second) => {
              console.log("Watch props.selected function called with args:", first, second );
              // selectOptionExtern(first)
              // this.selected    = this.dropDownOptions[index-1];
              // this.placeholder = this.dropDownOptions[index-1]; 
          });

          return{
            store
          //   selected,
          //   showDropdown,
          //   placeholder
          }
    },
    
    data() {
        return {
            selected: "select an option",
            showDropdown: false,
            // placeholder:  "Wähle aus dem Menü",

        }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option, index) {
      //this.selected = option;
      // this.placeholder = option;
      // inform parent (the form) of a selection so the model can be updated.
      this.$emit('interface', index)
    },
    // selectOptionExtern(index) {
    //   this.selected = this.options[index-1];
    //   this.placeholder = this.options[index-1];
    //   // inform parent (the form) of a selection so the model can be updated.
    //   // this.$emit('interface', index)
    // },
  },
 
}
</script>


<style lang="scss" scoped>
$vue-navy: #0408fc00;
$vue-navy-light: #ef07f7;
$vue-teal:  #94a78f;
// $vue-teal-light: #fffb00;
$light-gray: #fc0000;

@mixin active-state() {
  background-color: #94a78f00;
  border: none;
}

@mixin ellipsis() {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

body {
  background: $light-gray;
  color: $vue-navy;
  font-family: 'Source Sans Pro', sans-serif;
  min-height: 1000px;
}

h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: $vue-teal;
}

p {
  color: #ffffff;
  text-align: left;
  margin-top: 0px;
  margin-left: 0px;
}

.vue-select {
  width: 100%;
  // height: 30px;
  background-color: rgb(0, 0, 0);
  margin: 2px auto 2px auto;
  cursor: pointer;
  user-select: none;
  // box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid;
  border-color: $vue-teal;
  transition: all 200ms linear;
  font-family: FontSerifItalic;
  color: rgb(253, 253, 253);
  font-size: 16px;
  line-height: 24px;

  // z-index: 510;

  .selected-option {
    @include ellipsis();
    display: inline-block;
    color: white;
    padding: 1px 50px 1px 15px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    transition: all 200ms linear;
    
    &:hover {
      color: rgba(255, 255, 255, 0.7);
      // color: rgba(248, 0, 0, 0.45);
    }
      
    svg {
      fill: $vue-teal;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      transition: fill 300ms linear;
      // &:hover {
      //   fill: darken($vue-teal, 100%);
      // }
    }
  }
}

.dropdown-options-container {
  overflow-y: scroll;
  height: 150px;
  // border: 1px solid;
  border-color: $vue-teal;
}

.dropdown-options--cell {
  padding: 10px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  height: 30px;
  &:hover { 
    @include active-state();
  }
}
.dropdown-options.selected {
  .dropdown-options--cell {
    @include active-state(); 
  }
}

// select:focus{
//     border-color: gray;
//     outline:none;
// }


// select option:hover,
//     select option:focus,
//     select option:active {
//         background: linear-gradient(#000000, #000000);
//         background-color: #000000 !important; /* for IE */
//         color: #ffed00 !important;
//     }

//     select option:checked {
//         background: linear-gradient(#d6d6d6, #d6d6d6);
//         background-color: #d6d6d6 !important; /* for IE */
//         color: #000000 !important;
//     }


// select:active, select:hover {
//   outline: none
// }
// select option:hover{   box-shadow: 0 0 10px 10px #e1358f inset; }

.slide-enter-active, .slide-leave-active  {
  transition: height 250ms ease;
}

.slide-enter, .slide-leave-to {
  height: 0px;
}
</style>