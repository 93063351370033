<template>
  
<ul>
    <li v-for="markerText, index in content" :key="index" >
        <input type="checkbox"  v-if="index==0">
        <input type="checkbox" checked v-else-if="index!=0">
        <i></i>
        <!-- <h2> {{ index +1 }} {{ store.state.contentUi.ui.bibelstelle[language]}}</h2>    -->
        <h2  :id="'bs'+index" :class="store.state.typoSwitch ? 'larger':'leer'" > {{ index +1 }} {{ markerText['stelle_' + language].raw }}</h2>   
        <!-- {{ markerText[language][0] }} -->
        <!-- <p v-html="markerText.la[1] +'<br>'  + markerText[language][1]"></p> -->
        
        <!-- <p v-html="markerText.zit_la.raw +'<br>'  + markerText['zit_' + language].raw"></p> -->
        <!-- <template> -->
          
        <!-- <p> zitat {{ '/mp3/' + markerText.zitatbasename.raw + '_' + language + '.mp3' }}</p>   -->

        <div class="flex-vert">
        <!-- <div class="flex-space"></div>-->

            <div class="flex-audio" v-if="markerText['zitaudio_' + language].raw==1 && store.state.contentUi.noaudio==false" :key="store.state.language">
              <vue-plyr :ref="'vplyraudioaccordeon'+index" :options="optionsAudio" >
                  <audio >
                      <source :src="'/mp3/' + markerText.zitatbasename.raw + '_' + language + '.mp3'" type="audio/mp3" />
                      <source :src="'/mp3/' + markerText.zitatbasename.raw + '_' + language + '.ogg'" type="audio/ogg" />
                  </audio>
              </vue-plyr>
            </div> 


            <div class="flex-horz" :class="store.state.typoSwitch ? 'large':'leer'" >
              <!-- <div class="flex-item"> -->
                <p class="flex-item" v-html="supsup(markerText.zit_la.raw)"></p>
              <!-- </div> -->
              <!-- <div class="flex-item"> -->
                <!-- <p class="flex-item" v-html="markerText['zit_' + language].raw"></p> -->
                <!-- :options="optionsAudio" @play="vPlayCheck('audio2')" -->
                <div class="flex-item" :class="store.state.typoSwitch ? 'large':'leer'">
                  
                  <!-- <vue-plyr ref="vplyraudio2" :options="optionsAudio" v-if="markerText['zitaudio_' + language].raw==1" >
                      <audio >
                          <source :src="'/mp3/' + markerText.zitatbasename.raw + '_' + language + '.mp3'" type="audio/mp3" />
                          <source :src="'/mp3/' +  markerText.zitatbasename.raw + '_' + language + '.ogg'" type="audio/ogg" />
                      </audio>
                  </vue-plyr> -->
                 
                  <p v-html="supsup(markerText['zit_' + language].raw)"></p>
              </div>
              <!-- </div> -->
            </div>
        
      </div>

        
      <!-- </template> -->
    </li>
    <!-- <li>
        <input type="checkbox" checked>
        <i></i>
        <h2>2 How it Works</h2>
        <p>Using the sibling and checked selectors, we can determine the styling of sibling elements based on the checked state of the checkbox input element. One use, as demonstrated here, is an entirely CSS and HTML accordion element. Media queries are used to make the element responsive to different screen sizes.</p>
    </li>
    <li>
        <input type="checkbox" checked>
        <i></i>
        <h2>3 Points of Interest</h2>
        <p>By making the open state default for when :checked isn't detected, we can make this system accessable for browsers that don't recognize :checked. The fallback is simply an open accordion. The accordion can be manipulated with Javascript (if needed) by changing the "checked" property of the input element.</p>
    </li> -->
</ul>

</template>



<script>
import { useStore } from 'vuex'

export default {
 
  props: {
    content: Object,
    // contentui: Object,
    language: String
  },

  setup() {
    // console.log("accordion", props.content);
    const store = useStore();

    const supsup = (msg) => {
       // console.log(msg)
       // return msg.replace(/\d+\s+/g, function(a){ return '<strong>' + a + '</strong>'; })  findet nur zahlen gefolgt von whitespace
       return msg.replace(/\d+/g, function(a){ return '<strong>' + a + '</strong>'; })
    }
    //

    const optionsAudio = {     
                  hideControls: false,
                  settings: [], 
                  captions: { active: true, language: 'de', update: true }
               }

    return {
      store,
      optionsAudio,
      supsup,
    }
  },
}
</script>



<style lang="scss" scoped>

.transition, ul li i:before, ul li i:after, div {  // p für div
  transition: all 0.25s ease-in-out;
}

.flipIn, ul li, h1 {
  animation: flipdown 0.5s ease both;
}

.no-select, h2 {
  -webkit-tap-highlight-color: none; //rgba(255, 6, 6, 1)
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// select:active, select:hover {
//   outline: none
// }


// .flex-vert{
//   display: flex;
//   min-height: 2em;
//   // justify-content: center;
//   flex-direction: column;
//   z-index: 2;
//   transform: translate(0, 0);
// }

.flex-space {
  margin-top: 1rem;
  min-height: 1rem;
}
.flex-audio {
  margin-top: 1rem;
  min-height: 1rem;
}

.flex-vert {
  display: flex;
  min-height: 2em;
  justify-content: center;
  flex-direction: column;
  // background-color: orange;

  font-family: FontLight;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  position: relative;
  overflow: hidden;
  // max-height: 800px;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 14px;
  margin-bottom: 10px;
  z-index: 2;
}

.flex-horz {
  display: flex;
  min-height: 2em;
  justify-content: center;
  flex-direction: row;
  // background-color: orange;

  font-family: FontLight;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  position: relative;
  overflow: hidden;
  // max-height: 800px;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 14px;
  margin-bottom: 10px;
  z-index: 2;
}

.flex-item {
  width: 46%; 
  margin-left: auto; 
  margin-right: auto;
  // background-color: greenyellow;
}


// html {
//   width: 100%;
//   height: 100%;
//   perspective: 900;
//   overflow-y: scroll;
//   background-color: #dce7eb;
//   font-family: "Titillium Web", sans-serif;
//   color: rgba(48, 69, 92, 0.8);
// }

// body {
//   min-height: 0;
//   display: inline-block;
//   position: relative;
//   left: 50%;
//   margin: 90px 0;
//   transform: translate(-50%, 0);
//   // box-shadow: 0 10px 0 0 #ff6873 inset;
//   background-color: #fefffa;
//   max-width: 450px;
//   padding: 30px;
// }

@media (max-width: 550px) {
  body {
    box-sizing: border-box;
    transform: translate(0, 0);
    max-width: 100%;
    min-height: 100%;
    margin: 0;
    left: 0;
  }
}



h2 {
  color: #ffffff;
 
}

// h1 {
//   text-transform: uppercase;
//   font-size: 36px;
//   line-height: 42px;
//   letter-spacing: 3px;
//   font-weight: 100;
// }
.inverted{
  color: rgb(240, 4, 248);
}

h2 {
  font-family: FontSemiBold;
  font-size: 16px;
  line-height: 24px;
  // font-weight: 300;
  letter-spacing: 1px;
  display: block;
  // background-color: #fefffa;
  margin: 0;
  cursor: pointer;
}

p {
  font-family: FontLight;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
// 576px für Porträt-Handys
// 768px für Tablets
// 992px für Laptops
// 1200px für große Geräte
@media (max-width: 576px){
  h2 {
    // font-family: FontSemiBold;
    font-size: 14px;
    line-height: 20px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
}



ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
  // border-bottom: 1px solid #ffffff;
}
ul li {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 4px;
  padding-top: 18px;
  // border-top: 1px solid #ffffff;
  // border-bottom: 1px solid #ffffff;
}

li {
  border-bottom: 1.5px solid #8d8d8d;
  // padding-bottom: 20px;
}

ul li:nth-of-type(1) {
  animation-delay: 0.25s;
}
ul li:nth-of-type(2) {
  animation-delay: 0.5s;
}
ul li:nth-of-type(3) {
  animation-delay: 0.75s;
}
ul li:nth-of-type(4) {
  animation-delay: 1s;
}
ul li:nth-of-type(5) {
  animation-delay: 1.25s;
}
ul li:nth-of-type(6) {
  animation-delay: 1.25s;
}
ul li:nth-of-type(7) {
  animation-delay: 1.25s;
}
ul li:nth-of-type(8) {
  animation-delay: 1.25s;
}
ul li:nth-of-type(9) {
  animation-delay: 1.25s;
}
ul li:nth-of-type(10) {
  animation-delay: 1.25s;
}
ul li:nth-of-type(11) {
  animation-delay: 1.25s;
}
ul li:nth-of-type(12) {
  animation-delay: 1.25s;
}

ul li:last-of-type {
  padding-bottom: 0;
}
ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 16px;
  right: 0;
}
ul li i:before, ul li i:after {
  content: "";
  position: absolute;
  background-color: #ffffff;
  width: 1px;
  height: 7px;
}
ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}
ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}
ul li input[type=checkbox] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
ul li input[type=checkbox]:checked ~ div {  // p
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}
ul li input[type=checkbox]:checked ~ i:before {
  transform: translate(2px, 0) rotate(45deg);
}
ul li input[type=checkbox]:checked ~ i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}

</style>

