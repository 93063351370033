<template>
     <div class="base-container" >
          
          <transition name="fadepage" appear mode="out-in">
               <div class="base-c1" :class="store.state.typoSwitch ? 'larger':'normal'" v-if="content!=''" style="white-space: pre;" v-html="filterBR(content['toolset-meta'].mlpost['posttext_' + store.state.language].raw)" :key="store.state.language">
                   
               </div>
          </transition>

          <!-- <img src="images/bluelemon_logo.jpg" alt=""> -->

     </div>
         
</template>



<script>
import { ref, onMounted} from 'vue'; // ref, , computed
import { useStore } from 'vuex'

// import { AmbientLight, Camera, GltfModel, Renderer, Scene, } from 'troisjs';
// import { AmbientLight, Camera,  Renderer, Scene, } from 'troisjs';


export default {
     // inheritAttrs: false,
     // props: {
     //      contentui:Object,
     //      content: Object,
     //      contentwp: Object,
     //      zitate: Array,
     //      language: String,
     // },

     setup() {
          const store = useStore();
          let apiURL = 'https://app.museum-abtei-liesborn.de/wp-json/wp/v2/posts/200';
          const content = ref('')

          if (store.state.contentUi.noaudio==true){
               apiURL = window.location.origin + '/' + 'foerderer.json';
               console.log("apiURL", apiURL);
          }

          const filterBR = (xxx) => {
               // console.log(xxx);
               // return "<H1>hallo</H1>"
               // return xxx.replace('<br />','<br>' );
               let yyy = xxx.replace(/(?:\r\n|\r|\n)/g, '');
               // console.log(yyy)
               return  yyy
               // return "<H1>hallo</H1>"
          }


          onMounted(() => {
               fetch(apiURL, { method: 'get', headers: { 'content-type': 'application/json' } } )
                    .then(res => {
                         if (!res.ok) {
                              // console.log("loaded NOOK")
                              // create error instance with HTTP status text
                              const error = new Error(res.statusText);
                              error.json = res.json();
                              throw error;
                         }
                         return res.json();
                    })
                    .then(json => {
                         // console.log("content")
                         console.log("json", json)
                         content.value = json;
                         console.log(content.value['toolset-meta'].mlpost['posttext_' + store.state.language].raw)
                    })
                    .catch(err => {
                         console.log("error", err)
                         const error = new Error();
                         error.value = err;
                         if (err.json) {
                              return err.json.then(json => {
                              error.value.message = json.message;
                              });
                         }
                         })
                    .then(() => {
                         // contentLoaded.value = true;
                    });
          })

          return {
               content,
               store,
               filterBR

          }

     },
     // components: {
     //      AmbientLight,
     //      Camera,
     //      GltfModel,
     //      Renderer,
     //      Scene,
     // },
     methods: {
     //    onReady(model){  // 
     //      console.log("hallo", model)
     //      model.scene.castShadow = true;
     //      model.scene.children[0].castShadow = true;
     //      // model.geometry.computeVertexNormals(); 

     //      // model.traverse(n => { if ( n.isMesh ) {
     //      //      n.castShadow = true; 
     //      //      n.receiveShadow = true;
     //      //      if(n.material.map) n.material.map.anisotropy = 16; 
     //      //      }});

     //      //   model.traverse(o => {
     //      //       if (o.isMesh){
     //      //           // handle both multiple and single materials
     //      //           const asArray = Array.isArray(o.material) ? o.material : [o.material]
     //      //           // 0 works for matte materials - change as needed
     //      //           asArray.forEach(mat => mat.metalness = 0)
     //      //       }
     //      //   })
     //    }
     },
     
}
</script>



<style lang="scss">

ul {
     color: white;
     line-height: 12px;
     width: 100%;
     display: block;
}

li {
     color: white;
     line-height: 18px;
     // width: 100%;
     // display: block;
     white-space:normal;
     // word-wrap: break-word;
}

.normal p {
 
}

.larger p {
     font-size: 20px;
     line-height: 28px;
}

.size-full {
     width: 100%;
     height: auto;
}

</style>