<template>
     <div class="base-container" >
          
          <transition name="fadepage" appear mode="out-in">
          <div class="base-c1" :class="store.state.typoSwitch ? 'larger':'normal'" v-if="content!=''" style="white-space: pre;" v-html="filterBR(content['toolset-meta'].mlpost['posttext_' + store.state.language].raw)" :key="store.state.language">

          </div>
          </transition>

          <!-- <div class="base-c1">

               
               <h1>Impressum</h1>
               
               <p><b>Herausgeber:</b><br>
                    Museum Abtei Liesborn des Kreises Warendorf<br>
                    Abteiring 8<br>
                    D-59329 Wadersloh<br>
                    Tel. 02523 – 9 82 40<br>
                    Fax 02523 – 98 24 11<br>
                    E-Mail: info@museum-abtei-liesborn.de<br>
               </p>

               <p>
                    <b>Träger:</b><br>
                    Kreis Warendorf<br>
                    Der Landrat<br>
                    Waldenburger Straße 2<br>
                    D-48231 Warendorf<br>
                    Tel. 02581 – 53 0<br>
                    Fax 02581 – 53 5199<br>
                    E-Mail: verwaltung@kreis-warendorf.de<br>
                    Der Kreis Warendorf ist eine Körperschaft des öffentlichen Rechts. Er wird vertreten durch den Landrat Dr. Olaf Gericke.<br>
                    Umsatzsteuer-ID gemäß Umsatzsteuergesetz: DE 126731464<br>
                    
               </p>

               <p>
                    Datenschutzbeauftragte: Jessica Wozniak<br>
                    Waldenburger Straße 2<br>
                    D-48231 Warendorf<br>
                    Tel. 02581 – 53 1630<br>
                    <a href="mailto:datenschutz@kreis-warendorf.de"></a>datenschutz@kreis-warendorf.de<br>
               </p>

               <p>
                    <b>Museumsleiter:</b><br>
                    PD Dr. Sebastian Steinbach<br>
                    Stellvertretende Museumsleiterin: Dr. Jutta Desel<br>
                    Inhaltliche Verantwortung<br>
                    Museum Abtei Liesborn<br>
                    Urheberrecht und Haftung<br><br>
                    Das Layout des Media-Guides, die verwendeten Grafiken sowie die Sammlung der Beiträge sind urheberrechtlich geschützt. Die Seiten dürfen nur zum privaten Gebrauch vervielfältigt, Änderungen nicht vorgenommen und Vervielfältigungsstücke weder verbreitet noch zu öffentlichen Wiedergaben benutzt werden. In keinem Fall wird für Schäden, die sich aus der Verwendung der abgerufenen Informationen ergeben, eine Haftung übernommen.
                    Die Seiten unterliegen dem Copyright des Museums Abtei Liesborn.<br>
               </p>

               <p>    
                    <b>Programmierung, Realisierung und Technik:</b><br>
                    bluelemon Interactive OHG<br>
                    Grüner Weg 16<br>
                    51503 Rösrath<br>
                    <a href="https://www.bluelemon.de"> www.bluelemon.de</a>
               </p>

               <p><b>Kontakt:</b><br>
                    Museum Abtei Liesborn des Kreises Warendorf<br>
                    Abteiring 8<br>
                    D-59329 Wadersloh<br>
                    Tel. 02523 – 9 82 40<br>
                    Fax 02523 – 98 24 11<br>
                    E-Mail: <a href="mailto:info@museum-abtei-liesborn.de"></a>  info@museum-abtei-liesborn.de
               </p>

               <p>
                    <b>Öffnungszeiten:</b><br>
                    Di – So von 10 – 18 Uhr<br>
                    Montags und an allen Feiertagen geschlossen<br>
                    <br>
                    Das Museum ist barrierefrei
               </p>
     
          </div> -->

     </div>
         
</template>



<script>
import { ref, onMounted} from 'vue'; // ref, , computed
import { useStore } from 'vuex'

// import { AmbientLight, Camera, GltfModel, Renderer, Scene, } from 'troisjs';
// import { AmbientLight, Camera,  Renderer, Scene, } from 'troisjs';


export default {
     // inheritAttrs: false,
     // props: {
     //      contentui:Object,
     //      content: Object,
     //      contentwp: Object,
     //      zitate: Array,
     //      language: String,
     // },

     setup() {
          const store = useStore();
          let apiURL = 'https://app.museum-abtei-liesborn.de/wp-json/wp/v2/posts/175';
          const content = ref('')

          if (store.state.contentUi.noaudio==true){
               apiURL = window.location.origin + '/' + 'impressum.json';
               console.log("apiURL", apiURL);
          }


          const filterBR = (xxx) => {
               // console.log(xxx);
               // return "<H1>hallo</H1>"
               // return xxx.replace('<br />','<br>' );
               let yyy = xxx.replace(/(?:\r\n|\r|\n)/g, '');
               // console.log(yyy)
               return  yyy
               // return "<H1>hallo</H1>"
          }


          onMounted(() => {
               fetch(apiURL, { method: 'get', headers: { 'content-type': 'application/json' } } )
                    .then(res => {
                         if (!res.ok) {
                              // console.log("loaded NOOK")
                              // create error instance with HTTP status text
                              const error = new Error(res.statusText);
                              error.json = res.json();
                              throw error;
                         }
                         return res.json();
                    })
                    .then(json => {
                         // console.log("content")
                         console.log("json", json)
                         content.value = json;
                         console.log(content.value['toolset-meta'].mlpost['posttext_' + store.state.language].raw)
                    })
                    .catch(err => {
                         console.log("error", err)
                         const error = new Error();
                         error.value = err;
                         if (err.json) {
                              return err.json.then(json => {
                              error.value.message = json.message;
                              });
                         }
                         })
                    .then(() => {
                         // contentLoaded.value = true;
                    });
          })

          return {
               content,
               store,
               filterBR

          }

     },
     // components: {
     //      AmbientLight,
     //      Camera,
     //      GltfModel,
     //      Renderer,
     //      Scene,
     // },
     methods: {
     //    onReady(model){  // 
     //      console.log("hallo", model)
     //      model.scene.castShadow = true;
     //      model.scene.children[0].castShadow = true;
     //      // model.geometry.computeVertexNormals(); 

     //      // model.traverse(n => { if ( n.isMesh ) {
     //      //      n.castShadow = true; 
     //      //      n.receiveShadow = true;
     //      //      if(n.material.map) n.material.map.anisotropy = 16; 
     //      //      }});

     //      //   model.traverse(o => {
     //      //       if (o.isMesh){
     //      //           // handle both multiple and single materials
     //      //           const asArray = Array.isArray(o.material) ? o.material : [o.material]
     //      //           // 0 works for matte materials - change as needed
     //      //           asArray.forEach(mat => mat.metalness = 0)
     //      //       }
     //      //   })
     //    }
     },
     
}
</script>



<style lang="scss">

.normal p {

}

.larger p {
     font-size: 20px;
     line-height: 28px;
}

</style>