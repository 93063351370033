<template>
     
     <div class="intro-container">
       <div class="c1">
        <!-- <h1>Loading {{ loaded }} </h1> -->
        <div class="overlay-icon"></div>
        <!-- <Renderer ref="renderer">
            <Camera :position="{ z: 10 }" />
            <Scene>
                <PointLight :position="{ y: 50, z: 50 }" />
                <Box ref="box" :rotation="{ y: Math.PI / 4, z: Math.PI / 4 }">
                <LambertMaterial />
                </Box>
            </Scene>
      </Renderer> -->
        
        <div class="bgbibel" style="{display: inline; height: 500px; width: 100%; max-height: calc(100vh - 200px);}" >
        <!-- <div class="bgbibel bibel3d" > -->
          
            
            <!-- <Renderer ref="renderer" antialias :alpha="true" :orbit-ctrl="{ enableDamping: true }" shadow resize > -->
            <Renderer ref="renderer" antialias :alpha="true" :orbit-ctrl="{ enableDamping: true,  maxDistance: 6, minDistance: 2.5, screenSpacePanning: true }" shadow resize >
                <Camera ref="camera" :position="{ x: 0, y: 0, z: 5 }" />
                <Scene ref="scene" background="#fff" >
                  <!-- sky-color="white"  cast-shadow  height="500px" cast-shadow :shadow-map-size="{ width: 1024, height: 1024 }" height="500px"  -->
                    <HemisphereLight :intensity="1.0" cast-shadow ground-color="white"></HemisphereLight> 
                    <AmbientLight :intensity="0.8"></AmbientLight>
                    <PointLight color="#ffffff" :intensity="2.2" :position="{ x: 0, y: 0, z: 8 }" cast-shadow :shadow-map-size="{ width: 1024, height: 1024 }" />
                    <GltfModel src="/export-bibel-1/bibel.glb" @load="onReady"  @progress="onProgress" @error="onError" :rotation="{ x: 0, z: 0 }"/>
                    <!-- <Box :size="2" :rotation="{ x: 1, z: 1 }" cast-shadow>
                      <LambertMaterial />
                    </Box> -->
                  
                    <!-- <Plane :width="200" :height="200" :position="{z: -50 }" receive-shadow>
                          <LambertMaterial color="#555555" />
                    </Plane> -->
                </Scene>
                
            </Renderer>
           
        </div>
        
       </div>
       
    </div>
    
</template>

<script>
// https://glb-packer.glitch.me/  packt den kram zusammen


import { AmbientLight, Camera, GltfModel, Renderer, Scene } from 'troisjs';
import { MathUtils } from 'three';

export default {
  inheritAttrs: false,
  setup() {
    // const SIZE = 1.6, NX = 25, NY = 25, PADDING = 1;
    // const SIZEP = SIZE + PADDING;
    // const W = NX * SIZEP - PADDING;
    // const H = NY * SIZEP - PADDING;
    // return {
    //   SIZE, NX, NY, PADDING,
    //   SIZEP, W, H,
    //   NUM_INSTANCES: NX * NY,
    // };
  },
  data() {
    return {
      // tiltRadius: 100,
      // tiltY: 100,
      camera: "",
      fov: 50,
      planeAspectRatio: 1 / 1,
      loaded: "",
    };
  },
  components: { 
      AmbientLight,
      Camera,
      GltfModel,
      Renderer,
      Scene
  },
  mounted() {

    this.camera = this.$refs.camera.camera;
    this.renderer = this.$refs.renderer;
    // this.size = this.renderer.three.size;
    // this.pointer = this.renderer.three.pointer;
    // this.imesh = this.$refs.imesh.mesh;

    // // init color attribute
    // const colors = [];
    // for (let i = 0; i < this.NUM_INSTANCES; i++) {
    //   // const color = new Color(cscale(Math.random()).hex());
    //   // colors.push(color.r, color.g, color.b);
    //   const c = Math.random();
    //   colors.push(c, c, c);
    // }
    // this.imesh.geometry.setAttribute('color', new InstancedBufferAttribute(new Float32Array(colors), 3));

    // this.tiltRadius = this.size.height / 3;
    // this.tiltY = this.size.height / 2;
    // this.renderer.onAfterResize(this.updateTilt);

    // this.dummy = new Object3D();
    // this.renderer.onBeforeRender(this.animate);

    setTimeout(() => {
        window.addEventListener('resize', this.resize,{passive:true})	;
        
        // window.addEventListener('resize', () => {	
        //   console.log(window.innerWidth, window.innerHeight);
        //   this.camera.aspect = window.innerWidth / window.innerHeight;
          
        //   if (this.camera.aspect < this.planeAspectRatio) {
        //     console.log("window to large")
        //     // window too large
        //     const cameraHeight = Math.tan(MathUtils.degToRad(this.fov / 2));
        //     const ratio = this.camera.aspect / this.planeAspectRatio;
        //     const newCameraHeight = cameraHeight / ratio;
        //     //this.tfov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
        //     this.camera.fov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
        //     // this.$refs.renderer.three.setSize(200, 200)
        //     // this.$refs.renderer.three.setSize(window.innerWidth -40, window.innerHeight -40)
        //   } else {
        //   //   // window too narrow
        //     // console.log("window to narrow")
        //     this.camera.fov = this.fov;
        //   //  this.tfov = this.fov
        //    }
        // })

        // this.renderer.onBeforeRender( this.animate);
        this.resize();

        //  renderer.onBeforeRender(() => {
        //       const t = Date.now();
        //       let mesh, ti;    
        //       for (let i = 1; i <= this.n; i++) {
        //            // console.log(this.$refs);
        //            // console.log (this.$refs["mesh"+i]);
        //            // console.log (this.$refs["mesh"+i][0]);
        //            if (this.$refs["mesh"+i][0].mesh != null){
        //                 mesh = this.$refs["mesh"+i][0].mesh;
        //                 ti = (t - i * 500);
        //                 mesh.rotation.x = ti * 0.00015;
        //                 mesh.rotation.y = ti * 0.00020;
        //                 mesh.rotation.z = ti * 0.00017;
        //            } 
        //       }
        //  });
    }, 3);


  },
  methods: {
    resize (){	
      // console.log(window.innerWidth, window.innerHeight);
      this.camera.aspect = window.innerWidth / window.innerHeight;
      
      if (this.camera.aspect < this.planeAspectRatio) {
        // console.log("window to large")
        const cameraHeight = Math.tan(MathUtils.degToRad(this.fov / 2));
        const ratio = this.camera.aspect / this.planeAspectRatio;
        const newCameraHeight = cameraHeight / ratio;
        //this.tfov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
        this.camera.fov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
        
        // this.$refs.renderer.three.setSize(200, 200)
        // this.$refs.renderer.three.setSize(window.innerWidth -40, window.innerHeight -40)
      } else {
        // console.log("window to narrow")
        this.camera.fov = this.fov;
        }
    },
    onError (e){
      console.log("onError", e)
      this.loaded = ""
    },
    onProgress(e){
      // console.log("onProgress", e)
      this.loaded = Math.floor( e.loaded / e.total * 100 )
      // this.loaded = ""
    },
    onReady(model){  // 
        // console.log("hallo", model)
        // console.log(model.scene)
        this.resize();
        model.scene.castShadow = true;
        model.scene.children[0].castShadow = true;
        this.loaded = ""
        //  model.setClearColorHex( 0xffffff, 1 )
        // console.log(this.$refs.scene)
        this.$refs.scene.scene.background = null
      // this.$refs.scene.setClearColor( 0xffffff, 1 );
        // this.$refs.renderer.three.setClearColorHex( 0xffffff, 1 );
          // model.geometry.computeVertexNormals(); 

          // model.traverse(n => { if ( n.isMesh ) {
          //      n.castShadow = true; 
          //      n.receiveShadow = true;
          //      if(n.material.map) n.material.map.anisotropy = 16; 
          //      }});

          //   model.traverse(o => {
          //       if (o.isMesh){
          //           // handle both multiple and single materials
          //           const asArray = Array.isArray(o.material) ? o.material : [o.material]
          //           // 0 works for matte materials - change as needed
          //           asArray.forEach(mat => mat.metalness = 0)
          //       }
          //   })
        },
    // animate() {
    //   this.updateInstanceMatrix();
    // },
    // updateTilt() {
    //   this.tiltRadius = this.size.height / 3;
    //   this.tiltY = (this.pointer.positionN.y + 1) * 0.5 * this.size.height;
    // },
    // updateInstanceMatrix() {
    //   const x0 = -this.W / 2 + this.PADDING;
    //   const y0 = -this.H / 2 + this.PADDING;
    //   const time = Date.now() * 0.0001;
    //   const noise = 0.005;
    //   let x, y, nx, ny, rx, ry;
    //   for (let i = 0; i < this.NX; i++) {
    //     for (let j = 0; j < this.NY; j++) {
    //       x = x0 + i * this.SIZEP;
    //       y = y0 + j * this.SIZEP;
    //       nx = x * noise;
    //       ny = y * noise;
    //       rx = simplex.noise3D(nx, ny, time) * Math.PI;
    //       ry = simplex.noise3D(ny, nx, time) * Math.PI;
    //       this.dummy.position.set(x, y, -10);
    //       this.dummy.rotation.set(rx, ry, 0);
    //       this.dummy.updateMatrix();
    //       this.imesh.setMatrixAt(i * this.NY + j, this.dummy.matrix);
    //     }
    //   }
    //   this.imesh.instanceMatrix.needsUpdate = true;
    // },
  },
}
</script>


<style lang="scss" scoped>
.bgbibel {
    background: radial-gradient(#fff 20%, #b9b9b9 100%);
}

.bibel3d {
  display:inline; 
  height: 500px; 
  width: 100%;
  // height: calc(100vh - 100px);
  // max-height: calc(100vh - 100px);
  // box-sizing: border-box;
}

.c1 {
	// background: blue;
  // height: 300px;
  position: relative;
}

.overlay-icon {
  position: absolute;
  width: 29px;
	height: 12px;
  // background: green;
  background-image: url("../assets/book-icon.svg");
  top: 90%;
  left: 50%;
  
  transform: translate(-50%, -50%);
}

</style>


