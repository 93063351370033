<template>
    
    <div class="ad-container">
        <transition-group name="fadepagefast"  mode="out-in">
            <svg width="56" height="56" viewBox="0 0 56 56" @click="playAudio()" v-if="paused==true" :key="paused" :style="kontrastSwitch()">
                <g id="Audiodeskription1" transform="translate(-16.12 -17.402)" :fill="kontrastSwitch()" >
                    <!-- fill="#fff" -->
                    <path id="Pfad_124" data-name="Pfad 124" d="M74.8,17.42c.38-.1.645.245.912.432,1.183,1.039,2.52,1.928,3.591,3.075-.157.219-.314.441-.489.648-2.2,2.491-4.508,4.893-6.638,7.434-1.112,1.417-2.536,2.59-3.638,4.014-.163.225.257.291.4.392a44.114,44.114,0,0,1,5.239,2.45,4.461,4.461,0,0,1,1.283.913c-.583.874-1.213,1.72-1.8,2.592A15.937,15.937,0,0,1,72.428,41.1a13.6,13.6,0,0,1-2.143-1,32.648,32.648,0,0,0-4.467-1.832,38.4,38.4,0,0,0-10.012-1.943c-1.593-.016-3.185-.039-4.778.02a46.019,46.019,0,0,0-6.053.91A39.99,39.99,0,0,0,37.9,39.644c-.98.444-1.9.987-2.891,1.417-.56-.69-.948-1.478-1.433-2.211-.492-.78-1.032-1.537-1.453-2.354a10.591,10.591,0,0,1,1.957-1.168,41.075,41.075,0,0,1,9.074-3.472c1.256-.268,2.5-.6,3.761-.83a37.16,37.16,0,0,1,5.041-.444,43.509,43.509,0,0,1,6.663.259c1.3.1,2.573.4,3.859.6q2.9-3.248,5.739-6.543c1.811-2.177,3.738-4.267,5.613-6.4A10.359,10.359,0,0,1,74.8,17.42Z" transform="translate(-13.71 0)" />
                    <path id="Pfad_125" data-name="Pfad 125" d="M136.392,157.652a6.57,6.57,0,0,1,7.009,7.328,6.947,6.947,0,0,1-1.948,3.814,6.8,6.8,0,0,1-4.373,1.855,5.63,5.63,0,0,1-2.564-.5,6.248,6.248,0,0,1-3.714-3.94,5.548,5.548,0,0,1-.1-3.828,6.4,6.4,0,0,1,3.54-4.136A8.8,8.8,0,0,1,136.392,157.652Z" transform="translate(-96.302 -118.255)" />
                    <path id="Pfad_126" data-name="Pfad 126" d="M20.474,223.35a5.969,5.969,0,0,1,1.3-1.7,41.1,41.1,0,0,0,5.457,2.552,34.93,34.93,0,0,0,8.908,2.015,39.711,39.711,0,0,0,11.1-.276,37.947,37.947,0,0,0,9.318-2.916c.873-.4,1.69-.9,2.581-1.275a17.383,17.383,0,0,1,1.328,2.009c.51.856,1.1,1.67,1.559,2.553a10.856,10.856,0,0,1-2.145,1.272,41.835,41.835,0,0,1-15.862,4.522,19.918,19.918,0,0,1-2.786.136,32.318,32.318,0,0,1-4.342-.14,17.36,17.36,0,0,1-3.665-.487c-.364-.053-.843-.259-1.106.089-1.284,1.382-2.537,2.792-3.795,4.194-1.5,1.846-3.149,3.579-4.723,5.369a30.391,30.391,0,0,1-3.321,3.417c-.84-.528-1.542-1.216-2.361-1.77-.563-.439-1.17-.834-1.7-1.309a.365.365,0,0,1-.034-.475,7.192,7.192,0,0,1,.9-1.052c2.217-2.476,4.387-4.987,6.626-7.446.506-.535.962-1.106,1.445-1.657a5.827,5.827,0,0,0,.947-1.179c-.321-.145-.641-.288-.973-.409a35.189,35.189,0,0,1-5.243-2.443,8.356,8.356,0,0,1-1.291-.791A29.377,29.377,0,0,1,20.474,223.35Z" transform="translate(0 -171.282)" />
                </g>
            </svg>
            <!-- <img class="img1" src="../assets/svgs/button-ad.svg" style="{fill:#00ff00}" alt="" @click="playAudio()" v-if="paused==true" :key="paused" :style="kontrastSwitch()"> -->
            <svg  width="56" height="56" viewBox="0 0 56 56"  alt="" @click="playAudio()" v-if="paused==false" :key="paused" :style="kontrastSwitch()">
                <g id="Audiodeskription2" data-name="Gruppe 56" transform="translate(0 0)">
                    
                    <g id="button-pause" transform="translate(18 17)">
                    <rect id="Rechteck_343" data-name="Rechteck 343" width="7" height="22" transform="translate(-0.22 -0.134)" :fill="kontrastSwitch()"/>
                    <rect id="Rechteck_344" data-name="Rechteck 344" width="7" height="22" transform="translate(12.78 -0.134)" :fill="kontrastSwitch()"/>
                    </g>
                
                    <g id="Ellipse_34" data-name="Ellipse 34" transform="translate(0 0)" fill="none" :stroke="kontrastSwitch()" stroke-width="5">
                        <circle cx="28" cy="28" r="28" stroke="none"/>
                        <circle cx="28" cy="28" r="25.5" fill="none"/>
                    </g>
                </g>
            </svg>

            <!-- <img class="img2" src="../assets/svgs/button-pause.svg" alt="" @click="playAudio()" v-if="paused==false" :key="paused" :style="kontrastSwitch()"> -->
        </transition-group>
        <!-- <audio ref="audioElm" src="../../javascript/play-toggle-sound/Wat_Metta_Buddha_Qualities.mp3"></audio> -->
                                        <!-- <vue-plyr :options="optionsAudio" ref="vplyraudio1" @play="vPlayCheck('audio1')"> -->
        <audio crossorigin playsinline ref="adaudio">
            <source
                :src="'/mp3/' + basename + '_' + language +'.mp3'"
                type="audio/mp3"
            />
            <source
                :src="'/mp3/' + basename + '_' + language +'.ogg'"
                type="audio/ogg"
            />
        
        </audio>
                                        <!-- </vue-plyr> -->
        <div class="ad-line"></div>
    </div>

</template>



<script>
import {onMounted, ref} from 'vue'; //  refs,  ref, onMounted, computed, inject
import {useStore} from 'vuex';



export default {

    props: {
        basename:String,
        language: String,
    },

    setup() {
        let paused = ref(true);
        // let a = null;
        const adaudio = ref(null);
        const store = useStore();


        onMounted(() => {
            // if (this.roomId==4){
            // console.log(adaudio)
            // a = refs.adaudio;
            // console.log("ad", a)
        })

        const playAudio = () => {
    
            if (adaudio.value.paused == true) {
                paused.value = false;
                adaudio.value.currentTime=0;
                adaudio.value.play();
            } else {
                paused.value = true;
                adaudio.value.pause();
            }
        }
        
        const kontrastSwitch = () => {
            if (store.state.kontrastSwitch == true ){
                return "#ffffff"
                    // backgroundColor: "#ffff00",
                    // color: "#ff0000",
                    // fill: "#ff0000",
                
            } else {
                return "#94A78F"
                    // backgroundColor: "#ff00ff",
                    // color: "#ff0000",
                    // fill: "#ff00ff",
            }

        }

        return {
            playAudio,
            adaudio,
            paused,
            kontrastSwitch,
        }

    },

}
</script>




<style lang="scss">
.ad-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    // background-color: #f80000;
    height: 100%;
}

.ad-container > svg {
    display: flex;
    padding: 0px;
}

// .ad-container > img  {
//     display: flex;
//     padding: 10px;
// }

.ad-line {
    height: 56px;
    width: 2px;
    background-color: white;
    opacity: 0.2;
    margin-right: 10px;;
}

// .img1 {
//     -webkit-mask: url("../assets/svgs/button-ad.svg") no-repeat center;
//     mask: url("../assets/svgs/button-ad.svg") no-repeat center;
// }

// .img2 {
//     -webkit-mask: url("../assets/svgs/button-pause.svg") no-repeat center;
//     mask: url("../assets/svgs/button-pause.svg") no-repeat center;
// }


</style>