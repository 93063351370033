// import Vue from 'vue'
// import Vuex from 'vuex'
import { createStore } from 'vuex'
// Vue.use(Vuex)

// import { Timer } from "../_util.js";
// var t = new Timer();
// console.log(t.elapsed());


const store = createStore({
  state() {
    return { 
      debug: true,
      // language: "de",
      loaded: false,
      page: -1,
      qrcodeModal: false,
      pincodeModal: false,
      menuModal: false,
      showRundgang: false,
      showSettings: false,
      showLanguage: false,
      showPincode: false,
      roomId: 0,
      roomPageId: 0,
      maxRoomPage: 2,
      typoSwitch: false,
      kontrastSwitch: false,
      content: {},
      contentWp: {},
      contentZitate: [],
      contentUi: {},
      
      // settingsModal: false,
      // card: -1,
      // currPartnerPoint: -1,
      // videoNum: -1,
      // content: "",
      language: "de",
      // timeout: false,
      // apptext: { 
      //   language_de: "english",
      //   language_en: "deutsch",
      // }
    }
    // currSubs: [1,2,3,4],
  },
  mutations: {
    // switchLanguageByIDM(state, language) {},
    // setCurrPartnerPointM(state, currPartnerPoint){
    //   // console.log("setCurrPartnerPoint in store commit:" + currPartnerPoint);
    //   state.currPartnerPoint = currPartnerPoint;
    //   // console.log( state.currPartnerPoint);
    // },

    setAppContentM(state, content) {
      // console.log("setAppContentM in store commit:" + content);
      state.content = content;
      // console.log(state.activeInfo);
    },

    setAppLoadedM(state) {
      // console.log("setAppLoadedM");
      state.loaded = true;
      // console.log(state.activeInfo);
    },

    setRoomIdM(state, payload) {
      // console.log("setRoomIdM", payload);

      if (state.roomId != payload){
        state.maxRoomPage = state.contentWp[payload].children.length
        state.roomPageId = 0;
      }
      state.roomId = payload;
      // console.log(state.activeInfo);
    },

    checkRoomMaxPage(state){
      console.log("checkRoomMaxPage", state);
    },

    // setAppTimeoutM(state) {
    //   // console.log("setAppTimeoutM");
    //   state.timeout = true;
    //   state.page= -1;
    //   // console.log(state.activeInfo);
    // },

    setPageM(state, payload) {
      state.page = payload.page;
    },

    // setVideoNumM(state, payload) {
    //   state.videoNum = payload.videoNum;
    // },

    switchDebugM(state) {
      state.debug = !state.debug;
    },

    setDebugM(state, payload) {
      state.debug = payload.debug;
    },

    // setAppLanguageM(state) {
    //   console.log("setAppLanguage in store commit");
    //   state.language == "de"
    //     ? (state.language = "en")
    //     : (state.language = "de");
    //   console.log(state.language);
    // },

    changePageM(state, page) {
      // console.log("changePageM in store commit:" + page);
      state.page = page;
      state.timeout = false;
      // console.log(state.activeInfo);
    },
    // switchLanguageM(state) {
    //   // console.log("switchLanguageM");
    //   state.language == "de"
    //     ? (state.language = "en")
    //     : (state.language = "de");
    // },

    isTimeout() {
      // console.log("computed isTimeout");
      return this.$store.getters.getTimeoutFlag;
    },

    // setAppActiveCardM(state, card) {
    //   console.log("setActiveCardM in store commit:" + card);
    //   state.page = 3; // muss immer auf 3 sein...
    //   state.card = card;
    //   // console.log(state.activeInfo);
    // },

    setAppUpM(state) {
      console.log("setAppUpM in store commit");
      if (state.card > -1) {
        state.page = 2;
        state.card = -1;
      } else {
        state.timeout = true;
      }
      // state.gui = true;
    },

    setQrcodeModalM (state){
      state.qrcodeModal = state
    }

    // isVisible() {
    //   // für condition der headline benötigt
    //   console.log("isVisible getTimeout",  this.$store.getters.getTimeout);
    //   // side effect this.showme = this.$store.getters.getTimeoutFlag;
    //   return this.$store.getters.getTimeoutFlag;
    // }
  },

  actions: {
    // setCurrPartnerPoint({ commit }, currPartnerPoint) {
    //   commit("setCurrPartnerPointM", currPartnerPoint); // alles wieder auf anfang
    // },
    setAppUp({ commit }) {
      // console.log("setAppTimeout in store");
      commit("setAppUpM"); // alles wieder auf anfang
    },
    setQrcodeModal ({ commit, payload}){
      commit("setQrcodeModalM", payload)
    },
    setRoomId ({commit}, payload) {
      //console.log("setRoomId", payload);
      commit("setRoomIdM", payload)
    },
    // setAppActiveCard({ commit }, card) {
    //   // console.log("setAppActiveInfo in store");
    //   commit("setAppActiveCardM", card);
    // },

    // switchLanguage({ commit }) {
    //   console.log("switchLanguage in store");
    //   commit("switchLanguageM");
    // },
    // switchLanguageByID({ commit }) {
    //   // console.log("switchLanguage in store");
    //   commit("switchLanguageByIDM");
    // },
    resetAppTimeout({ commit }) {
      // console.log("resetAppTimeout in store");
      commit("resetAppTimeoutM"); // alles wieder auf anfang
    },

    setAppTimeout({ commit }) {
      // console.log("setAppTimeout in store");
      commit("setAppTimeoutM"); // alles wieder auf anfang
    },

    changePage({ commit }, page) {
      // console.log("changeMenu in store");
      commit("changePageM", page);
    },

    setAppLoaded({ commit }) {
      // console.log("setAppLoaded in store");
      commit("setAppLoadedM"); // alles wieder auf anfang
    },

    // setAppLanguage({ commit }) {
    //   // console.log("setAppGame in store");
    //   commit("setAppLanguageM"); // Game Layer einblenden
    // },

    setAppContent({ commit }, content) {
      // console.log("setAppContent in store");
      commit("setAppContentM", content);
    },

    setPage({ commit }, payload) {
      // console.log("setPage", payload);
      commit("setPageM", payload); // in der mutation kann der wert geändert werden
    },

    // setVideoNum({ commit }, payload) {
    //   // console.log("setVideoNum", payload);
    //   commit("setVideoNumM", payload); // in der mutation kann der wert geändert werden
    // },

    setDebug({ commit }, payload) {
      commit("setDebugM", payload); // in der mutation kann der wert geändert werden
    },

    switchDebug({ commit }) {
      // console.log("switchDebug");
      commit("switchDebugM");

    },
  },
  modules: {},
  getters: {
    // getCurrPartnerPoint(state) {
    //   return parseInt(state.currPartnerPoint);
    // },
    // getAppLanguage(state) {
    //   return state.language;
    // },
    getAppLoaded(state) {
      // console.log("getAppLoaded from store");
      return state.loaded;
    },
    getAppTimeout(state) {
      // console.log("getAppTimeout from store");
      return state.timeout;
    },
    getDebugFlag(state) {
      // console.log("getDebugFlag from store", state.debug);
      return state.debug;
    },
    getAppText(state) {
      //console.log("getAppText from store");
      return state.apptext;
    },
    getPage(state) {
      // console.log("getDebugFlag from store");
      return state.page;
    },
    // getAppActiveCard(state) {
    //   // console.log("getAppActiveCard from store");
    //   return state.card;
    // },
    // getVideoNum(state) {
    //   // console.log("getDebugFlag from store");
    //   return state.videoNum;
    // },
    // getCurrSubs(state) {
    //   // console.log("getDebugFlag from store");
    //   return state.currSubs;
    // },
  },
});

export default store;