<template>
     
    <div class="basefloater-container" :style="kSBackground()">
        <div class="base-c1" ref="target">
            <div class="settings-topline"><h4 :class="store.state.typoSwitch ? 'larger':'leer'">{{contentui.einstellungen[language]}}</h4><img src="../assets/svgs/close.svg" alt="" @click="closeSettings()"></div>
            <h5 :class="store.state.typoSwitch ? 'larger':'leer'">{{contentui.spracheeinstellungen[language]}}</h5>
            <div class="deko-settings__line"></div>
            
            <div class="settings-container">  

                <div class="setting-base" id="de" :class="language=='de' ? 'setting-active' : ''" @click="setLanguage('de')" >
                    <!-- <img id="svg-de" src="@/assets/svgs/checkbox.svg" alt="de"> -->
                    <svg id="svg-de"  xmlns="http://www.w3.org/2000/svg" width="59.5" height="59.5" viewBox="0 0 59.5 59.5">
                      <g id="Gruppe_60" data-name="Gruppe 60" transform="translate(-59 -129.5)">
                        <g id="Ellipse_13" data-name="Ellipse 13" transform="translate(59 139)" fill="none" stroke="#fff" stroke-width="1">
                            <circle cx="25" cy="25" r="25" stroke="none"/>
                            <circle cx="25" cy="25" r="24.5" fill="none"/>
                        </g>
                        <g id="haken" name="haken" class="svg-visible" transform="translate(-4.637 -118.637)">
                            <circle id="Ellipse_17" data-name="Ellipse 17" cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Pfad_115" data-name="Pfad 115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                      </g>
                    </svg>

                    <p>{{store.state.contentUi.ui.language["de"]}}</p>
                </div>
                
                <div class="setting-base" id="en"  :class="language=='en' ? 'setting-active' : ''" @click="setLanguage('en')" >
                    <!-- <img id="svg-en" src="@/assets/svgs/checkbox.svg" alt="en"> -->
                    <svg id="svg-en" xmlns="http://www.w3.org/2000/svg" width="59.5" height="59.5" viewBox="0 0 59.5 59.5">
                      <g id="Gruppe_60" data-name="Gruppe 60" transform="translate(-59 -129.5)">
                        <g id="Ellipse_13" data-name="Ellipse 13" transform="translate(59 139)" fill="none" stroke="#fff" stroke-width="1">
                            <circle cx="25" cy="25" r="25" stroke="none"/>
                            <circle cx="25" cy="25" r="24.5" fill="none"/>
                        </g>
                        <g id="haken" name="haken" class="svg-invisible" transform="translate(-4.637 -118.637)">
                            <circle id="Ellipse_17" data-name="Ellipse 17" cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Pfad_115" data-name="Pfad 115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                      </g>
                    </svg>

                    <p>{{store.state.contentUi.ui.language["en"]}}</p>
                </div>
                
                <div class="setting-base" id="nl"  :class="props.language=='nl' ? 'setting-active' : ''" @click="setLanguage('nl')" >
                    <!-- <img id="svg-nl" src="@/assets/svgs/checkbox.svg" alt="nl"> -->
                    <svg id="svg-nl" xmlns="http://www.w3.org/2000/svg" width="59.5" height="59.5" viewBox="0 0 59.5 59.5">
                        <g id="Gruppe_60" data-name="Gruppe 60" transform="translate(-59 -129.5)">
                          <g id="Ellipse_13" data-name="Ellipse 13" transform="translate(59 139)" fill="none" stroke="#fff" stroke-width="1">
                              <circle cx="25" cy="25" r="25" stroke="none"/>
                              <circle cx="25" cy="25" r="24.5" fill="none"/>
                          </g>
                          <g id="haken" name="haken" class="svg-invisible" transform="translate(-4.637 -118.637)">
                              <circle id="Ellipse_17" data-name="Ellipse 17" cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <path id="Pfad_115" data-name="Pfad 115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                          </g>
                        </g>
                      </svg>

                    <p>{{store.state.contentUi.ui.language["nl"]}}</p>
                </div>
                
                <div class="setting-base" id="sl"  :class="props.language=='sl' ? 'setting-active' : ''" @click="setLanguage('sl')" >
                    <!-- <img id="svg-des" src="@/assets/svgs/checkbox.svg" alt="sl"> -->
                    <svg id="svg-sl" xmlns="http://www.w3.org/2000/svg" width="59.5" height="59.5" viewBox="0 0 59.5 59.5">
                      <g id="Gruppe_60" data-name="Gruppe 60" transform="translate(-59 -129.5)">
                        <g id="Ellipse_13" data-name="Ellipse 13" transform="translate(59 139)" fill="none" stroke="#fff" stroke-width="1">
                            <circle cx="25" cy="25" r="25" stroke="none"/>
                            <circle cx="25" cy="25" r="24.5" fill="none"/>
                        </g>
                        <g id="haken" name="haken" class="svg-invisible" transform="translate(-4.637 -118.637)">
                            <circle id="Ellipse_17" data-name="Ellipse 17" cx="12" cy="12" r="12" transform="translate(98.637 248.637)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Pfad_115" data-name="Pfad 115" d="M9,15.511l3.008,3.008L19.527,11" transform="translate(96.015 245.519)" fill="none" stroke="#94a78f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                      </g>
                    </svg>

                    <p>{{store.state.contentUi.ui.language["sl"]}}</p>
                </div> 

            </div>
        </div>
    </div>

</template>



<script>
import {useStore} from 'vuex'
import { inject, onMounted  } from 'vue';
import { ref } from 'vue'; // ref, onMounted, computed
// import { vOnClickOutside } from '@vueuse/components'
import { onClickOutside } from '@vueuse/core'


export default {
    props: {
        contentui: Object,
        content: Object,
        language: String
    },
    setup(props) {
        const store = useStore();
        const $cookies = inject('$cookies');
        const target = ref(null)
        
        onClickOutside(target, (event) =>  {
              console.log("onClickOutside", event)
              closeSettings();
            }
        )

        const closeSettings = () => {
          store.state.showSettings = false;
          store.state.showLanguage = false;
        }

        const setLanguage = (value) => {
          
          const langArraxy =["de", "en", "nl", "sl",]
          for (let i= 0; i < langArraxy.length; i++) {
            const butsvg = document.getElementById("svg-" + langArraxy[i]);
            const haken = butsvg.querySelector('#haken');
            haken.classList =["svg-invisible"]
          }
         
          const butsvg = document.getElementById("svg-" + value);
          // console.log(butsvg);
          const haken = butsvg.querySelector('#haken');
          // console.log(haken);
          haken.classList.toggle("svg-visible")
          // haken.classList.add("svg-unvisible");

          // const koerperNr =  e.target.attributes.name.nodeValue;
          // console.log("setLanguage", value);
          store.state.language = value;
          $cookies.set("language",store.state.language);

        }

        const kSBackground = () => {
            if (store.state.kontrastSwitch == false) {
                return {
                      backgroundColor: '#4c5749',
                }
            } else {
                return {
                      backgroundColor: '#222222',
                }
            } 
        }

        onMounted(() => {
          console.log(props)
          const langArraxy =["de", "en", "nl", "sl",]
          for (let i= 0; i < langArraxy.length; i++) {
            const butsvg = document.getElementById("svg-" + langArraxy[i]);
            const haken = butsvg.querySelector('#haken');
            if (props.language == langArraxy[i] ){
              haken.classList = ["svg-visible"]
            }else {
              haken.classList =["svg-invisible"]
            }
          }


        })

        // const closeSettings = () => {
        //     console.log("closeSettings")
        //     store.state.settingsModal = false;
        // }

        return {
          //   closeSettings,
          target,
          props,
          store,
          setLanguage,
          closeSettings,
          kSBackground,
        }
    }

}
</script>



<style lang="scss" scoped>

.settings-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  // justify-content: space-between;
  // align-content: space-around;
  align-content: space-between;
  
  margin-top: 1rem;
}

.setting-base {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    align-content: space-around;
}

.settings-topline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between
  
}

// .settings-topline > img {
//   align-content: right;
//   align-self: right;
// }
// .mobile-nav {
// //   display: none;
//   position: fixed;
//   z-index: 101;
//   top: 0;
//   left: 0;
//   background: rgb(255, 255, 255);
//   width: 100%;
//   height: 100vh;
// }

// .mobile-nav__items {
//   width: 90%;
// //   height: 100%;
//   list-style: none;
//   margin: 0 auto;
//   padding: 0;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
// }

// .mobile-nav__item {
//   margin: 1rem 0;
// }

// .mobile-nav__item a {
//   font-size: 1.5rem;
// }

// button {
//   background-color: #e4e4e4; 
//   border-radius: 10px;
//   color: rgb(0, 0, 0);
//   margin: 0.4rem ;
//   padding: 1rem 1.6rem;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 1.2rem;
//   box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.8);
// }

</style>

